import { useRef, useEffect, useState } from 'react';
import plus from '@assets/pictures/plus.svg';
import dash from '@assets/pictures/dash.svg';
import faq from '@assets/pictures/svgs/faq.png';
import useIsMobile from '../customHooks/useIsMobile';
import useIsDesktop from '../customHooks/useIsDesktop';
import useIsTablet from '@src/customHooks/useIsTablet.js';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';

const faqData = [
  {
    question:
      'Why are movie sessions at the box office at different timings as compared to those listed in the newspapers or printed movie schedules?',
    answer: [
      'Newspapers and internet listings require that we submit our session times at least three days in advance and even earlier in the event of a public holiday. As a result, what appears in the newspapers and printed schedules may differ from the actual timings. To confirm a session time, please check movie timings on our website or at the box office.',
    ],
  },
  {
    question:
      'My booking has been rejected, but my credit card has been charged? What do I do?',
    answer: [
      'This is a very rare situation, and can occur in case of a network fluctuation. In this case, please email our team at info@epixcinemas.com. You will need to provide them with details such as the date of transaction as well as the first and last 4 digits of the credit/debit card. Upon confirmation by the bank end team, payment will be refunded by the bank to the same card within 3 weeks.',
    ],
  },
  {
    question: 'At what time does your box office open and close?',
    answer: [
      ' The opening and closing hours of our box office may vary depending upon the first and the last movie of the day at the cinema. Also, variations in timings may result due to midnight screenings.',
    ],
  },
  {
    question: 'Up to what age are children allowed to enter for free?',
    answer: [
      'Children under 3 years can enter the cinema for free, but are not entitled to separate seats. In such an event, 3D glasses will need to be purchased separately for the child.',
    ],
  },
  {
    question: 'What are the different types of movie ratings?',
    answers: [
      'G - General for all Audiences',
      'PG - Parental / Adult guardian guidance for all children',
      'PG 13 - Parental / Adult guardian guidance is required for those below 13 years of age.',
      'PG 15 Parental / Adult guardian guidance is required for those below 15 years of age.',
      '15 + - Admission only for those of 15 years and above age.',
      '18 + - Admission only for those of 18 years and above age.',
    ],
  },
  {
    question: ' What are the general rules and regulations in Epix Cinemas?',
    answers: [
      '1. Smoking is not allowed inside the cinema premises or the cinema halls',
      '2. Outside food and beverages are not permitted inside the cinema halls',
      '3. The cinema reserves the right to do random checks of all bags at the hall entrance',
      '4.All recording devices are strictly prohibited inside the cinema halls',
      '5. Epix cinemas strictly adheres to the film-rating regulations of the UAE and reserves the right to refuse admission if proof of age is not provided',
    ],
  },
  {
    question: 'Do your cinemas provide spaces for wheelchairs?',
    answer: ['Yes, we do provide only on screens – 1,2,3,5,7,8,10'],
  },
  {
    question: 'Can I see what subtitles are displaying on a movie?',
    answer: ['Yes, the subtitles are listed on the movie information pages.'],
  },
  {
    question: 'Can I bring food from outside into the cinemas?',
    answer: [
      'Food and Beverages purchased outside the cinema is strictly prohibited',
    ],
  },
  {
    question: 'What is the minimum age to pay for a ticket?',
    answers: [
      'Children under 3 years old can enter the cinema for free. Please note, this does not entitle the child to an exclusive seat. He/she is expected to sit the lap of the accompanying adult. 3D Glasses will need to be rented as an extra charge.',
      'It is at the sole discretion for cinema staff to ask for photo identification as proof of age. You will be refused entry to the movie if you cannot provide an identification document to verify your age. In such cases, tickets will not be refunded.',
    ],
  },
  {
    question: 'Where do I collect my 3D glasses?',
    answer: ['3D glasses can be collected from our staff at the usher points.'],
  },
  {
    question: ' Can I book a cinema/party room for a private event?',
    answer: [
      ' Yes, our cinemas cater to any occasions and can be booked with custom packages for private screenings, conferences, staff outings or birthday parties. For more information, email us at info@epixcinemas.com, mentioning the location you wish to book.',
    ],
  },
  {
    question: ' Can I take photos & videos inside the auditorium?',
    answer: [
      ' It is strictly forbidden to record or take photos during the movie. However, you may take photos and videos before and after.',
    ],
  },
  {
    question:
      'Can I place my advertisement on the auditorium screens or in the cinema lobby?',
    answer: [
      ' Yes, for more information, send us an email at info@epixcinemas.com, mentioning the location you wish to advertise.',
    ],
  },
  {
    question: 'How do I make a booking at kinder?',
    answer: ['Pls contact us on +973- 77900111'],
  },
  {
    question: ' The refund has still not been credited in my credit card account.',
    answer: [
      'The refund has still not been credited in my credit card account. As per our company policy, we will contact you to provide relevant information y the intermediary bank to enable the refund to the credit card. Depending on the card issuing bank, it can sometimes take upto a 3 week for the reversal to take effect. Please contact us',
    ],
  },
];

const fnbData = [];
const moview3d = [];
const yourAccounts = [
  {
    question: 'How do I reset my password?',
    answer: [
      'Please reset your password by selecting the forgotten password link found on the login page.',
    ],
  },
  {
    question: 'How do I access my purchase history?',
    answer: [
      'Please view your upcoming and past booking history by selecting Booking History. This can also be accessed via the Settings section of the mobile apps.',
    ],
  },
];
const bookings = [
  {
    question: 'Can confirmed bookings be cancelled and refunds requested?',
    answer: [
      'Once a booking is confirmed and paid for, no cancellations or refunds will be allowed under any circumstances. All tickets sold are non-returnable and non-refundable, even if you do not collect your tickets or arrive to see the movie.                   ',
    ],
  },
  {
    question: 'Can a confirmed booking be changed?',
    answer: [
      'Yes. Subject to seat availability for the movie session you want to switch to, you can change a confirmed booking. However, this can be done only at the box office where the tickets were booked. Changes must be made at the box office at least two (2) hours before the original movie show-time. No changes can be made once the movie has started.',
    ],
  },
  {
    question: 'What forms of payment do you accept for online bookings?',
    answer: ['We accept,Visa, Mastercard and Amex for online bookings.'],
  },
  {
    question: 'Where can I find my QR code?',
    answer: ['The QR code can be found in the booking confirmation email.'],
  },
  {
    question: 'How many tickets can I book online?',
    answer: ['You may purchase a maximum of 10 tickets per booking.'],
  },
  {
    question: "What should I do if I don't receive my booking receipt email?",
    answer: [
      'Please check the Junk mail. If still not there, kindle email us at info@epixcinemas.com and provide your name and the first 6 and last four digits of your bank card.',
    ],
  },
];
const offers = [];

const FAQComponent = () => {
  const [activeTab, setActiveTab] = useState('General Information');
  const [expandedQuestions, setExpandedQuestions] = useState([]);
  const activeButtonRef = useRef(null);
  const [activeButtonWidth, setActiveButtonWidth] = useState(0);
  const isDeskTop = useIsDesktop();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const handleQuestionClick = (index) => {
    setExpandedQuestions((prevExpanded) => {
      if (prevExpanded.includes(index)) {
        return prevExpanded.filter((item) => item !== index);
      } else {
        return [index];
      }
    });
  };

  useEffect(() => {
    if (activeButtonRef.current) {
      setActiveButtonWidth(activeButtonRef.current.offsetWidth);
    }
  }, [activeTab]);

  // const filteredFaq = faqData.filter((faq) => {
  //     if (activeTab === 'General') {
  //         return true;
  //     } else if (activeTab === 'F&B') {
  //         // Add more conditions for other tabs if needed
  //         // Example: return faq.category === 'F&B';
  //     }
  //     return false;
  // });

  const getFilteredFaq = (category) => {
    switch (category) {
      case 'General Information':
        return faqData;
      case 'F&B':
        return fnbData;
      case '3D':
        return moview3d;
      case 'YourAccounts':
        return yourAccounts;
      case 'Bookings':
        return bookings;
      case 'Offers':
        return offers;
      default:
        return [];
    }
  };

  const filteredFaq = getFilteredFaq(activeTab);
  return (
    <>
      <div style={{ marginTop: '0' }}>
        <div class="container4321">
          <img src={faq} alt="Snow" style={{ width: '100%' }} />
          <div
            class="bottom-left4321"
            style={{ fontSize: isMobile ? '20px' : '30px' }}
          >
            Frequently Asked Questions
          </div>
        </div>

        <div className="faq-container mt-3">
        {!isMobile &&(
          <div>
            <div className="tabs1">
              {[
                'General Information',
                'F&B',
                '3D',
                'YourAccounts',
                'Bookings',
                'Offers',
              ].map((tab) => (
                <button
                  key={tab}
                  className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab}
                </button>
              ))}
            </div>
            <hr
              style={{
                width: activeButtonWidth,
                border: `2px solid '#FFB230'}`,
                marginTop: '0',
              }}
            />
          </div>
          )}
        {isMobile &&(  <div className="tabsmobile">
            {[
              'General Information',
              'F&B',
              '3D',
              'YourAccounts',
              'Bookings',
              'Offers',
            ].map((tab) => (
              <button
                key={tab}
                className={`tab-buttonmob ${activeTab === tab ? 'active' : ''}`}
                onClick={() => setActiveTab(tab)}
                style={{
                    borderBottom: activeTab === tab ? '4px solid #ffa500' : 'none',
                    color: activeTab === tab ? 'white' : '#ACA8B7',
                  }}
              >
                {tab}
              </button>
            ))}
          </div>
          )}
          {filteredFaq.length === 0 ? (
            <div className="no-data-message">
              <h5>Currently, no data available for the selected category.</h5>
            </div>
          ) : (
            filteredFaq.map((faq, index) => (
              <div key={index} className="faq-item">
                <button
                  className={`accordion d-flex ${
                    expandedQuestions.includes(index) ? 'active' : ''
                  }`}
                  onClick={() => handleQuestionClick(index)}
                  style={{
                    border: `1px solid ${
                      expandedQuestions.includes(index) ? 'blue' : '#4D4A57'
                    }`,
                    borderTop: index === 0 ? '1px solid #4D4A57' : 'none',
                  }}
                >
                  {faq.question}{' '}
                  {expandedQuestions.includes(index) ? (
                    <img src={dash} alt="-" />
                  ) : (
                    <img src={plus} alt="+" />
                  )}
                </button>
                {expandedQuestions.includes(index) && (
                  <div className="panel">
                    {Array.isArray(faq.answers) ? (
                      faq.answers.map((answer, answerIndex) => (
                        <p key={answerIndex} className="para-text-size">
                          {answer}
                        </p>
                      ))
                    ) : (
                      <p className="para-text-size">{faq.answer}</p>
                    )}
                  </div>
                )}
                {index !== filteredFaq.length - 1 && <hr className="faq-divider" />}
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default FAQComponent;
