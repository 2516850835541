import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';
import dc from '@assets/pictures/faqbanner.png';
import useIsMobile from '../customHooks/useIsMobile';
import useIsDesktop from '../customHooks/useIsDesktop';
import useIsTablet from '@src/customHooks/useIsTablet.js';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const Disclaimers = ({ t }) => {
  const dispatch = useDispatch();
  const [cms_pages_data, setcms_pages_data] = useState([]);
  const [cmsPagesData, setCMSPagesData] = useState([]);

  const isDeskTop = useIsDesktop();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const fetchCMSPagesData = async () => {
    try {
      const response = await GenericService.GetMsPage(6);
      const { data } = response;
      if (data?.status) {
        setCMSPagesData(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCMSPagesData();
  }, []);

  // const addHRAfterH4 = (htmlContent) => {
  //   return htmlContent.replace(
  //     /(<h4\b[^>]*>.*?<\/h4>)/g,
  //     '<span class="custom-h44">$1</span><hr style="width: 5%; height: 3px; background-color: orange; border: none; margin: 8px 0 8px" />',
  //   );
  // };
  const addHRAfterH4 = (htmlContent) => {
    return htmlContent.replace(
      /(<h4\b[^>]*>)(.*?)(<\/h4>)/g,
      (match, openTag, content, closeTag) => {
        return `${openTag} <span class="custom-h4" style="display: block; margin-top: 20px; color: white;">${content}</span> ${closeTag}<hr style="width: 5%; height: 3px; background-color: orange; border: none; margin: 8px 0 8px" />`;
      },
    );
  };

  const renderHTMLContent = (htmlContent) => {
    return { __html: addHRAfterH4(htmlContent) };
  };

  const ulStyle = {
    fontSize: '14px',
    color: '#aca8b7',
    listStyleType: 'none',
    lineHeight: '2',
    fontWeight: 'normal',
    marginTop: '20px',
  };

  return (
    <>
      <div className="tnc_new align_new" style={{ marginTop: '0px' }}>

        <div class="container123">
          <img src={dc} alt="Snow" style={{ width: '100%' }} />
          <div class="bottom-left">
            <CenteredTitle firstHalf={`${t('Disclaimers')}`} secondHalf={''} />
          </div>

          {!isMobile && (
            <div class="top-left">
              <BreadCrumb
                firstHalf={`${t('More')} >`}
                secondHalf={`${t('Disclaimers')}`}
              />
            </div>
          )}
        </div>

        <section className="row">
          <article className="col-lg-10 col-xl-12 tnc_back">
            {cmsPagesData.map((page, index) => (
              <div
                key={index}
                style={ulStyle}
                dangerouslySetInnerHTML={renderHTMLContent(page.page_content)}
              />
            ))}
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(Disclaimers);
