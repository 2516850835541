import React, { useState, useEffect, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
// Components
import { setisAdvance } from '@store/movies/movieActions';
import MoviesNFestsNew from '@components/partials/MoviesNFestsNew';
import InlineScroll from '@components/partials/InlineScroll';
import _, { groupBy } from 'lodash';
import { setSearchedQuery } from '@store/ancilliary/ancilliaryActions';
import useIsTablet from '@src/customHooks/useIsTablet.js';
import { ReactComponent as SearchIcon } from '@assets/pictures/svgs/search.svg';
import { ReactComponent as CloseIcon } from '@assets/pictures/svgs/close.svg';
import moment from 'moment';
import {
  useGetMiniFestivalQuery,
  useGetNowShowingQuery,
  useGetOnDemandQuery,
  useGetUpcomingQuery,
} from '@store/rtk/rtk.movieLists';
import useIsMobile from '../customHooks/useIsMobile';
import useIsDesktop from '../customHooks/useIsDesktop';
let genres = [];

const SearchContainer = ({ t, setSearchComp, searchComp }) => {
  const searchedQuery = useSelector((state) => state.ancilliary.searchedQuery);
  const searchResult = useSelector((state) => state.homepage.searchMovies);
  const cms_settings = useSelector((state) => state.homepage.cms_settings);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser } = loginDetails;

  const [selectedLabelFilter, setLabelFilter] = useState('');
  const [selectedLabelFilterGenre, setLabelFilterGenre] = useState('');
  const isTablet = useIsTablet();

  const { data: comingSoonMovies, isLoading: comingSoonLoading } =
    useGetUpcomingQuery();
  // const { data: createOwnShowMovies, isLoading: onDemandLoading } =
  //   useGetOnDemandQuery({
  //     limit: 1000,
  //     currentPage: 1,
  //     isUserLogged: loggedInUser ? 'Y' : 'N',
  //   });
  const { data: todaysScreeningsMovies, isLoading: nowShowingLoading } =
    useGetNowShowingQuery({ limit: 1000, currentPage: 1 });
  // const { data: miniFestivalsData, isLoading: miniFestivalsLoading } =
  //   useGetMiniFestivalQuery();

  // const uniqueCreateOwnShowMovies = {
  //   ...createOwnShowMovies,
  //   data: [
  //     ...new Map(
  //       createOwnShowMovies?.data?.map((item) => [item['movie_id'], item]),
  //     ).values(),
  //   ],
  // };
  const isDeskTop = useIsDesktop();
  const isMobile = useIsMobile();

  const uniqueComingSoonMovies = {
    ...comingSoonMovies,
    data: [
      ...new Map(
        comingSoonMovies?.data?.map((item) => [item['movie_id'], item]),
      ).values(),
    ],
  };

  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id } = selected_language;

  const dispatch = useDispatch();

  const isItemActive = (item) => {
    if (!CMSLOADER) {
      if (cms_settings && cms_settings.length > 0) {
        if (cms_settings[0][item] == 'Y') {
          return true;
        } else if (cms_settings[0][item] == 'N') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    setLabelFilter('All');
  }, [searchComp]);

  useEffect(() => {
    setLabelFilter('All');
  }, []);

  const SearchMovieData = (movieList = [], index, searchType) => {
    let searchedResult = [];
    searchedResult = movieList?.filter((x) => {
      if (
        x?.movie_title &&
        x?.movie_title?.toLowerCase().includes(searchedQuery?.toLowerCase())
      ) {
        return x;
      } else if (
        x?.d_company_name &&
        x?.d_company_name?.toLowerCase().includes(searchedQuery?.toLowerCase())
      ) {
        return x;
      } else if (
        x?.alternate_movie_name_language &&
        x?.alternate_movie_name_language
          ?.toLowerCase()
          .includes(searchedQuery?.toLowerCase())
      ) {
        return x;
      } else if (
        x?.languageSpecificImages?.length > 0 &&
        x?.languageSpecificImages.some((mc) =>
          mc?.mc_title?.toLowerCase().includes(searchedQuery?.toLowerCase()),
        )
      ) {
        return x;
      } else if (
        x?.genres_with_translations?.length > 0 &&
        x?.genres_with_translations.some(
          (g) =>
            g?.de?.toLowerCase().includes(searchedQuery?.toLowerCase()) ||
            g?.en?.toLowerCase().includes(searchedQuery?.toLowerCase()) ||
            g?.fr?.toLowerCase().includes(searchedQuery?.toLowerCase()) ||
            g?.it?.toLowerCase().includes(searchedQuery?.toLowerCase()) ||
            g?.g_name?.toLowerCase().includes(searchedQuery?.toLowerCase()),
        )
      ) {
        return x;
      } else if (
        x?.languages_with_translations?.length > 0 &&
        x?.languages_with_translations.some(
          (l) =>
            l?.de?.toLowerCase().includes(searchedQuery?.toLowerCase()) ||
            l?.en?.toLowerCase().includes(searchedQuery?.toLowerCase()) ||
            l?.fr?.toLowerCase().includes(searchedQuery?.toLowerCase()) ||
            l?.it?.toLowerCase().includes(searchedQuery?.toLowerCase()) ||
            l?.lang_name?.toLowerCase().includes(searchedQuery?.toLowerCase()),
        )
      ) {
        return x;
      } else if (
        x?.label_to_show?.length > 0 &&
        x?.label_to_show.some((label) =>
          label?.label_name?.toLowerCase().includes(searchedQuery?.toLowerCase()),
        )
      ) {
        return x;
      } else if (
        x?.label_to_show?.length > 0 &&
        x?.label_to_show.some((label) =>
          label?.label?.toLowerCase().includes(searchedQuery?.toLowerCase()),
        )
      ) {
        return x;
      } else if (
        x?.category_link_name &&
        x?.category_link_name?.toLowerCase().includes(searchedQuery?.toLowerCase())
      ) {
        return x;
      } else if (
        x?.language_content?.length > 0 &&
        x?.language_content?.some((label) =>
          label?.event_name?.toLowerCase().includes(searchedQuery?.toLowerCase()),
        )
      ) {
        return x;
      } else if (
        x?.cast_crew?.length > 0 &&
        x?.cast_crew?.some((cast) =>
          cast?.toLowerCase().includes(searchedQuery?.toLowerCase()),
        )
      ) {
        return x;
      } else if (
        x?.keywords_list?.length > 0 &&
        x?.keywords_list?.some((key_w) =>
          key_w?.toLowerCase().includes(searchedQuery?.toLowerCase()),
        )
      ) {
        return x;
      }
    });

    // avoid mutating redux state
    if (window.structuredClone) {
      searchedResult = window.structuredClone(searchedResult);
    }

    searchedResult.forEach((r) => (r.searchType = searchType));
    return searchedResult;
  };

  const searchCategoryMovies = (categories = [], index, searchType) => {
    let temp_arr = [];
    if (categories?.length > 0) {
      let temp = categories?.map((x) => {
        let obj = {
          ...x,
          movies: SearchMovieData(x?.movie_data?.data, undefined, searchType),
        };
        temp_arr.push(obj);
      });
    }

    // avoid mutating redux state
    if (window.structuredClone) {
      temp_arr = window.structuredClone(temp_arr);
    }

    temp_arr.forEach((r) => (r.searchType = searchType));
    return temp_arr;
  };

  // const searchedNowShowing = SearchMovieData(uniqueTodaysScreeningMovies?.data, undefined, 'NowShowing');
  const searchedNowShowing = React.useMemo(
    () => SearchMovieData(todaysScreeningsMovies?.data, undefined, 'NowShowing'),
    [todaysScreeningsMovies, searchedQuery],
  );

  // const searchedOnDemand = SearchMovieData(uniqueCreateOwnShowMovies?.data, undefined, 'OnDemand');
  // const searchedOnDemand = React.useMemo(
  //   () => SearchMovieData(uniqueCreateOwnShowMovies?.data, undefined, 'OnDemand'),
  //   [uniqueCreateOwnShowMovies, searchedQuery],
  // );

  // const searchedUpcoming = SearchMovieData(uniqueComingSoonMovies?.data, undefined, 'Upcoming');
  const searchedUpcoming = React.useMemo(
    () => SearchMovieData(uniqueComingSoonMovies?.data, undefined, 'Upcoming'),
    [uniqueComingSoonMovies, searchedQuery],
  );

  // const searchedOnDemandLabeledMovies = searchCategoryMovies(miniFestivalsData?.Records, undefined, 'OnDemandLabeledMovies');
  // const searchedOnDemandLabeledMovies = React.useMemo(
  //   () =>
  //     searchCategoryMovies(
  //       miniFestivalsData?.Records,
  //       undefined,
  //       'OnDemandLabeledMovies',
  //     ),
  //   [miniFestivalsData, searchedQuery],
  // );

  let movies = [
    ...searchedNowShowing,
    // ...searchedOnDemand,
    ...searchedUpcoming,
    // ...searchedOnDemandLabeledMovies.flatMap((x) => x.movies),
  ];

  let advance = searchedNowShowing?.filter((movie) => {
    let result = !(
      !(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) ||
      (!!(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) &&
        moment(
          movie?.original_release_date ||
            movie?.original_mrrdr_release_date ||
            movie?.mrrdr_release_date,
        ).isSameOrBefore(moment()))
    );

    return result;
  });

  let current = searchedNowShowing?.filter((movie) => {
    let result =
      !(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) ||
      (!!(
        movie?.original_release_date ||
        movie?.original_mrrdr_release_date ||
        movie?.mrrdr_release_date
      ) &&
        moment(
          movie?.original_release_date ||
            movie?.original_mrrdr_release_date ||
            movie?.mrrdr_release_date,
        ).isSameOrBefore(moment()));

    return result;
  });
  // useEffect(() => {
  //   if () {
  //     dispatch(setisAdvance(true));
  //   }
  // }, [todaysScreeningsMovies]);
  let AllMovieData = [...current, ...advance];
  // console.log(AllMovieData, 'AllMovieData');
  let movieIds = [...new Set(movies.map((x) => x.movie_id))];
  let groupByMovies = groupBy(movies, 'movie_id');
  let merged = [];
  movieIds.forEach((i) => {
    merged.push(groupBy(groupByMovies[i], 'searchType'));
  });

  if (genres.length === 0)
    genres = [
      ...new Set(
        movies.flatMap((x) =>
          x.genres_with_translations.flatMap((g) => JSON.stringify(g)),
        ),
      ),
    ];

  const noResultFound = () => {
    let status = false;
    let count = 0;
    if (isItemActive('show_on_demand')) {
      if (
        // searchedOnDemand?.length == 0 &&
        searchedNowShowing?.length == 0 &&
        searchedUpcoming?.length == 0
      ) {
        // let temp = searchedOnDemandLabeledMovies?.map((x) => {
        //   if (x.movies.length > 0) {
        //     count += 1;
        //   }
        // });
        if (count == 0) {
          status = true;
        } else {
          status = false;
        }
      } /*else if (selectedLabelFilter && selectedLabelFilter !== 'All') {
        let filterData = searchedOnDemandLabeledMovies.filter(
          (x) =>
            x?.category_link_name?.toLowerCase() ==
            selectedLabelFilter?.toLowerCase(),
        );
        if (filterData.length > 0) {
          let arr = filterData?.map((x) => {
            if (x?.movies?.length > 0) {
              count += 1;
            }
          });
        }
        if (count == 0) {
          status = true;
        } else {
          status = false;
        }
      }*/ else {
        status = false;
      }
    } else if (searchedNowShowing?.length == 0 && searchedUpcoming?.length == 0) {
      status = true;
    } else {
      status = false;
    }
    return status;
  };

  return (
    <>
      <div className={`container-fluid search_container`} style={{marginBottom:isDeskTop?"5%":"10%"}}>
        <section></section>

        <section className="row">
          <article className="col-12">
            <InlineScroll>
              <h6 className="lube">{t('Search Titles related to')}</h6>
              {/*
              {createLabelsList()?.map((label, index) => (
                <span key={index + 'search_tag'}
                  className={`search_tag ${selectedLabelFilter?.toLowerCase() == label?.label?.toLowerCase() ? "active" : ""}`}
                  onClick={() => setLabelFilter(label?.label)}>{getLangSpecificAttribute(
                    label?.language_content,
                    lang_id,
                    "event_name",
                    "lang_id"
                  ) || label?.label}</span>
              ))}
              <button className="search_tag active">Action</button>

              <button className="search_tag active">Comedy</button>
              */}
              {genres
                ?.map((x) => JSON.parse(x))
                ?.map(
                  (genre, index) =>
                    index < 6 && (
                      <span
                        key={index + 'search_tag'}
                        className={`search_tag ${
                          selectedLabelFilterGenre?.genre_id === genre?.genre_id &&
                          searchedQuery == genre[iso_2?.toLowerCase()]
                            ? 'active'
                            : ''
                        }`}
                        onClick={() => {
                          setLabelFilterGenre(genre);
                          dispatch(setSearchedQuery(genre[iso_2?.toLowerCase()]));
                        }}
                      >
                        {genre[iso_2?.toLowerCase()] || genre?.g_name}
                      </span>
                    ),
                )}
            </InlineScroll>
          </article>
        </section>
        <hr className="hori" />
        <div>
          <span style={{ fontSize: '18px', fontWeight: '600' }}>
            {t('Search Result')}
            <span style={{ fontSize: '10px', color: 'grey', paddingLeft: '13px' }}>
              {/* {merged?.length}{' '}
              {merged?.length && merged?.length > 1 ? t('Movies') : t('Movie')} */}
              {AllMovieData?.length}{' '}
              {AllMovieData?.length && AllMovieData?.length > 1
                ? t('Movies')
                : t('Movie')}
            </span>
          </span>
        </div>

        <section className="">
          {AllMovieData?.length > 0 && (
            <MoviesNFestsNew
              search={true}
              section={1}
              type="search"
              moviesArray={AllMovieData}
              subCategory={''}
              category={''}
              movieCount={AllMovieData?.length}
              onClick={() => {
                setSearchComp && setSearchComp(false);
              }}
              isSearchSection={true}
            />
          )}
        </section>

        <section className="row">
          <div className="col-12 text-center">
            {noResultFound() && (
              <h6 className="mx-auto mb-0">{t('common.No movies found')}</h6>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(SearchContainer);
