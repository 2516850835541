import {
  FETCH_FNB_ITEMS,
  FETCH_FNB_ITEMS_ERROR,
  SELECT_FNB_ITEM,
  UPDATE_COMBO_FNB_ITEM,
  FNB_RESERVATION_DONE,
  SET_FNB_BOOKING_DETAILS,
  SET_FT_ID,
  SET_FNB_LOADER,
  CLEAR_SELECTED_FNB_ITEMS,
  CLEAR_FNB_STATE,
  FNB_BOOKING_ERROR,
  UPDATE_FNB_DATA_FROM_BACKEND,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_ERROR,
} from './foodAndBeveragesTypes';
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';

//initial state of user
const initialState = {
  fnb_items: [],
  fnb_items_error: null,
  fnb_categories: [],
  fnb_categories_error: null,
  fnb_loader: false,
  ft_id: null,
  selected_fnb_items: [],
  fnbBookingDetails: null,
  fnb_price: {
    total_price: 0,
    total_tax: 0,
  },
  selected_fnb_item_id: null,
  current_fnb_item: {},
  fnbReservationDone: false,
  fnbBookingError: null,
};

const fnbReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_FNB_ITEMS: {
      // console.log("FnbReducer: FETCH_FNB_ITEMS", action.payload);
      if (action.payload && Array.isArray(action.payload)) {
        action.payload.forEach((item) => {
          item.alternate_items = [];
          item.fp_total_amount = parseFloat(item.fp_total_amount);
          item.fp_amount = parseFloat(item.fp_amount);
        });
      }
      return {
        ...state,
        fnb_items: action.payload,
        fnb_loader: false,
      };
    }
    case FETCH_FNB_ITEMS_ERROR:
      console.log('FnbReducer: FETCH_FNB_ITEMS_ERROR');
      return {
        ...state,
        fnb_items: [],
        fnb_items_error: action.payload,
        fnb_loader: false,
      };
    case FETCH_CATEGORIES_SUCCESS: {
      return {
        ...state,
        fnb_categories: action.payload,
        fnb_categories_error: null,
      };
    }
    case FETCH_CATEGORIES_ERROR: {
      return {
        ...state,
        fnb_categories: [],
        fnb_categories_error: action.payload,
      };
    }
    case UPDATE_COMBO_FNB_ITEM: {
      const { itemIndex, final_combo_array } = action.payload;
      let new_selected_items = cloneDeep([...state.selected_fnb_items]);
      new_selected_items[itemIndex]['final_combo_array'] =
        cloneDeep(final_combo_array);
      return {
        ...state,
        selected_fnb_items: new_selected_items,
      };
    }
    case SELECT_FNB_ITEM: {
      const { item } = action.payload;

      let new_fnb_items = window.structuredClone
        ? window.structuredClone([...state.fnb_items])
        : [...state.fnb_items];
      let new_selected_items = window.structuredClone
        ? window.structuredClone([...state.selected_fnb_items])
        : [...state.selected_fnb_items];
      let new_fnb_price = window.structuredClone
        ? window.structuredClone({ ...state.fnb_price })
        : { ...state.fnb_price };

      let checkIfNotExists = new_fnb_items.findIndex(
        (_item) => _item?.item_id == item?.item_id,
      );
      if (checkIfNotExists < 0) {
        new_fnb_items = cloneDeep([
          ...new Set(
            new_fnb_items.concat([
              {
                ...item,
              },
            ]),
          ),
        ]); //Adding this to always make the selected item available in the list this is for alternate, customization items
      }

      const itemIndex = new_fnb_items.findIndex(
        (selectedItem) =>
          item.fc_id === selectedItem.fc_id &&
          item.fc_parent_id === selectedItem.fc_parent_id &&
          item.fp_id === selectedItem.fp_id &&
          item.fp_amount === selectedItem.fp_amount &&
          item.item_id === selectedItem.item_id,
      );
      const selectedItemIndex = new_selected_items.findIndex(
        (selectedItem) =>
          item.fc_id === selectedItem.fc_id &&
          item.fc_parent_id === selectedItem.fc_parent_id &&
          item.fp_id === selectedItem.fp_id &&
          item.fp_amount === selectedItem.fp_amount &&
          item.item_id === selectedItem.item_id,
      );

      let tax = round(item.fp_total_amount - item.fp_amount, 4);

      if (itemIndex !== -1) {
        if (action.payload.type == 'add') {
          if (selectedItemIndex == -1) {
            new_fnb_items[itemIndex]['Quantity'] = 1;
            let itemCopy = cloneDeep(new_fnb_items[itemIndex]);
            itemCopy.total_price = round(item.fp_total_amount, 4);
            itemCopy.total_tax = tax;
            new_fnb_price.total_price = round(
              new_fnb_price.total_price + item.fp_total_amount,
              4,
            );
            new_fnb_price.total_tax = round(new_fnb_price.total_tax + tax, 4);
            new_selected_items.push(itemCopy);
          } else {
            new_fnb_items[itemIndex].Quantity++;
            new_selected_items[selectedItemIndex].Quantity++;
            new_selected_items[selectedItemIndex].total_price = round(
              new_selected_items[selectedItemIndex].total_price +
                item.fp_total_amount,
              4,
            );
            new_selected_items[selectedItemIndex].total_tax = round(
              new_selected_items[selectedItemIndex].total_tax + tax,
              4,
            );
            new_fnb_price.total_price = round(
              new_fnb_price.total_price + item.fp_total_amount,
              4,
            );
            new_fnb_price.total_tax = round(new_fnb_price.total_tax + tax, 4);
            if (item?.final_combo_array) {
              new_selected_items[selectedItemIndex]['final_combo_array'] =
                cloneDeep(item.final_combo_array);

              new_selected_items[selectedItemIndex]['combo_pack'] = cloneDeep(
                item.combo_pack,
              );
            }
          }
        } else if (action.payload.type == 'sub') {
          if (
            new_selected_items[selectedItemIndex] &&
            new_selected_items[selectedItemIndex].Quantity &&
            new_selected_items[selectedItemIndex].Quantity > 0
            // &&
            // new_fnb_items[itemIndex] &&
            // new_fnb_items[itemIndex].Quantity &&
            // new_fnb_items[itemIndex].Quantity > 0
          ) {
            new_fnb_items[itemIndex]['Quantity']--;
            new_selected_items[selectedItemIndex].Quantity--;
            new_selected_items[selectedItemIndex].total_price = round(
              new_selected_items[selectedItemIndex].total_price -
                item.fp_total_amount,
              4,
            );
            new_selected_items[selectedItemIndex].total_tax = round(
              new_selected_items[selectedItemIndex].total_tax - tax,
              4,
            );

            new_fnb_price.total_price = round(
              new_fnb_price.total_price - item.fp_total_amount,
              4,
            );
            new_fnb_price.total_tax = round(new_fnb_price.total_tax - tax, 4);

            new_selected_items[selectedItemIndex]['final_combo_array'] = cloneDeep(
              item.final_combo_array,
            );
            new_selected_items[selectedItemIndex]['combo_pack'] = cloneDeep(
              item.combo_pack,
            );

            if (new_selected_items[selectedItemIndex]['Quantity'] == 0) {
              new_fnb_items[itemIndex]['Quantity'] = 0;

              let checkIfItemHasExtras = new_selected_items.filter(
                (extraItem) =>
                  (extraItem?.customized_for_fnb_id &&
                    extraItem?.customized_for_fnb_id == item?.item_id) ||
                  (extraItem?.customized_for_fnb_id &&
                    item?.alternate_item_for &&
                    extraItem?.customized_for_fnb_id == item?.alternate_item_for),
              );

              if (checkIfItemHasExtras?.length) {
                checkIfItemHasExtras?.forEach((extraItem) => {
                  let Qty = parseInt(
                    extraItem?.Quantity || extraItem?.quantity || 1,
                  );
                  let fpTax = parseFloat(
                    parseFloat(extraItem?.fp_total_amount * Qty || 0) -
                      parseFloat(extraItem?.fp_amount * Qty || 0),
                  );
                  new_fnb_price.total_tax = round(
                    new_fnb_price.total_tax - fpTax,
                    4,
                  );
                  new_fnb_price.total_price = round(
                    new_fnb_price.total_price -
                      parseFloat(extraItem?.fp_total_amount * Qty || 0),
                    4,
                  );
                  let checkExtraIndex = new_selected_items.findIndex(
                    (ext) => ext?.item_id == extraItem?.item_id,
                  );
                  new_selected_items.splice(checkExtraIndex, 1);
                });
              }
              new_selected_items.splice(selectedItemIndex, 1);
            }
          }
        }
      }

      return {
        ...state,
        fnb_items: new_fnb_items,
        selected_fnb_items: new_selected_items,
        fnb_price: new_fnb_price,
      };
    }
    case SET_FNB_BOOKING_DETAILS:
      return { ...state, fnbBookingDetails: action.payload };
    case SET_FT_ID: {
      return { ...state, ft_id: action.payload };
    }
    case SET_FNB_LOADER: {
      return {
        ...state,
        fnb_loader: true,
      };
    }
    case CLEAR_SELECTED_FNB_ITEMS: {
      return { ...state, selected_fnb_items: [] };
    }
    case CLEAR_FNB_STATE: {
      return {
        fnb_items: [],
        fnb_items_error: null,
        fnb_loader: false,
        ft_id: null,
        selected_fnb_items: [],
        fnbBookingDetails: null,
        fnb_price: {
          total_price: 0,
          total_tax: 0,
        },
        selected_fnb_item_id: null,
        current_fnb_item: {},
        fnbReservationDone: false,
        fnbBookingError: null,
      };
    }
    case FNB_RESERVATION_DONE:
      console.log('FnbReducer: FNB_RESERVATION_DONE');
      return {
        ...state,
        fnbReservationDone: true,
      };
    case FNB_BOOKING_ERROR: {
      return {
        ...state,
        fnbBookingError: action.payload,
      };
    }
    case UPDATE_FNB_DATA_FROM_BACKEND: {
      let { selected_fnb_items, fnb_price } = action.payload;

      return {
        ...state,
        selected_fnb_items,
        fnb_price,
      };
    }
    default:
      return state;
  }
};

export default fnbReducer;
