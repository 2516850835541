import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';
import pp from '@assets/pictures/svgs/pp.png';
import useIsMobile from '../customHooks/useIsMobile';
import useIsDesktop from '../customHooks/useIsDesktop';
import useIsTablet from '@src/customHooks/useIsTablet.js';
import { ReactComponent as LocationIcon } from '@assets/pictures/svgs/location.svg';
import { Container, Row, Col } from 'react-bootstrap';
import Schedules from './Schedules';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import g1 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_02_28.735Z_2_11.jpeg';
import g2 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_03_25.953Z_2_10.jpeg';
import g3 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_03_26.088Z_2_9.jpeg';
import g4 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_03_26.253Z_2_8.jpeg';
import g5 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_03_26.398Z_2_7.jpeg';
import g6 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_03_55.720Z_2_6.jpeg';
import g7 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_06_08.844Z_2_5.jpeg';
import g8 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_09_28.848Z_2_4.jpeg';
import g9 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_09_48.757Z_2_3.jpeg';
import g10 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_10_05.048Z_2_2.jpeg';
import g11 from '@assets/pictures/svgs/epixgallary/2023-03-02T06_10_23.300Z_2_1.jpeg';

import food from '@assets/pictures/epixnewlogo/277933effe4d81362a08.svg';
import mobileT from '@assets/pictures/epixnewlogo/0b491870582f8a7ad9bf.svg';
import XLnew from '@assets/pictures/epixnewlogo/XLnew.png';
import VIPnew from '@assets/pictures/epixnewlogo/VIPnew.png';
import colorkinder from '@assets/pictures/epixnewlogo/colorkinder.png';
import direction from '@assets/pictures/epixnewlogo/direction.png';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const GalleryContainer = ({ t }) => {
  const dispatch = useDispatch();
  const isDeskTop = useIsDesktop();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [activeTab, setActiveTab] = useState('tab2');
  const [selectedImage, setSelectedImage] = useState(null);
  const [isZoomed, setIsZoomed] = useState(false);

  const handleClickImage = (image) => {
    setIsZoomed(true);
    setSelectedImage(image);
  };

  const handleCloseModal = () => {
    setIsZoomed(false);
    setSelectedImage(null);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  function importAll(r) {
    let images = {};
    r.keys().map((item, index) => {
      images[item.replace('./', '')] = r(item);
    });
    return images;
  }

  const images = importAll(
    require.context(
      '@assets/pictures/svgs/epixgallary',
      false,
      /\.(png|jpe?g|svg)$/,
    ),
  );

  return (
    <>
      <div className="tnc_new align_new" style={{ marginTop: '0px' }}>
        <div class="container123">
          <img src={pp} alt="Snow" style={{ width: '100%' }} />
          <div class="bottom-left">
            {!isMobile && (
              <CenteredTitle
                firstHalf={`${t('EPIX CINEMA, DANA MALL')}`}
                secondHalf={''}
              />
            )}

            {!isMobile && (
              <div className="d-flex justify-content-start align-items-center my-3">
                <LocationIcon className="mx-1" /> <h4 className="mb-0">Bahrain</h4>
              </div>
            )}
            {!isMobile && (
              <div className="d-flex justify-content-start gap-2 align-items-center">
                <p>Available Facilities:</p>
                <p className="ml-2">
                  <img src={food} />{' '}
                  F&B
                </p>
                <p className="ml-2">
                  <img src={mobileT} />{' '}
                  Mobile-Ticket
                </p>
                <p class="ml-2">
                  <img
                    style={{ width: '45px', height: '23px' }}
                    src={colorkinder}
                  />
                </p>
                <p class="ml-1">
                  <img
                    style={{ width: '45px', height: '23px' }}
                    src={VIPnew}
                  />
                </p>
                <p class="ml-1">
                  <img
                    style={{ width: '45px', height: '23px' }}
                    src={XLnew}
                  />
                </p>
              </div>
            )}
          </div>
          {!isMobile && (
            // <div class="bottom-right">
            //   <div className="d-flex align-items-center" style={{border:"1px solid #aca8b7", height:"22px", width:"90px", borderRadius:"5px", padding:"5px"}}>
            //     <img src="https://cdn.epixcinemas.com/icons/direction.png"/> <a href="https://www.google.com/maps/search/?api=1&query=26.230134,50.552661" style={{color:"#aca8b7", fontSize:"12px"}}>Directions</a>
            //   </div>
            // </div>
            <div class="bottom-right">
              <div
                className="d-flex align-items-center"
                style={{
                  border: '1px solid #aca8b7',
                  height: '22px',
                  width: '90px',
                  borderRadius: '5px',
                  padding: '5px',
                }}
              >
                <img src={direction} />
                <a
                  href="https://www.google.com/maps/search/?api=1&query=26.230134,50.552661"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: '#aca8b7', fontSize: '12px' }}
                >
                  Directions
                </a>
              </div>
            </div>
          )}

          {/* {!isMobile && (
            <div class="top-left">
              <BreadCrumb
                firstHalf={`${t('More')} >`}
                secondHalf={`${t('Privacy Policy')}`}
              />
            </div>
          )} */}
        </div>

        <section className="row mt-4">
          <div className="container mt-2">
            <ul className="nav nav-tabs custom-tabs">
              <li className="nav-item">
                <button
                  className={`newTabs nav-link1 ${
                    activeTab === 'tab1' ? 'active' : ''
                  }`}
                  onClick={() => handleTabChange('tab1')}
                  style={{ height: '50px' }}
                >
                  Schedule
                </button>
              </li>
              <li className="nav-item ml-4">
                <button
                  className={`newTabs nav-link1 ${
                    activeTab === 'tab2' ? 'active' : ''
                  }`}
                  onClick={() => handleTabChange('tab2')}
                  style={{ height: '50px' }}
                >
                  Epix Gallary
                </button>
              </li>
            </ul>
            <div className="tab-content mt-3">
              <div
                className={`tab-pane fade ${
                  activeTab === 'tab1' ? 'show active' : ''
                }`}
                id="tab1"
              >
                {activeTab === 'tab1' && <Schedules />}
              </div>
              <div
                className={`tab-pane fade ${
                  activeTab === 'tab2' ? 'show active' : ''
                }`}
                id="tab2"
              >
                {/* <Container>
                  <Row>
                    {Object.keys(images).map((image, index) => (
                      <Col
                        key={index}
                        xs={12}
                        sm={6}
                        md={4}
                        lg={3}
                        xl={3}
                        className="mb-4"
                      >
                        <img
                          src={images[image].default}
                          alt={image}
                          className="img-fluid"
                          style={{ borderRadius: '10px', cursor:"pointer" }}
                          onClick={() => handleClickImage(image)}
                        />
                      </Col>
                    ))}
                  </Row>
                </Container> */}
                <Container>
                  <Row>
                    {Object.keys(images)
                      .reverse()
                      .map((image, index) => (
                        <Col
                          key={index}
                          xs={12}
                          sm={6}
                          md={4}
                          lg={3}
                          xl={3}
                          className="mb-4"
                        >
                          <img
                            src={images[image].default}
                            alt={image}
                            className="img-fluid"
                            style={{ borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => handleClickImage(image)}
                          />
                        </Col>
                      ))}
                  </Row>
                </Container>
                {selectedImage && (
                  <div className="modal-overlay">
                    <div className="modal25" onClick={handleCloseModal}>
                      <img
                        src={images[selectedImage].default}
                        alt={selectedImage}
                        className="modal-content"
                        onClick={() => setIsZoomed(!isZoomed)}
                        style={{ backgroundColor: 'black', cursor: 'zoom-out', maxWidth: '90%', maxHeight: '90%', objectFit: 'contain' }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(GalleryContainer);
