import React, { useState, useEffect } from 'react';
import { Row, Nav, Tab, Col, Dropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import profile from '@assets/pictures/svgs/userSettingsProfile.svg';
import newprofile from '@assets/pictures/svgs/newprofileimage.svg';
import editProfile from '@assets/pictures/svgs/Edit-pen.svg';
import close from '@assets/pictures/svgs/close.svg';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import 'moment/locale/it';
import { Modal } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import useIsMobile from '../customHooks/useIsMobile';
import useIsTablet from '../customHooks/useIsTablet';
import i18n from '../plugins/i18n';
import { withNamespaces } from 'react-i18next';

import { motion } from 'framer-motion';

import RegisterPopup from './partials/popups/RegisterPopup.jsx';
import LoginPopup from './partials/popups/LoginPopup.jsx';
import { guestLogout, logout } from '../store/auth/authActions';
import {
  setSelectedLanguage,
  setLanguageSpecificMovieFunction,
} from '../store/movies/movieActions';
import { titleCase, getLangISOCode } from '../helper/formatting.js';
import { getMomentLocalLang } from '@helper/languages';

const MobileMenu = ({ sideBarClickHandler, t }) => {
  const [modalShow, setModalShow] = React.useState(false);
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="main_body"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ul className="list-unstyled mob_align">
            {languages?.map((language, index) => (
              <li
                key={index}
                className={
                  selected_language.lang_name === language?.lang_name
                    ? 'active'
                    : ''
                }
                onClick={() => {
                  setCinemaSelected(false);
                  onSelectLanguage(language);
                  setModalShow(false);
                }}
              >
                {(
                  language?.alternate_lang_name ??
                  language?.lang_name ??
                  ''
                ).toUpperCase()}
              </li>
            ))}
          </ul>
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id } = selected_language;
  const onSelectLanguage = (language) => {
    dispatch(setSelectedLanguage(language));
    let lang = language.iso_2
      ? language.iso_2.toLowerCase()
      : getLangISOCode(language.lang_name);
    // console.log("found lang = ", lang);
    i18n.changeLanguage(lang);
    moment.locale(getMomentLocalLang(lang.toLowerCase()));
    dispatch(setLanguageSpecificMovieFunction());
  };
  const dispatch = useDispatch();

  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);
  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser } = loginDetails;
  const history = useHistory();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const globalOptions = useSelector((state) => state.movies.globalOptions);
  const languageArray = globalOptions.find(
    (go) => go.go_key === `KIOSK_LANGUAGE`,
  )?.go_value;

  const [languages, setLanguages] = useState(null);
  useEffect(
    () => languageArray && setLanguages(JSON.parse(languageArray)),
    [languageArray],
  );

  const isNavItemActive = (item) => {
    if (!CMSLOADER) {
      if (HEADERS_CMS && HEADERS_CMS.length > 0) {
        if (HEADERS_CMS[0][item] == 'Y') {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const isFooterItemActive = (item) => {
    if (!CMSLOADER) {
      if (footer_cms && footer_cms.length > 0) {
        if (footer_cms[0][item] == 'Y') {
          return true;
        } else if (footer_cms[0][item] == 'N') {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const HEADERS_CMS = useSelector((state) => state.homepage.headers_cms);
  const footer_cms = useSelector((state) => state.homepage.footer_cms);
  const CMSLOADER = useSelector((state) => state.homepage.cmsLoader);
  const [language, setLanguage] = useState('ENGLISH');
  const [cinemaSelected, setCinemaSelected] = useState(false);

  const routelocation = useLocation();

  useEffect(() => {
    setLogin(false);
  }, [routelocation]);

  if (isMobile) {
    return (
      <>
        {/* Popups declaration start */}
        <RegisterPopup show={register} closeFunc={setRegister} />
        <LoginPopup show={login} closeFunc={setLogin} />
        {/* Popups declaration end */}
        <section className="user-profile-page header-space">
          <div className="container-fluid container-xl">
            <motion.div
              className="profile-box"
              initial={{ x: -75, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ duration: 0.75 }}
            >
              <div className="profile-header">
                <div className="user-img">
                  <img src={newprofile} alt="profile-img" className="img-fluid" />
                  <div className="custom-upload">
                    {/* <label htmlFor="file-upload">
                      <img
                        src={editProfile}
                        alt="edit-img"
                        className="img-fluid"
                      />
                    </label>
                    <input id="file-upload" type="file" /> */}
                  </div>
                </div>
                <div className="user-name">
                  <h6 className="mb-1">
                    {loggedInUser && !guestUser && (
                      <>
                        {loggedInUser?.first_name} {loggedInUser?.last_name}
                      </>
                    )}
                    {!loggedInUser && guestUser && (
                      <>
                        {t('Guest')} : {guestUser?.guest_first_name}{' '}
                        {guestUser?.guest_last_name}
                      </>
                    )}
                  </h6>
                  {/*<p>
                    {moment().format("HH:mm")} {t("local time")}
                  </p>*/}
                  <p className="user_text">User</p>
                </div>
              </div>

              {/* Menu starts */}
              <div className="row">
                <div className="col-12" onClick={sideBarClickHandler}>
                  <div className="mobile-menu">
                    {!loggedInUser && guestUser && (
                      <p onClick={() => history.push('/guest-user-profile')}>
                        {t('My Profile')}
                      </p>
                    )}
                    {loggedInUser && (
                      <p onClick={() => history.push('/user-profile')}>
                        {t('My Profile')}
                      </p>
                    )}
                    {loggedInUser && (
                      <p
                        onClick={() => history.push('/user-profile?transactions=Y')}
                      >
                        {t('My Transactions')}
                      </p>
                    )}
                    {false && isNavItemActive('Cinemas') && (
                      <p onClick={() => history.push('/cinema-listing')}>
                        {t('common.Cinema Listing')}
                      </p>
                    )}
                    <p onClick={() => history.push('/now-showing')}>
                      {t('Now Showing')}
                    </p>

                    <p onClick={() => history.push('/upcoming')}>{t('Upcoming')}</p>
                    {isNavItemActive('On Demand') && (
                      <p onClick={() => history.push('/new-on-demand')}>
                        {t('On Demand')}
                      </p>
                    )}
                    {/* {isFooterItemActive("How its Work") && (
                      <p onClick={() => history.push("/how-it-works")}>
                        {t("How It Works")}
                      </p>
                    )} */}
                    {/* <p onClick={() => history.push('/cash-cards')}>
                      {t('Cash Cards')}
                    </p> */}
                    {isFooterItemActive('About US') && (
                      <p onClick={() => history.push('/about-us')}>
                        {t('About Us')}
                      </p>
                    )}
                    {/* <p onClick={() => history.push('/how-it-works')}>
                      {t('AboutUs.howItWorksHeader')}
                    </p> */}
                    {/* <p onClick={() => history.push("/auditorium-listing")}>
                      Auditorium Listing
                    </p> */}
                    {isFooterItemActive('Contact US') && (
                      <p onClick={() => history.push('/contact-us')}>
                        {t('Contact Us')}
                      </p>
                    )}
                    {isFooterItemActive('FAQs') && (
                      <p onClick={() => history.push('/faqs')}>{t('FAQs')}</p>
                    )}
                    {/* {isFooterItemActive('Advertise With Us') && (
                      <p onClick={() => history.push('/AdvertiseWithUs')}>
                        {'Advertise With Us'}
                      </p>
                    )} */}
                    {/* {isFooterItemActive('Whatsapp Community') && (
                      <p onClick={() => history.push('/WhatsappCommunity')}>
                        {'Whatsapp Community'}
                      </p>
                    )} */}

                    {isFooterItemActive('Terms n Conditions') && (
                      <p onClick={() => history.push('/tnc')}>
                        {t('Terms & Conditions')}
                      </p>
                    )}
                    {isFooterItemActive('Privacy Policy') && (
                      <p onClick={() => history.push('/privacy-policy')}>
                        {t('Privacy Policy')}
                      </p>
                    )}

                    {/* <p onClick={() => history.push('/opening-hours')}>
                      {t('Opening Hours')}
                    </p> */}

                    {/* <p onClick={() => history.push('/our-tariff')}>
                      {t('Our Tariff')}
                    </p> */}

                    {/* <p onClick={() => history.push('/auditorium-plan')}>
                      {t('Auditorium Plan')}
                    </p> */}

                    {/* <p onClick={() => history.push('/age-rating')}>
                      {t('Age Rating')}
                    </p> */}

                    {/* <p onClick={() => history.push('/opening-hours')}>
                      {t('Opening Hours')}
                    </p> */}

                    {/* <p onClick={() => history.push('/language-versions')}>
                      {t('Language Versions')}
                    </p> */}
                    <p onClick={() => history.push('/offers')}>{'Offers'}</p>
                    <p onClick={() => history.push('/Locations')}>{'Locations'}</p>
                    <p onClick={() => history.push('/experience')}>
                      {'Experience'}
                    </p>
                    {/* <p onClick={() => history.push('/Careers')}>{'Career'}</p> */}
                    {/* <p onClick={() => history.push('/event_new')}>{'Updates'}</p> */}
                    {/* <p onClick={() => history.push('/foodnbeverages')}>
                      {'Food And Beverages'}
                    </p> */}

                    {/* <p onClick={() => history.push('/jobs')}>{t('Jobs')}</p> */}

                    {/* <p onClick={() => history.push('/events')}>
                      {t('Events & Appetisers')}
                    </p> */}

                    {/* <p onClick={() => history.push('/advertise')}>
                      {t('Advertise')}
                    </p> */}

                    {/* <p onClick={() => history.push('/rent-our-space')}>
                      {t('Rent our Space')}
                    </p> */}

                    {/* <p onClick={() => history.push('/bar-offers')}>
                      {t('Bar Offers')}
                    </p> */}

                    {/* <p onClick={() => history.push('/book-event')}>
                      {t('header-footer-menu.Book-An-Event')}
                    </p> */}
                    {/* {isFooterItemActive("Promotion n Offers") && (
                      <p onClick={() => history.push("/promotions-and-offers")}>
                        {t("Promotions & Offers")}
                      </p>
                    )} */}
                    {/* {isNavItemActive('Languages') && (
                      <p onClick={() => setModalShow(true)}>
                        {t('Change Language')}
                      </p>
                    )} */}
                    {/* <p onClick={() => history.push("/cinema-listing")}>
                      Cinema Listing
                    </p> */}
                    <p className="copyright-text text-center">
                      © {t('All Rights Reserved')}
                    </p>
                  </div>
                  {loggedInUser ? (
                    <button
                      className="btn-main mx-auto mb-4"
                      onClick={() => dispatch(logout({ history }))}
                    >
                      {t('Logout')}
                    </button>
                  ) : (
                    <div className="d-flex">
                      <button
                        className="btn-main btn-main-sm mx-auto mb-4"
                        onClick={() => setLogin(true)}
                      >
                        {t('Sign In')}
                      </button>
                      {/* <button
                        className="btn-main btn-main-sm mx-auto mb-4"
                        onClick={() => setRegister(true)}
                      >
                        Sign Up
                      </button> */}
                    </div>
                  )}
                  {/* <div className="dropdown-bg">
                    <Dropdown className="">
                      <Dropdown.Toggle
                        className="dropdownClassic"
                        id="dropdown-basic"
                      >
                        <span className="jod">
                          {titleCase(selected_language?.lang_name)}
                        </span>
                      </Dropdown.Toggle>

                      <Dropdown.Menu
                        align="right"
                        className="dropdownItems align-right"
                      >
                        {languages?.map((language, index) => (
                          <Dropdown.Item
                            key={index}
                            onClick={() => {
                              onSelectLanguage(language);
                            }}
                            className="selected"
                          >
                            {language?.lang_name}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> */}
                  {true && languages?.length > 0 && (
                    <aside className="cinema-select">
                      {/* <p onClick={() => setCinemaSelected(true)}>
                        <img
                          src={activeMap}
                          alt=""
                          width="20px"
                          className="pr-2"
                        />
                        {selectedCinema
                          ? selectedCinema.cinema_name
                          : t("home_page.select-cinema")}
                      </p> */}

                      <article className={cinemaSelected ? 'visible' : ''}>
                        <img
                          src={close}
                          onClick={(e) => {
                            setCinemaSelected(false);
                          }}
                          alt=""
                        />
                        <ul className="list-unstyled">
                          {languages?.map((language, index) => (
                            <li
                              key={index}
                              className={
                                selected_language.lang_name === language?.lang_name
                                  ? 'active'
                                  : ''
                              }
                              onClick={() => {
                                setCinemaSelected(false);
                                onSelectLanguage(language);
                              }}
                            >
                              {(
                                language?.alternate_lang_name ??
                                language?.lang_name ??
                                ''
                              ).toUpperCase()}
                            </li>
                          ))}
                        </ul>
                        <>
                          <MyVerticallyCenteredModal
                            show={modalShow}
                            onHide={() => setModalShow(false)}
                          />
                        </>
                      </article>
                    </aside>
                  )}
                </div>
              </div>
              {/* Menu ends */}
            </motion.div>
          </div>
        </section>
      </>
    );
  } else {
    return null;
  }
};

export default withNamespaces()(MobileMenu);
