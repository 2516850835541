export const API_VERSION = "v1.0";
const HOST_URL = `epixcinemas.brij.tech`;
const LIVEBASEURL = `https://epixcinemas-api.brij.tech`;
const DEVBASEURL = `http://localhost:8000`;
// const DEVBASEURL = `https://cinematic-server-gdc-3.brij.tech`;
// const DEVBASEURL = `http://shringar.brij.tech:3950`;

export const BASEURL =window.location.host === HOST_URL ? LIVEBASEURL : DEVBASEURL;
export const IMG_BASEURL = BASEURL;
export const ARENA_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjYwN2VkY2MwLTNjODQtNGRhZC1hY2VmLTBjN2FhYjNmMzU1MyIsImlhdCI6MTYwNjg5MzA0MCwiZXhwIjoxNjA5NDg1MDQwfQ.3dkWIqaDYXjJ0Eayq_nL8Q0rbmqfYcyI3NLZbcbKh8Y`;
export const EPIXCINEMAS_TOKEN = `eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbiI6IjU4ZDJlZTM4LTI4MDctNDQ4My05YzVhLThhZDYxMjI2OGY0MSIsImlhdCI6MTcwMzgzNTMzNn0.3eAPpdCzFTsGuHbgeJFCZknPbWS0WjDD1aSnNy_HRTk`;
export const TOKEN = EPIXCINEMAS_TOKEN;
