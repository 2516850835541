import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';
// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import tc from '@assets/pictures/svgs/tc.png';
import useIsMobile from '../customHooks/useIsMobile';
import useIsDesktop from '../customHooks/useIsDesktop';
import useIsTablet from '@src/customHooks/useIsTablet.js';
const TnCNewContainer = ({ t }) => {
  const dispatch = useDispatch();
  const [cms_pages_data, setcms_pages_data] = useState([]);
  const [cmsPagesData, setCMSPagesData] = useState([]);

  const isDeskTop = useIsDesktop();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const fetchCMSPagesData = async () => {
    try {
      const response = await GenericService.GetMsPage(2);
      const { data } = response;
      if (data?.status) {
        setCMSPagesData(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCMSPagesData();
  }, []);

  // const addHRAfterH4 = (htmlContent) => {
  //   return htmlContent.replace(/(<h4\b[^>]*>.*?<\/h4>)/g, '$1<hr style="width: 5%; height: 3px; background-color: orange; border: none; margin: 8px 0 15px" />');
  // };

  const addHRAfterH4 = (htmlContent) => {
    return htmlContent.replace(
      /(<h4\b[^>]*>.*?<\/h4>)/g,
      '<span class="custom-h4">$1</span><hr style="width: 5%; height: 3px; background-color: orange; border: none; margin: 8px 0 15px" />',
    );
  };

  const renderHTMLContent = (htmlContent) => {
    return { __html: addHRAfterH4(htmlContent) };
  };

  const ulStyle = {
    fontSize: '14px',
    color: '#aca8b7',
    listStyleType: 'none',
    lineHeight: '2',
    fontWeight: 'normal',
    marginTop: '20px',
  };

  // const overlayPosition = () => {
  //   if (isDeskTop) {
  //     return {
  //       position: 'absolute',
  //       top: '50%',
  //       left: '22%',
  //       transform: 'translate(-50%, -50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   } else if (isTablet) {
  //     return {
  //       position: 'absolute',
  //       top: '50%',
  //       left: '30%',
  //       transform: 'translate(-50%, -50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   } else if (isMobile) {
  //     return {
  //       position: 'absolute',
  //       top: '50%',
  //       left: '50%',
  //       transform: 'translateX(-50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   }
  // };

  // const overlayPosition12 = () => {
  //   if (isDeskTop) {
  //     return {
  //       position: 'absolute',
  //       top: '15%',
  //       left: '10%',
  //       transform: 'translate(-50%, -50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   } else if (isTablet) {
  //     return {
  //       position: 'absolute',
  //       top: '15%',
  //       left: '15%',
  //       transform: 'translate(-50%, -50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   } else if (isMobile) {
  //     return {
  //       position: 'absolute',
  //       top: '15%',
  //       left: '30%',
  //       transform: 'translateX(-50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   }
  // };
  return (
    <>
      <div className="tnc_new align_new" style={{ marginTop: '0px' }}>
        {/* <div className="tncBanar" style={{ position: 'relative', margin: '0' }}>
        <img src={tc} alt="cinema banner" style={{ width: '100%', height:"210px" }} />

         <div className="bannar_overlay_breadcrumb" style={overlayPosition12()}>
           <BreadCrumb
               firstHalf={`${t('More')} >`}
               secondHalf={`${t('Terms')} & ${t('Conditions')}`}
             />
           </div>
           
         <div className="banner-overlay" style={overlayPosition()}>
            <CenteredTitle
              firstHalf={`${t('Terms')} & ${t('Conditions')}`}
              secondHalf={''}
            />
          </div>
        </div> */}
        <div style={{ position: 'relative' }}>
          <img
            src={tc}
            alt="Image Banner"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '210px',
              // zIndex: 1,
            }}
          />
          <div
            className="left_right_align"
            style={{ zIndex: 2, position: 'relative' }}
          >
            <BreadCrumb
               firstHalf={`${t('More')} >`}
               secondHalf={`${t('Terms')} & ${t('Conditions')}`}
             />
            <CenteredTitle
              firstHalf={`${t('Terms')} & ${t('Conditions')}`}
              secondHalf={''}
            />
          </div>
        </div>

        <section className="row">
          <article className="col-lg-10 col-xl-12 tnc_back">
            {cmsPagesData.map((page, index) => (
              <div
                key={index}
                style={ulStyle}
                dangerouslySetInnerHTML={renderHTMLContent(page.page_content)}
              />
            ))}
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TnCNewContainer);
