import React, { useEffect, useState, useContext, useRef } from 'react';
import { withNamespaces } from 'react-i18next';
import GenericService from '@apiService/GenericService';
import { useDispatch, useSelector } from 'react-redux';
import pp from '@assets/pictures/svgs/pp.png';
import useIsMobile from '../customHooks/useIsMobile';
import useIsDesktop from '../customHooks/useIsDesktop';
import useIsTablet from '@src/customHooks/useIsTablet.js';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
const TnCNewContainer = ({ t }) => {
  const dispatch = useDispatch();
  const [cms_pages_data, setcms_pages_data] = useState([]);
  const [cmsPagesData, setCMSPagesData] = useState([]);

  const isDeskTop = useIsDesktop();
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();

  const fetchCMSPagesData = async () => {
    try {
      const response = await GenericService.GetMsPage(5);
      const { data } = response;
      if (data?.status) {
        setCMSPagesData(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCMSPagesData();
  }, []);

  // const addHRAfterH4 = (htmlContent) => {
  //   return htmlContent.replace(
  //     /(<h4\b[^>]*>.*?<\/h4>)/g,
  //     '<span class="custom-h44">$1</span><hr style="width: 5%; height: 3px; background-color: orange; border: none; margin: 8px 0 8px" />',
  //   );
  // };
  const addHRAfterH4 = (htmlContent) => {
    return htmlContent.replace(
      /(<h4\b[^>]*>)(.*?)(<\/h4>)/g,
      (match, openTag, content, closeTag) => {
        return `${openTag} <span class="custom-h4" style="display: block; margin-top: 20px; color: white;">${content}</span> ${closeTag}<hr style="width: 5%; height: 3px; background-color: orange; border: none; margin: 8px 0 8px" />`;
      },
    );
  };

  const renderHTMLContent = (htmlContent) => {
    return { __html: addHRAfterH4(htmlContent) };
  };

  const ulStyle = {
    fontSize: '14px',
    color: '#aca8b7',
    listStyleType: 'none',
    lineHeight: '2',
    fontWeight: 'normal',
    marginTop: '20px',
  };

  // const overlayPosition = () => {
  //   if (isDeskTop) {
  //     return {
  //       position: 'absolute',
  //       top: '50%',
  //       left: '22%',
  //       transform: 'translate(-50%, -50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   } else if (isTablet) {
  //     return {
  //       position: 'absolute',
  //       top: '50%',
  //       left: '30%',
  //       transform: 'translate(-50%, -50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   } else if (isMobile) {
  //     return {
  //       position: 'absolute',
  //       top: '50%',
  //       left: '50%',
  //       transform: 'translateX(-50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   }
  // };

  // const overlayPosition12 = () => {
  //   if (isDeskTop) {
  //     return {
  //       position: 'absolute',
  //       top: '15%',
  //       left: '10%',
  //       transform: 'translate(-50%, -50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   } else if (isTablet) {
  //     return {
  //       position: 'absolute',
  //       top: '15%',
  //       left: '15%',
  //       transform: 'translate(-50%, -50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   } else if (isMobile) {
  //     return {
  //       position: 'absolute',
  //       top: '15%',
  //       left: '30%',
  //       transform: 'translateX(-50%)',
  //       textAlign: 'center',
  //       color: 'white',
  //     };
  //   }
  // };

  return (
    <>
      <div className="tnc_new align_new" style={{ marginTop: '0px' }}>
        {/* <div className="left_right_align">
          <BreadCrumb
            firstHalf={`${t('More')} >`}
            secondHalf={`${t('Privacy Policy')}`}
          />
          <CenteredTitle firstHalf={`${t('Privacy Policy')}`} secondHalf={''} />
        </div> */}

        <div class="container123">
          <img src={pp} alt="Snow" style={{ width: '100%' }} />
          <div class="bottom-left">
            <CenteredTitle firstHalf={`${t('Privacy Policy')}`} secondHalf={''} />
          </div>

          {!isMobile && (
            <div class="top-left">
              <BreadCrumb
                firstHalf={`${t('More')} >`}
                secondHalf={`${t('Privacy Policy')}`}
              />
            </div>
          )}
        </div>

        <section className="row">
          {/* <article className="col-lg-10 col-xl-12 tnc_back">
            <div className='tncpara' style={{ marginTop: '20px' }}>
              Epix Cinemas (Epix Cinemas W.L.L) respects your privacy. We recognize that when you choose to provide us with information about yourself, you trust us to act in a responsible manner. This information helps us improve your movie going experience with better content, services and opportunities. This policy tells you about the information gathering and dissemination that we conduct. As we continue to grow, this policy may change, so please check back periodically for updates. We always adopt safe practices to ensure full protection to the personal information that you provide us with, over the Internet whilst acknowledging the sensitive nature of such information.
            </div>
            <div className='tncpara'>
              Therefore, this privacy statement is designed to maximize your ability to protect your personal information, while at the same time permit us to provide you with the opportunity to obtain interesting and useful information, products and services. However, at no time are you obligated to provide us any personal information, and if at any time you no longer wish to receive communications from us, you are free to opt out of receiving such communications from us. Because this privacy Statement applies to any person who provides information to Epix Cinemas through its website: <a href="https://www.epixcinemas.com/">www.epixcinemas.com </a>, we strongly recommend you to review this privacy statement in its entirety.
            </div>
            <h4 className="tnc_new_text_highlighted mb-2">
              What Information Is, Or May Be, Collected From You
            </h4>
            <hr style={{ width: '5%', height: '3px', backgroundColor: 'orange', border: 'none', margin: '0px 0' }} />
            <div className='tncpara' style={{ marginTop: '20px' }}>
              In order to enable you to register to receive information, products or services available through our web site, or to permit us to respond to your inquiries, our sites registration forms may require that you provide contact information (like your name, mobile number and e-mail address), demographic information (like your residential area, age, or income level), and/or financial information (like account or credit card numbers). Our online surveys may ask you for contact information, demographic information, and lifestyle-related information (like your hobbies, favorite athletic teams, buying preferences, etc.).
            </div>
            <div className='tncpara'>
              We may run contests on our site in which we ask visitors for contact information and demographic information.
            </div>
            <div className='tncpara'>
              We may track usage through cookies. A cookie is a small data file written to a user's hard drive. Cookies may be used to, among other things, allow automated log-in and may contain information such as a login ID or other information about preferences or Internet use. Third party advertisers that we work with may also place cookies on browsers of users of our service.
            </div>
            <div className='tncpara'>
              We also collect information about your movie preferences. For example, we may collect, among other things, information such as what movies you purchase tickets to or what theatres you attend. We may save information sent or posted to the service. For example, we may save messages posted in our chat rooms or other message areas or feedback left for other users. We may collect correspondence, such as emails or letters, sent to us. We may collect user responses to online polls, ads, surveys, electronic newsletters and questionnaires.
            </div>
            <div className='tncpara'>
              We may also collect the internet protocol (I.P) address and e-mail address of visitors to our web site, the e- mail addresses of those who post messages to a bulletin board, and the e-mail addresses of those who communicate with us through e-mail. An IP address is a number that is automatically assigned to your computer whenever you are surfing the web, allowing web servers to locate and identify your computer In addition to this personal information, our web servers automatically collect web site use information from you when you visit our web site. Web site usage information is anonymous information about our visitors that describes how our web site is used and navigated, including the number and frequency of visitors and subscribers to each web page, their actions taken during visits to the site, and the length of their stays. Web site usage information does not contain personally identifiable information.
            </div>
            <h4 className="tnc_new_text_highlighted mb-2">
              Protection Of Your Information
            </h4>
            <hr style={{ width: '5%', height: '3px', backgroundColor: 'orange', border: 'none', margin: '0px 0' }} />
            <div className='tncpara' style={{ marginTop: '20px' }}>
              We are committed to protecting the security of your information, including personal information, and other information collected relating to the website usage. We use several procedures to help protect your personal information from unauthorized access, use, or disclosure. When we transmit highly confidential information (such as a credit card number or password) over the Internet, we protect it through the use of encryption, such as the Secure Socket Layer (SSL) protocol. We suggest that if a password is used to help protect your accounts and personal information, it is your responsibility to keep your password confidential. We further recommend you not to share this information with anyone. Further if you are sharing a computer with anyone you should always log out before leaving a site or service to protect access to your information from subsequent users. We further strongly recommend that you do not select the option "Remember Me" whilst logging in for security of your password and thereby ensuring the security of your personally identifiable information.
            </div>
            <div className='tncpara'>
              credit card information is used only for processing payments and not used for other purposes.
            </div>
            <h4 className="tnc_new_text_highlighted mb-2">
              Security
            </h4>
            <hr style={{ width: '5%', height: '3px', backgroundColor: 'orange', border: 'none', margin: '0px 0' }} />
            <div className='tncpara' style={{ marginTop: '20px' }}>
              Epix Cinemas has implemented security measures to protect against the loss, misuse and alteration of the information under our control. We protect the secure areas of our site with a firewall. Although Epix Cinemas has implemented adequate security measures, the site is completely virus-free and the user is advised to employ virus scans for extra security, as Epix Cinemas is not liable for any virus picked up at the time of transmission.
            </div>

            <h4 className="tnc_new_text_highlighted mb-2">
              Children's Privacy
            </h4>
            <hr style={{ width: '5%', height: '3px', backgroundColor: 'orange', border: 'none', margin: '0px 0' }} />
            <div className='tncpara' style={{ marginTop: '20px' }}>
              We do not knowingly collect information from children under the age of 13 and as such do not target our website(s) to such children under the age of 13 years. However, our site may contain links to content providers that collect information from children under the age of 13. We have no control over such content providers and are not responsible for the privacy practices or the content of other web sites. It is stated for general information that internet offers open access to a wide range of information; hence it is important that parents and guardians supervise and prevent their children's access to inappropriate content, email or chat sessions.
            </div>

            <h4 className="tnc_new_text_highlighted mb-2">
              Changes To This Statement
            </h4>
            <hr style={{ width: '5%', height: '3px', backgroundColor: 'orange', border: 'none', margin: '0px 0' }} />
            <div className='tncpara' style={{ marginTop: '20px' }}>
              If we change our privacy statement, we will post the revised statement here, with an updated revision date. However, in the event of making significant changes to our statement, we may also notify you by other means, such as sending an email or posting a notice on <a href="https://www.epixcinemas.com/">www.epixcinemas.com </a>
            </div>
          </article> */}
          <article className="col-lg-10 col-xl-12 tnc_back">
            {cmsPagesData.map((page, index) => (
              <div
                key={index}
                style={ulStyle}
                dangerouslySetInnerHTML={renderHTMLContent(page.page_content)}
              />
            ))}
          </article>
        </section>
      </div>
    </>
  );
};

export default withNamespaces()(TnCNewContainer);
