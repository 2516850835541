import React, { useState } from 'react';
import { withNamespaces } from 'react-i18next';

// Components
import Button from '@components/partials/Button';
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import { setShowLoginPopup } from '../../../store/auth/authActions';
import { useDispatch } from 'react-redux';
import useIsMobile from '@src/customHooks/useIsMobile';
import useIsTablet from '@src/customHooks/useIsTablet';
import aboutUsImg1 from '@assets/pictures/about_us_1.jpeg';
import aboutUsImg2 from '@assets/pictures/about_us_2.jpeg';
import aboutUsImg3 from '@assets/pictures/about_us_3.jpeg';
import aboutUsImg4 from '@assets/pictures/about_us_4.jpeg';
import aboutUsImg5 from '@assets/pictures/about_us_5.jpeg';
import aboutUsImg6 from '@assets/pictures/about_us_6.jpeg';
import aboutUsMap from '@assets/pictures/about_us_new_map.jpeg';
import abouthomeimage from '@assets/pictures/abouthomeimage.png'

const AboutUsHeroText = (props) => {
  const { t, classes = '' } = props;
  const isMobile = useIsMobile();
  const isTablet = useIsTablet();
  const [register, setRegister] = useState(false);

  const dispatch = useDispatch();
  const handleLoginPopup = (toShow) => {
    setRegister(toShow);
    dispatch(setShowLoginPopup(toShow));
  };

  return (
    <>
      <section className={`container-fluid about_us_hero_text ${classes}`}>
        <div className="row">
          <article className="col-lg-10 col-xl-12 mx-auto">
            {/* <BreadCrumb firstHalf={`${t('More')} >`} secondHalf={t('About Us')} /> */}
            {/* <CenteredTitle firstHalf={'Welcome To Epix Cinema'} secondHalf={''} /> */}
            <h1>
              <span style={{ fontWeight: '600' }}>Welcome To</span>
              <span className='aboutheading' style={{ color: '#ffb230' }}> Epix Cinema</span>
            </h1>
            <div className="row">
              <section className="col-xl-8 col-md-12" style={{ marginTop: '30px' }}>
                <span
                  className={`f-14 ${isMobile ? 'ml-0' : ''}`}
                  style={{ margin: '5px' }}
                >
                  Thank you for your interest in Epix Cinemas!
                </span>
                <section className='mt-4' style={{ color: '#aca8b7' }}>
                  <p
                    className={`about_us_hero_text_synopsis aboutpara ${isMobile ? 'mb-3 mt-3' : ''
                      }`}
                    style={{ margin: '12px 5px' }}
                  >
                    Epix Cinemas is owned and operated by Dadabai Holding, a leading family business group in the Kingdom of Bahrain. The Dadabai family has a rich legacy in the Kingdom of Bahrain, having started its operations as a leading family Group in the early 1950's. The Group grew and rose to the challenges of the times, playing a significant role in the transformation of the Kingdom. Over the years, the Group has kept pace with the changes in the region, adapting, innovating and evolving to meet the growing demands of an emerging country on a fast track of economic growth.
                  </p>
                  <p
                    className={`about_us_hero_text_synopsis aboutpara ${isMobile ? 'mb-3 mt-3' : ''
                      }`}
                    style={{ margin: '12px 5px' }}
                  >
                    The Group draws strength from its in-depth knowledge and understanding of the market and is committed to realize its full potential in the core areas of its operations spanning construction, education, real estate, logistics, car rental, hospitality management, training and entertainment.

                  </p>
                  <p
                    className={`about_us_hero_text_synopsis aboutpara ${isMobile ? 'mb-3 mt-3' : ''
                      }`}
                    style={{ margin: '12px 5px' }}
                  >

                    EPIX Cinemas at Dana Mall, Sanabis, Bahrain will be housing 10 screens, with a full capacity of over 1100 seats. The smallest screen has a capacity of 75 seats and the largest cinema with multi-level balcony seating can entertain 300 people. The grand VIP theatre can accommodate more than 50 guests with fully reclining seats and a dedicated lounge and F&B service. The kid's cinema will have added attractions with a soft play area and slides incorporated into the cinema hall. There will be an array of the latest technology and experience for the first time in Bahrain, such as the acoustic systems from Dolby Atmos.
                  </p>
                </section>
                {/* <span
                  className={`f-14 ${isMobile ? 'ml-0 mb-2' : ''}`}
                  style={{ margin: '0px' }}
                >
                  Distributors & Exhibitors{' '}
                </span> */}
                {/* <p
                  className={`about_us_hero_text_synopsis ${isMobile ? 'mb-3 mt-2' : ''
                    }`}
                  style={{ margin: '12px 5px' }}
                >
                  We are operated by PHF (Phars Film), which has started in 1964 and
                  has since become the Middle East’s market leader in film
                  distribution and cinema exhibition. PHF unequaled distribution
                  network and stakeholder partnerships now oversee the regional
                  distribution and screening of over 200 films each year.
                </p> */}
              </section>

              {/* <section className="col-xl-8 col-md-12">
                <img className="about-us-map" src={aboutUsMap}></img>
              </section> */}
              <section className="col-xl-4 col-md-12">
                <img className="about-us-map" src={abouthomeimage}></img>
              </section>
            </div>
            {/* <hr className="about_us_hr" /> */}
            {/* <div className="row achievments_wrapper justify-content-center">
              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg1} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg2} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg3} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg4} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg5} />
              </div>

              <div className="col-xl-3 col-md-4">
                <img src={aboutUsImg6} />
              </div>
            </div> */}
          </article>
        </div>
      </section>
    </>
  );
};

export default withNamespaces()(AboutUsHeroText);
