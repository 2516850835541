import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import { sendCareerMailStar } from '@store/auth/authActions';
import { HOST_URL, BASEURL } from '../config/index';
import { motion } from 'framer-motion';
import { fetchAllCinemas, selectedCinema } from '@store/cinemas/cinemasActions';

function AdvertiseForm({ t }) {
    const dispatch = useDispatch();

    const [email, setEmail] = useState(``);
    const [name, setName] = useState(``);
    const [mobile, setMobile] = useState(``);
    const [company, setCompany] = useState(``);
    const [salary, setSalary] = useState(0);
    const [visa, setVisa] = useState(``);
    const [experience, setExperience] = useState(``);
    const [suggestions, setSuggestions] = useState(``);
    const [cinemaname, setCinemaname] = useState(``);
    const [submitted, setSubmitted] = useState(false);
    const [status, setStatus] = useState(false);
    const [validForm, setValidForm] = useState(false);
    const [FileName, setFileName] = useState('');
    const [ImageURL, setImageURL] = useState('');
    const { pathname } = useLocation();
    const cinemas = useSelector((state) => state.cinemas);
    const { cinemasLoading, allCinemas } = cinemas;


    useEffect(() => {
        dispatch(fetchAllCinemas());
    }, [dispatch]);
    const isBookEvent = pathname === '/book-event';

    let globalOptions = useSelector((state) => state.movies.globalOptions);
    const stc_id =
        globalOptions.find(
            (g) => g.go_key === `MYCINEMA_FEEDBACK_SUPPORT_TICKET_CATEGORY_ID`,
        )?.go_value ?? 0;
    console.log({ stc_id });

    const auth = useSelector((state) => state.auth);
    const cinema = useSelector((state) => state.movies.selectedCinema);
    const { loggedInUser } = auth;
    let customer_id = loggedInUser?.cust_id || null;
    let contact_no = loggedInUser?.cust_mobile || null;
    let cinema_id = cinema?.cinema_id || null;

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(function fieldMarginReducer() {
        const fields = document.querySelectorAll('.fields');
        fields.forEach(function (field) {
            console.log(field);
            if (
                field.nextElementSibling &&
                field.nextElementSibling.classList.contains('error')
            ) {
                field.style.marginBottom = '3px';
            }
        });
    });

    const uploadFile = (e) => {
        let errMsg = '';
        let image = e.target.files[0];
        let url = `${BASEURL}/uploadfileContactUs`;
        setFileName(image.name);
        let formdata = new FormData();
        formdata.append('file', image);
        axios
            .post(url, formdata, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
            .then(function (response) {
                const { data } = response;
                console.log({ data }, `${data.imageBaseURL}${data.path}`);

                if (data.status) {
                    console.log(`${data.imageBaseURL}${data.path}`);
                    // self.ImageURL = `${data.imageBaseURL}${data.path}`;
                    setImageURL(data.fullpath);

                    let i = 0;
                    if (i == 0) {
                        i = 1;
                        let elem = document.getElementById('myBar');
                        let width = 1;
                        let id = setInterval(frame, 10);
                        function frame() {
                            if (width >= 100) {
                                clearInterval(id);
                                i = 0;
                            } else {
                                width++;
                                elem.style.width = width + '%';
                            }
                        }
                    }
                } else {
                    toast.error(data.message || 'Upload Error');

                }
            })
            .catch(function (error) {
                toast.error(error);

                console.log(error);
            });
    };
    const submitForm = (e) => {
        e.preventDefault();
        setSubmitted(true);
        if (name && mobile && email && salary && visa && FileName && experience) {
            setValidForm(true);
        }
    };

    useEffect(() => {
        if (validForm) {
            dispatch(
                sendCareerMailStar({
                    name: name,
                    email,
                    contact_no: mobile,
                    organization_name: company,
                    message: suggestions,
                    selectedcinema_name: cinemaname,

                    uploadedFilePath: ImageURL,
                    salary: salary,
                    visa: visa,
                    experience: experience,
                }),
            ).then(() => {
                setStatus(true);
            });
        }
    }, [validForm]);

    useEffect(() => {
        if (status) {
            setEmail('');
            setName('');
            setMobile('');
            setCompany('');
            setCinemaname('');
            setSuggestions('');
            setFileName('');
            setSalary(0);
            setVisa('');
            setExperience('');
        }
    }, [status]);

    return (
        <>
            <div className="header-space container-fluid container-xl feedback-container">
                <div className='headingcontainer'>
                    <h2 className="mb-0 font-weight-bold text-muted text-sm">{t('Apply Here')}</h2>
                </div>
                <div className="container-fluid align_new text-center">
                </div>
                <motion.div
                    className="feedback"
                    initial={{ scale: 0.8 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.75 }}
                >
                    {status && (
                        <div className="email-success-message">
                            {t('Message sent successfully')}{' '}
                        </div>
                    )}

                    <section className="container-fluid container-xl mt-2 location_wrapper">
                        <form onSubmit={submitForm} >
                            <div className="row">
                                <div className="col-xl-6 col-md-12">
                                    <label>Full Name</label>
                                    <input
                                        className="fields"
                                        type="text"
                                        placeholder={t('Enter Your Name')}
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />

                                    <p className="error">
                                        {submitted &&
                                            !status &&
                                            name === '' &&
                                            t('Please Enter your Name')}
                                    </p>
                                </div>

                                <div className="col-xl-6 col-md-12">
                                    <label>Contact Number</label>
                                    <input
                                        className="fields"
                                        type="number"
                                        placeholder={t('Enter Your Contact Number')}
                                        value={mobile}
                                        onChange={(e) => setMobile(e.target.value)}
                                    />

                                    <p className="error">
                                        {submitted &&
                                            !status &&
                                            mobile === '' &&
                                            t('Please Enter your Mobile Number')}
                                    </p>
                                </div>

                                <div className="col-xl-6 col-md-12">
                                    <label>Email ID</label>
                                    <input
                                        className="fields"
                                        type="email"
                                        placeholder={t('Enter Email Address')}
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                    />

                                    <p className="error">
                                        {submitted &&
                                            !status &&
                                            email === '' &&
                                            t('Please Enter Your Email Address')}
                                    </p>
                                </div>
                                {false && (
                                    <div className="col-xl-6 col-md-12">
                                        <input
                                            className="fields"
                                            type="text"
                                            placeholder={t('Enter Your Company Name')}
                                            value={company}
                                            onChange={(e) => setCompany(e.target.value)}
                                        />
                                    </div>
                                )}
                                <div className="col-xl-6 col-md-12">
                                    <label>Preferred location</label>
                                    <select
                                        className={'fields'}
                                        onChange={(e) => {
                                            setCinemaname(e?.target?.value);
                                        }}
                                    >
                                        <option
                                            value=""
                                            disabled
                                            selected
                                            hidden
                                            style={{ color: 'var(--light-4)' }}
                                        >
                                            Select Cinema
                                        </option>
                                        {allCinemas?.map((cinemaData, index) => {
                                            return (
                                                <option value={cinemaData?.cinema_name}>
                                                    {cinemaData?.cinema_name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    <p className="error">
                                        {submitted &&
                                            !status &&
                                            cinemaname === '' &&
                                            t('Please Select Cinema Name')}
                                    </p>
                                </div>

                                <div className="col-xl-6 col-md-12">
                                    <label>Expected salary</label>
                                    <input
                                        className="fields"
                                        type="number"
                                        placeholder={t('Enter Expected salary')}
                                        value={salary}
                                        onChange={(e) => setSalary(e?.target?.value)}
                                    />

                                    <p className="error">
                                        {submitted &&
                                            !status &&
                                            (salary <= 0 || salary == 0) &&
                                            t('Please Enter Your Expected salary')}
                                    </p>
                                </div>
                                <div className="col-xl-6 col-md-12">
                                    <label>Experience</label>
                                    <input
                                        className="fields"
                                        type="number"
                                        placeholder={t('Enter Experience In Year')}
                                        value={experience}
                                        onChange={(e) => setExperience(e?.target?.value)}
                                    />

                                    <p className="error">
                                        {submitted &&
                                            !status &&
                                            (experience <= 0 || experience == 0) &&
                                            t('Please Enter Experience In Year')}
                                    </p>
                                </div>
                                <div className="col-xl-6 col-md-12">
                                    <label>Visa Status</label>
                                    <input
                                        className="fields"
                                        type="text"
                                        placeholder={t('Enter Visa Status')}
                                        value={visa}
                                        onChange={(e) => setVisa(e?.target?.value)}
                                    />

                                    <p className="error">
                                        {submitted &&
                                            !status &&
                                            (visa <= 0 || visa == 0) &&
                                            t('Please Enter Your Visa Status')}
                                    </p>
                                </div>
                                <div className="col-xl-6 col-md-12">
                                    <label>Upload CV</label>
                                    <label className="custom-file-upload mb-1">
                                        <input
                                            type="file"
                                            id="resume"
                                            onChange={(e) => uploadFile(e)}
                                            accept="application/pdf,.doc,.docx,application/msword"
                                            className="upload_file"
                                            name="resume"
                                        />
                                        {FileName == '' ? 'Upload Your CV' : FileName}
                                    </label>
                                    {FileName != '' && (
                                        <div id="myProgress">
                                            <div id="myBar"></div>
                                        </div>
                                    )}
                                    <p className="" style={{ color: '#4472C4', fontSize: '13px' }}>
                                        {t('* Please Upload Your CV in Pdf or Word Document')}
                                    </p>
                                    <p className="error">
                                        {submitted &&
                                            !status &&
                                            FileName === '' &&
                                            t('Please Upload Your CV')}
                                    </p>
                                </div>
                                <div className='message_box mt-3 mx-auto text-justify'>
                                    <p className='message font-italic'>Please clearly state your visa status, nationality and work location preference in your application.</p>
                                </div>
                                <div className="col-12 col-sm-5 mx-auto">
                                    <button
                                        className="btn-main mx-auto"
                                        type="submit"

                                    >
                                        {t('Apply')}
                                    </button>
                                </div>
                            </div>
                            <div className="text-center mx-auto mt-3">
                                <h4 className="font-weight-bold" style={{ color: '#4472C4' }}>OR</h4>
                                <div className='mt-3 mx-auto'>
                                    <h5 className="font-weight-bold">Please share your CV at <a href="mailto:hr@starcinemas.com" style={{ color: '#4472C4' }}>hr@starcinemas.com</a></h5>
                                </div>
                            </div>
                        </form>
                    </section>
                </motion.div>
            </div>
        </>
    );
}

export default withNamespaces()(AdvertiseForm);
