import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import useIsMobile from '../../customHooks/useIsMobile';
import { motion } from 'framer-motion';

// Components
import MovieCardNew from './MovieCardNew';
import FestivalCard from './FestivalCard';
import Slider from 'react-slick';

// Images
import poster from '@assets/pictures/sample-poster-new.png';
import festivalPoster from '@assets/pictures/sample-fest.png';
import { ReactComponent as ArrowRight } from '@assets/pictures/svgs/slider-arrow-left.svg';
import HorizontalCard from './HorizontalCard';

const MovieNFests = (props) => {
  const {
    t,
    category,
    subCategory,
    movieCount,
    slider,
    type,
    section,
    search,
    showMovieList = undefined,
    showFestivalList = undefined,
  } = props;
  const history = useHistory();
  const isMobile = useIsMobile();
  const { pathname } = useLocation();

  // STATE
  const [swiping, setSwiping] = useState(false);
  const [onClickEventStatus, setonClickEventStatus] = useState(false);
  const [isDedicatedPage, setIsDedicatedPage] = useState(false);

  // Slider Settings
  const heroSlider = {
    variableWidth: type == 'mini_festival' && section == 3 ? true : false,
    className:
      type == 'mini_festival' && section == 3 ? 'slider variable-width' : '',
    pauseOnFocus: true,
    arrows: true,
    dots: false,
    infinite: false,
    useTransform: false,
    onSwipe: () => {
      console.log({ onSwipe: 'started' });
      setSwiping(true);
      if (!!window.queueMicrotask) {
        console.log({ onSwipe: 'queueMicrotaskd' });
        window.queueMicrotask(() => {
          setSwiping && setSwiping(false);
          console.log({ swiping });
        });
      }
    },
    slidesToShow: type === 'mini_festival' ? 5 : 5,
    slidesToScroll: type === 'mini_festival' ? 5 : 5,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          arrows: true,
          slidesToShow: type === 'mini_festival' ? 5 : 5,
          slidesToScroll: type === 'mini_festival' ? 5 : 5,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          arrows: true,
          slidesToShow: type === 'mini_festival' ? 4 : 5,
          slidesToScroll: type === 'mini_festival' ? 4 : 5,
        },
      },
      {
        breakpoint: 992,
        settings: {
          arrows: true,
          slidesToShow: type === 'mini_festival' ? 3 : 4,
          slidesToScroll: type === 'mini_festival' ? 3 : 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: true,
          slidesToShow: type === 'mini_festival' ? 2 : 2,
          slidesToScroll: type === 'mini_festival' ? 2 : 2,
        },
      },
    ],
  };

  useEffect(() => { }, [isMobile, type, section]);

  const handleSwiping = (onClick) => {
    if (swiping) {
      setSwiping(false);
    } else {
      // Actual Click Actions
      onClick && onClick();
    }
  };

  const goToPage = () => {
    if (!isDedicatedPage) {
      if (type === 'on_demand') {
        history.push('/new-on-demand');
      } else if (type === 'now_showing') {
        history.push('/now-showing');
      } else if (type === 'mini_festival') {
        history.push('/new-mini-festivals');
      }
    }
  };

  const getUrl = (item) => {
    let url = `/private-booking-flow/${item?.movie_id}/${item?.content_lang_id}/`;
    if (section == 2) {
      url = `/private-booking-flow/${item?.movie_id}/${item?.content_lang_id}/`;
    } else if (section == 1) {
      url = `/now-showing-booking/${item?.movie_id}/${item?.content_lang_id}/`;
      // setonClickEventStatus(true);
    } else if (section == 4) {
      // if (item?.now_demand_count > 0)
      //   url = `/private-booking-flow/${item?.movie_id}/${item?.content_lang_id}/`;
      url = `/movie-details/${item?.movie_id}/${item?.content_lang_id}/3`;
    } else if (section == 5) {
      // movies of mini festival
      if (item?.now_showing_count > 0)
        url = `/now-showing-booking/${item?.movie_id}/${item?.content_lang_id}/`;
      else
        url = `/private-booking-flow/${item?.movie_id}/${item?.content_lang_id}/`;
    }
    return history.push(url);
  };

  useEffect(() => {
    if (
      pathname === '/now-showing' ||
      pathname === '/new-on-demand' ||
      pathname === 'new-mini-festivals'
    ) {
      setIsDedicatedPage(true);
    }
  }, []);

  return (
    <>
      <section
        className={`movie_n_fests_new ${isDedicatedPage ? 'is_dedicated_page' : ''
          } ${section == 3 ? '__festivals' : ''}`}
      >
        <article
          className="movie_n_fests_new_headings"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.1 }}
        >
          {category && !search && (
            <span
              className={`movie_n_fests_new_category d-none ${type === 'on_demand' ? 'text-warning' : ''
                } ${window.location.pathname === '/' ? 'd-none' : ''}`}
              onClick={() => {
                if (isMobile && showMovieList) {
                  history.push(
                    `/show-list-movies/${showMovieList.category_id}${!!showMovieList?.section_id
                      ? `/${showMovieList?.section_id}`
                      : ''
                    }`,
                  );
                }
                if (isMobile && showFestivalList) {
                  history.push(`/mini-festivals-list`);
                }
              }}
            >
              {category}
              {/* This dot below is part of design */}
              &nbsp;
              {/* .&nbsp; */}
            </span>
          )}
          {/* Sub Category */}
          {!search && false && (
            <span
              className="movie_n_fests_new_category_sub_category"
              onClick={() => {
                if (isMobile && showMovieList) {
                  history.push(
                    `/show-list-movies/${showMovieList.category_id}${!!showMovieList?.section_id
                      ? `/${showMovieList?.section_id}`
                      : ''
                    }`,
                  );
                }
                if (isMobile && showFestivalList) {
                  history.push(`/mini-festivals-list`);
                }
              }}
            >
              {t(`${subCategory}`)}
            </span>
          )}
          {/* GATEWAY TO DEDICATED PAGE */}
          {false && !search && (
            <>
              {isMobile ? (
                <>
                  {movieCount && (
                    <span
                      className="movie_n_fests_new_category_cards"
                      onClick={() => {
                        if (showMovieList) {
                          history.push(
                            `/show-list-movies/${showMovieList.category_id}${!!showMovieList?.section_id
                              ? `/${showMovieList?.section_id}`
                              : ''
                            }`,
                          );
                        }
                        if (showFestivalList) {
                          history.push(`/mini-festivals-list`);
                        }
                      }}
                    >
                      ({movieCount})
                    </span>
                  )}
                  <figure className="movie_n_fests_new_arrow_right">
                    {/*<ArrowRight />*/}
                  </figure>
                </>
              ) : (
                <span
                  className={`movie_n_fests_new_category_cards ${showMovieList ? 'pointer' : ''
                    } ${showFestivalList ? 'pointer' : ''}`}
                  onClick={() => {
                    if (showMovieList) {
                      history.push(
                        `/show-list-movies/${showMovieList.category_id}${!!showMovieList?.section_id
                          ? `/${showMovieList?.section_id}`
                          : ''
                        }`,
                      );
                    }
                    if (showFestivalList) {
                      history.push(`/mini-festivals-list`);
                    }
                  }}
                >
                  {movieCount}{' '}
                  {movieCount && parseInt(movieCount) > 1
                    ? section == 3
                      ? t('Festivals')
                      : t('Movies')
                    : section == 3
                      ? t('Festival')
                      : t('Movie')}
                </span>
              )}
            </>
          )}
        </article>
        {slider && false ? (
          // IF NEED TO SHOW AS SLIDER
          <article className="row test">
            <section className="col-12 slider_col_spacing">
              <Slider {...heroSlider}>
                {/* Loop through Cards */}
                {props?.moviesArray?.map((item, index) =>
                  section == 3 ? (
                    item?.total_movies > 0 && (
                      <FestivalCard
                        style={{ width: isMobile ? '120px' : '180px' }}
                        slider
                        festival={item}
                        key={index}
                        onClick={() => {
                          handleSwiping(() => {
                            history.push(
                              `/mini-festivals/${item?.unique_id}?mini_fest_id=${item?.unique_id}`,
                            );
                          });
                        }}
                      />
                    )
                  ) : (
                    <MovieCardNew
                      isUpcoming={section == 4}
                      slider
                      {...item}
                      key={index}
                      onClick={() => {
                        handleSwiping(() => getUrl(item));
                      }}
                    />
                  ),
                )}
              </Slider>
            </section>
          </article>
        ) : !!search && false ? (
          <aside className="row search-results book_search">
            {props?.moviesArray?.map?.((search, index) => (
              <div className="" key={index}>
                <HorizontalCard
                  isUpcoming={section == 4}
                  showType={type == 'now_showing' ? 'now_showing' : 'on_demand'}
                  type="yellow"
                  movie={search}
                  key={index}
                  setSearchBar={() => { }}
                  onChangeSearch={() => { }}
                  t={t}
                  onClick={() => {
                    // handleSwiping(() => getUrl(search))
                  }}
                />
              </div>
            ))}
          </aside>
        ) : (
          // IF NEED TO SHOW AS NORMAL STACKING CARDS
          /* Loop through Cards */
          <article className="movie_n_fests_grid">
            {props?.moviesArray?.map((item, index) =>
              category === 'Mini Festivals' ? (
                <FestivalCard
                  festival={item}
                  key={index}
                  onClick={() => {
                    handleSwiping(() => {
                      history.push(
                        `/mini-festivals${item?.unique_id}?mini_fest_id=${item?.unique_id}`,
                      );
                    });
                  }}
                />
              ) : (
                <MovieCardNew
                  isUpcoming={section == 4}
                  {...item}
                  key={index}
                  onClick={() => {
                    handleSwiping(() => getUrl(item));
                  }}
                  onClickEventStatus={onClickEventStatus}
                />
              ),
            )}
          </article>
        )}
      </section>
    </>
  );
};

// Default Props
MovieNFests.defaultProps = {
  category: '',
  subCategory: '',
  type: 'now_showing',
  cards: '8',
};

export default withNamespaces()(MovieNFests);
