import React, { useEffect, useState, useRef, useContext } from 'react';
import QRCode from 'qrcode.react';
import { withNamespaces } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import poster2 from '@assets/pictures/poster-2.png';
import gogoLogo from '@assets/pictures/svgs/gogo-logo.svg';
import eyeGreen from '@assets/pictures/svgs/eye-green.svg';
import barCode from '@assets/pictures/svgs/bar-code.svg';
import greyBorder from '@assets/pictures/svgs/grey-border.svg';
import poster from '@assets/pictures/poster.png';
import logo from '@assets/pictures/svgs/private-final-ticket/logo.svg';
import logoBlack from '@assets/pictures/svgs/private-final-ticket/logo-black.svg';
import mail from '@assets/pictures/svgs/private-final-ticket/mail.svg';
import mobile from '@assets/pictures/svgs/private-final-ticket/mobile.svg';
import qr from '@assets/pictures/svgs/private-final-ticket/qr.svg';
import share from '@assets/pictures/svgs/private-final-ticket/share.svg';
import star from '@assets/pictures/svgs/private-final-ticket/star.svg';
import ticket from '@assets/pictures/svgs/private-final-ticket/ticket.svg';
import close from '@assets/pictures/svgs/close.svg';
import { ReactComponent as ArrowBack } from '@assets/pictures/svgs/arrow-back.svg';
import { ReactComponent as ArrowBackActive } from '@assets/pictures/svgs/arrow-back-active.svg';
import { ReactComponent as Sharegolden } from '@assets/pictures/svgs/sharegold.svg';
import { ReactComponent as Emailgolden } from '@assets/pictures/svgs/emailgold.svg';
import { ReactComponent as Messagegolden } from '@assets/pictures/svgs/messagegold.svg';
import { ReactComponent as TicketImg } from '@assets/pictures/svgs/newfinalticket.svg';
import { ReactComponent as TicketImggold } from '@assets/pictures/svgs/ticketimggold.svg';
import Diamond from '@assets/pictures/Diamond.png';
import useIsMobile from '../customHooks/useIsMobile';
import epixlogo from '@assets/pictures/epix-logo.png';

import {
  clearState,
  fetchBookingDetails,
  fetchBookingDetailsBrij,
  sendEmail,
  sendSms,
} from '../store/movies/movieActions';
import { seatTypesSelector, compactSeatTypes } from '../store/movies/movieSelector';
import ShareTicketPopup from './partials/popups/ShareTicketPopup.jsx';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { HOST_URL, BASEURL } from '../config/index';
import { CLEAR_VOUCHERS_DATA } from '../store/promotions/promotionsTypes';
import Spinner from './partials/Spinner';
import {
  Row,
  Container,
  Col,
  Dropdown,
  Modal,
  Alert,
  // Spinner,
} from 'react-bootstrap';
import { currencyFormatter } from '../helper/currencyFormatter';
// import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import dummyPosterStatic from '@assets/pictures/EPIX -  Icon 2.png';
import { getLangSpecificAttribute } from '@helper/languages';
import _ from 'lodash';
import seatTypesSelected from 'lodash';
import { movieLanguagesWithSubs } from '@helper/formatting';

const FinalTicket = ({ t }) => {
  const isMobile = useIsMobile();
  // useEffect(() => dispatch(clearState()), []);
  const [modalShow, setModalShow] = useState(false);
  const [actionsModal, setActionsModal] = useState(false);
  const [back, setBack] = useState(false);
  let getLanguageSpecificMovieDetails = useSelector(
    (state) => state.movies.getLanguageSpecificMovieDetails,
  );
  const movieDetails = useSelector((state) => state.movies.movieDetails);
  const dispatch = useDispatch();
  const history = useHistory();
  const showBookingID = useSelector((state) => state.movies.showBookingID);
  const { show_booking_id, category_id } = useParams();
  const bookingDetails = useSelector((state) => state.movies.bookingDetails);
  let private_rate_card_detail = bookingDetails?.private_rate_card_detail ?? [];

  const bookingDetailsLoading = useSelector(
    (state) => state.movies.bookingDetailsLoading,
  );
  const organization = useSelector((state) => state.movies.organizationDetails);
  const {
    org_logo_url,
    org_mycinema_logo,
    org_name,
    dummy_poster: dummyPosterDynamic,
  } = organization;

  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;

  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [sendSmsLoading, setSendSmsLoading] = useState(false);

  const currency = useSelector((state) => state.movies.currency);
  const [loading, setLoading] = useState({
    email: false,
    sms: false,
  });
  const { curr_code, curr_id } = currency;
  const [renderedBefore, setRenderedBefore] = useState(false);
  useEffect(() => {
    if (show_booking_id) {
      dispatch({ type: CLEAR_VOUCHERS_DATA });
      category_id == 1
        ? dispatch(fetchBookingDetails({ sb_id: show_booking_id }))
        : dispatch(fetchBookingDetailsBrij({ sb_id: show_booking_id }));
    } else {
      history.push(`/`);
    }
  }, [dispatch, history]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onClickEmailTicket = () => {
    setSendEmailLoading(true);
    dispatch(sendEmail({ sb_booking_code: show_booking_id })).finally(() => {
      setSendEmailLoading(false);
    });
  };

  const onClickSmsTicket = () => {
    setSendSmsLoading(true);
    dispatch(sendSms({ sb_booking_code: show_booking_id })).finally(() => {
      setSendSmsLoading(false);
    });
  };
  // useEffect(() => {
  //   const hasRenderedBefore = localStorage.getItem('hasRenderedBefore');

  //   if (!hasRenderedBefore) {
  //     // Perform actions on first load
  //     console.log('First load: Rendering useEffect API');

  //     // Set the flag in local storage to indicate the component has been rendered
  //     localStorage.setItem('hasRenderedBefore', true);
  //     setRenderedBefore(true);
  //     dispatch(sendSms({ sb_booking_code: show_booking_id }));
  //   } else {
  //     console.log('Not first load: useEffect API not rendered');
  //   }
  // }, []);
  const selected_language = useSelector((state) => state.movies.selected_language);

  const { lang_name, iso_2, lang_id } = selected_language;

  const seatTypes = useSelector((state) =>
    compactSeatTypes(state, 'final_ticket', iso_2),
  );

  const booking_type = useSelector((state) => state.movies.booking_type);

  const loginDetails = useSelector((state) => state.auth);
  const { loginError, loggedInUser, guestUser, userHistory } = loginDetails;

  function extractSeatTypes(selectedSeats) {
    let result = _.chain(selectedSeats)
      .groupBy('screen_seat_type_id')
      .toPairs()
      .map((x) => ({
        sst_seat_type: x[1][0].sst_seat_type,
        seats: x[1],
      }))
      .map((x) => {
        x.seats = _.chain(x.seats)
          .groupBy('rate_card_group_child_id')
          .toPairs()
          .map((x) => ({
            tt_name: x[1][0].tt_name,
            ticket_type_translations: x[1][0].ticketTypeTranslations,
            no_of_seats: x[1].length,
            single_seat_price: x[1][0].rc_total_amount,
            single_seat_net_price: x[1][0].rc_net_amount,
            total_price: x[1].reduce((acc, x) => acc + x.rc_total_amount, 0),
            seats: x[1],
          }))
          .value();
        return x;
      })
      .value();
    return result;
  }
  function percentage(percent, total) {
    return (percent / 100) * total;
  }

  if (bookingDetailsLoading)
    return (
      <div className="col-12">
        <div className="col-12 text-center">
          <Spinner />
        </div>
      </div>
    );

  function isGoldColor(bookingDetails, category_id) {
    let isPrivate = category_id == '1';

    if (!bookingDetails || !category_id) return false;

    if (bookingDetails?.booking_type_id === 2) return false;

    if (bookingDetails?.booking_type_id === 3) return true;

    if (bookingDetails?.booking_type_id === 1 && isPrivate) return true;

    return false;
  }
  function getInternetHandlingFees(bookingDetails, category_id) {
    if (bookingDetails?.booking_type_id == 3) return 0;

    return bookingDetails?.internet_handling_fee || 0;
  }
  function getFnbTotal(bookingDetails) {
    let total = 0;

    if (bookingDetails?.fnb_items && bookingDetails?.fnb_items?.length) {
      bookingDetails.fnb_items.forEach((f) => {
        total += f?.fp_total_amount_before_offer || 0;
      });
    }

    // return bookingDetails?.ft_total_amount || 0
    return total;
  }
  function getOfferTotal(bookingDetails) {
    let total = 0;

    total += bookingDetails?.offer_price || 0;

    if (bookingDetails?.fnb_items && bookingDetails?.fnb_items?.length) {
      bookingDetails.fnb_items.forEach((f) => {
        let t = f?.fp_offer_price * f?.fst_quantity || 0;

        if (
          f.ft_voucher_code == null &&
          f.ft_voucher_name == null &&
          f.ft_offer_name
        ) {
          t = f?.fp_offer_price || 0;
        }

        total += t;
      });
    }

    return total;
  }
  function getCashCardTotal(bookingDetails) {
    let total = 0;

    total += bookingDetails?.cash_card_amount || 0;

    if (bookingDetails?.fnb_items && bookingDetails?.fnb_items?.length) {
      total += bookingDetails?.fnb_items[0]?.cash_card_amount || 0;
    }

    return total;
  }
  function getEwalletTotal(bookingDetails) {
    let total = 0;

    total += bookingDetails?.e_wallet_amount || 0;

    if (bookingDetails?.fnb_items && bookingDetails?.fnb_items?.length) {
      total += bookingDetails?.fnb_items[0]?.e_wallet_amount || 0;
    }

    return total;
  }
  function getTotalWithoutDeductions(bookingDetails) {
    return bookingDetails?.booking_type_id !== 3
      ? parseFloat(
          bookingDetails?.sb_total_amount +
            getInternetHandlingFees(bookingDetails) +
            getFnbTotal(bookingDetails) +
            getRateCardAddonsTotal(bookingDetails),
        )
      : parseFloat(bookingDetails?.private_rate_card_detail?.[0]?.total);
  }
  function getRateCardAddonsTotal(bookingDetails) {
    if (!bookingDetails) return 0;

    return bookingDetails?.total_add_ons_charge || 0;
  }

  return (
    <section className="">
      <div className="header-space ticket-page">
        {/*container-fluid */}
        {false && !isMobile && (
          <section className="row for-prev-step mx-0">
            <button
              className="btn-main my-3 my-xl-4"
              onClick={() => history.go(-1)}
              onMouseOver={() => setBack(true)}
              onMouseOut={() => setBack(false)}
            >
              {/* <img
                src={back ? arrowBackActive : arrowBack}
                className="mr-3"
                alt=""
              /> */}
              {back ? <ArrowBackActive /> : <ArrowBack />}
              Back
            </button>
          </section>
        )}{' '}
        <>
          <div className="position-relative overflow-hidden main_wrapper_final">
            <div className="final-ticket">
              <div
                className={`ticket-header ${
                  isGoldColor(bookingDetails, category_id) ? 'back_gold_final' : ''
                }`}
              >
                <div>
                  <Link to={'/'}>
                    {/* <img
                      src={org_mycinema_logo || org_logo_url}
                      alt={org_name}
                      height={`${isMobile ? '40px' : '50px'}`}
                      width={`${isMobile ? '120px' : 'auto'}`}
                      // className="img-fluid"
                    /> */}
                    <img
                      src={epixlogo}
                      height={`${isMobile ? '40px' : '50px'}`}
                      width={`${isMobile ? '120px' : 'auto'}`}
                    />
                  </Link>
                </div>
                <div className="final_svg_top" style={{ alignItems: 'center' }}>
                  {isGoldColor(bookingDetails, category_id) ? (
                    <TicketImggold
                      className="svg_ticket"
                      style={{ marginRight: '9px' }}
                    />
                  ) : (
                    <TicketImg style={{ marginRight: '9px', marginTop: '2px' }} />
                  )}

                  <h6
                    className="mb-0"
                    style={{
                      color: isGoldColor(bookingDetails, category_id)
                        ? 'black'
                        : 'white',
                    }}
                  >
                    {isGoldColor(bookingDetails, category_id)
                      ? t('Private Cinema').toUpperCase()
                      : t('Your Ticket')}
                  </h6>
                </div>
              </div>
              {bookingDetailsLoading ? (
                <div className="row my-5">
                  <div className="col-12 text-center">
                    <Spinner />
                  </div>
                </div>
              ) : (
                <div className="ticket-content movie_info_wrapper payment_details_new">
                  <div className="row top">
                    <div
                      className={`col-md-9 
                      ${
                        !isMobile && isGoldColor(bookingDetails, category_id)
                          ? 'back_img_gold'
                          : ''
                      }`}
                    >
                      <section className="row align-items-center">
                        {/* col-4 col-md-3 */}
                        <div className="col-12 d-flex align-items-center">
                          <img
                            src={
                              getLangSpecificAttribute(
                                bookingDetails?.languageSpecificImages,
                                lang_id,
                                'artwork',
                              ) ||
                              movieDetails?.md_thumbnail_url ||
                              bookingDetails?.md_thumbnail_url ||
                              dummyPoster
                            }
                            alt="Movie Img"
                            className="movie-img img-fluid final_image"
                            onError={({ currentTarget }) => {
                              currentTarget.onerror = () => {}; // prevents looping
                              currentTarget.src = dummyPosterStatic;
                            }}
                          />
                          {/* </div>
                          <div className="col-8 col-md-9"> */}
                          <article className="mozilla_final_mob_alignmnet">
                            <p
                              className={`mb-1 final_heading ${
                                isGoldColor(bookingDetails, category_id)
                                  ? 'final_heading_gold'
                                  : ''
                              }`}
                            >
                              {isGoldColor(bookingDetails, category_id)
                                ? t('Private Cinema')
                                : ''}
                            </p>
                            <h1 className="movie_info_title mb-2">
                              {getLangSpecificAttribute(
                                bookingDetails?.languageSpecificImages,
                                lang_id,
                                'mc_title',
                              ) || bookingDetails?.movie_title}
                            </h1>
                            <div
                              className="movie_info_rating_n_format justify-content-start res_final_spacing"
                              style={{
                                marginBottom: !isMobile ? '24px' : undefined,
                              }}
                            >
                              <span className="movie_info_language space-right">
                                {movieLanguagesWithSubs(
                                  t(bookingDetails?.lang_name) ||
                                    bookingDetails?.lang_name,
                                  bookingDetails?.sub_1_iso_2,
                                  bookingDetails?.sub_2_iso_2,
                                )}
                              </span>
                              {
                                <>
                                  {bookingDetails?.duration && (
                                    <span className="newtime_final space-right">
                                      {bookingDetails?.duration} {t('common.mins')}
                                    </span>
                                  )}

                                  <span className="movie_info_rating space-right">
                                    {bookingDetails?.rating}
                                  </span>
                                  {bookingDetails?.mf_name && (
                                    <span className="movie_info_language space-right">
                                      {bookingDetails?.mf_name}
                                    </span>
                                  )}
                                </>
                              }
                            </div>

                            <div className=" text-left res_final_spacing">
                              <h6
                                className={`final_heading 
                                ${
                                  isGoldColor(bookingDetails, category_id)
                                    ? 'gold_final_text'
                                    : ''
                                }`}
                              >
                                {t('Date & Time')}
                              </h6>
                              <p className="final_para text-uppercase">
                                {moment(bookingDetails?.show_date).format(
                                  'DD MMM YYYY',
                                )}{' '}
                                |{' '}
                                {moment(
                                  bookingDetails?.show_time,
                                  'HH:mm:ss',
                                ).format('HH:mm')}
                              </p>
                            </div>
                            <div className=" text-left res_final_spacing">
                              <h6
                                className={`final_heading 
                                ${
                                  isGoldColor(bookingDetails, category_id)
                                    ? 'gold_final_text'
                                    : ''
                                }`}
                              >
                                {t('Auditorium')}
                              </h6>
                              <p className="final_para text-uppercase">
                                <div className="content">
                                  {bookingDetails?.screen_name}
                                </div>
                              </p>
                            </div>
                            <div className="text-left res_final_spacing ">
                              <h6
                                className={`final_heading 
                                  ${
                                    isGoldColor(bookingDetails, category_id)
                                      ? 'gold_final_text'
                                      : ''
                                  }`}
                              >
                                {t('Cinema')}
                                {/* Xanadu */}
                              </h6>

                              <p className="final_para">
                                {bookingDetails?.cine_name},{' '}
                                {bookingDetails?.cine_address}
                                {/* {bookingDetails?.cine_location} */}
                              </p>
                            </div>
                          </article>
                        </div>
                      </section>
                      {!isMobile && (
                        <div>
                          <p className="special_final">
                            {!!bookingDetails?.special_request &&
                              bookingDetails?.special_request}
                          </p>
                        </div>
                      )}
                    </div>

                    {!isMobile && (
                      <div
                        className={`col-md-3 mt-md-0 qr_sec ${
                          isMobile && isGoldColor(bookingDetails, category_id)
                            ? 'back_img_gold'
                            : ''
                        }`}
                      >
                        {/* <img
                      src={barCode}
                      alt="bar-code"
                      height="39px"
                      className="img-fluid"
                    /> */}
                        <div className="ticket_text">
                          <div
                            className={`final_heading final_heading_mob ${
                              isGoldColor(bookingDetails, category_id)
                                ? 'gold_final_text'
                                : ''
                            }`}
                          >
                            {t('Ticket ID')}:
                          </div>
                          <div className="final_para final_para_mob">
                            {bookingDetails?.sb_booking_code}
                          </div>
                        </div>
                        {false && <QRCode
                            value={`${BASEURL}/your-ticket/${bookingDetails?.sb_booking_code}`}
                            className="mx-auto ml-md-auto mr-md-0 qr-border qr_final"
                          />}
                        {bookingDetails?.sb_booking_code && (
                          <QRCode
                          level={'H'}
                            value={bookingDetails?.sb_booking_code}
                            className="mx-auto ml-md-auto mr-md-0 qr-border qr_final"
                          />
                        )}
                        <p className="qr_para">
                          {/* {t("Scan to get a ticket on your mobile")} */}
                          <span>{t('Use QR code for all tickets')}</span>
                        </p>

                        {isMobile && (
                          <div>
                            <p className="special_final_mob">
                              {!!bookingDetails?.special_request &&
                                bookingDetails?.special_request}
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  {/* Dotted Line <div className="grey-dashed-border py-4">
                      <img
                        src={greyBorder}
                        alt="border"
                        className="img-fluid"
                      />
                        </div>*/}
                  <div className="row bottom align-items-center changes_done">
                    <div
                      className={
                        isMobile
                          ? 'left grid_mob_space'
                          : 'col-md-12 col-lg-12 col-xl-12 left grid_mob_space'
                      }
                      style={!isMobile ? { left: '0px' } : { marginLeft: '0px' }}
                    >
                      <div
                        className={`grid-col-1-mob  respon_chg ${
                          isGoldColor(bookingDetails, category_id)
                            ? 'back_gold_final'
                            : ''
                        }`}
                      >
                        <div
                          className={`grid-col-1 mb-xl-4 mb-md-0 mob-gri ${
                            isGoldColor(bookingDetails, category_id)
                              ? 'back_gold_final'
                              : ''
                          }`}
                        >
                          {/*<div>
                            <div className={`heading
                            ${isGoldColor(bookingDetails, category_id) ? "" :"heading_mob"}
                            ${isGoldColor(bookingDetails, category_id) ? "heading_golden_final" : ""}`}>{t("Auditorium")}</div>
                            <div className={`content ${isGoldColor(bookingDetails, category_id) ? "golden_content_final" : ""}`}>
                              {bookingDetails?.screen_name}
                            </div>
                          </div>*/}
                          {bookingDetails?.booking_type_id !== 3 && (
                            <>
                              <div className="">
                                {/*ticket-type-col*/}
                                <div
                                  className={`heading textshadow
                              ${
                                isGoldColor(bookingDetails, category_id)
                                  ? ''
                                  : 'heading_mob'
                              }
                              ${
                                isGoldColor(bookingDetails, category_id)
                                  ? 'heading_golden_final'
                                  : ''
                              }`}
                                >
                                  {t('Ticket Type')}
                                </div>
                                {bookingDetails?.booking_type_id !== 3 && (
                                  <div>
                                    {seatTypes?.length > 0 &&
                                      seatTypes.map((seatType, seatTypeIndex) => (
                                        <div
                                          className={`content content_mob ${
                                            isGoldColor(bookingDetails, category_id)
                                              ? 'golden_content_final'
                                              : ''
                                          }`}
                                          key={seatTypeIndex}
                                        >
                                          <div
                                            className={
                                              isMobile ? 'd-flex flex-wrap' : ''
                                            }
                                          >
                                            {/* <span className="">
                                              {seatType.ticket_type}&nbsp;
                                              {!isMobile && '-'}&nbsp;
                                            </span> */}
                                            {seatType.seat_types.map(
                                              (item, index) => (
                                                <span
                                                  className={`${
                                                    isMobile ? 'd-block' : ''
                                                  }`}
                                                >
                                                  {item}
                                                  {seatType.seat_types.length -
                                                    1 !==
                                                    index && ', '}
                                                </span>
                                              ),
                                            )}
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                )}
                              </div>
                            </>
                          )}

                          <div>
                            <div
                              className={`heading textshadow
                            ${
                              isGoldColor(bookingDetails, category_id)
                                ? ''
                                : 'heading_mob'
                            }
                            ${
                              isGoldColor(bookingDetails, category_id)
                                ? 'heading_golden_final'
                                : ''
                            }`}
                            >
                              {t('Selected Seats')}:
                            </div>
                            <div
                              className={`comma-separated content ${
                                isGoldColor(bookingDetails, category_id)
                                  ? 'golden_content_final'
                                  : ''
                              }`}
                            >
                              {bookingDetails?.booking_type_id !== 3 && (
                                <div>
                                  {seatTypes?.length > 0 &&
                                    seatTypes.map((seatType, seatTypeIndex) => (
                                      <div
                                        className={`content content_mob ${
                                          isGoldColor(bookingDetails, category_id)
                                            ? 'golden_content_final'
                                            : ''
                                        }`}
                                        key={seatTypeIndex}
                                      >
                                        <div
                                          className={
                                            isMobile ? 'd-flex flex-wrap' : ''
                                          }
                                        >
                                          {seatType?.seatsName.map(
                                            (item, index) => (
                                              <span
                                                className={`${
                                                  isMobile &&
                                                  index + 1 ===
                                                    seatType?.seatsName?.length
                                                    ? 'd-block'
                                                    : ''
                                                }`}
                                              >
                                                {item}
                                              </span>
                                            ),
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}

                              {false &&
                                bookingDetails?.booking_type_id != 3 &&
                                bookingDetails?.seats?.map((seat, index) => (
                                  <>
                                    <span>{seat}</span>
                                  </>
                                ))}
                              {bookingDetails?.booking_type_id == 3 && (
                                <p>
                                  {
                                    bookingDetails?.private_rate_card_detail[0]
                                      ?.customSeatCount
                                  }{' '}
                                  {t('Seats')}
                                </p>
                              )}
                            </div>
                          </div>

                          {bookingDetails?.booking_type_id != 3 && (
                            <div>
                              <div
                                className={`heading textshadow
                              ${
                                isGoldColor(bookingDetails, category_id)
                                  ? ''
                                  : 'heading_mob'
                              }
                              ${
                                isGoldColor(bookingDetails, category_id)
                                  ? 'heading_golden_final'
                                  : ''
                              }`}
                              >
                                {t('No. of Seats')}
                              </div>
                              <div
                                className={`content ${
                                  isGoldColor(bookingDetails, category_id)
                                    ? 'golden_content_final'
                                    : ''
                                }`}
                              >
                                {bookingDetails?.seats.length}
                              </div>
                            </div>
                          )}

                          {/* <div>
                            <p>{t("Seat Type")}</p>
                            <p>Premium, Multiple</p>
                          </div> */}
                        </div>
                      </div>
                      <div>
                        {/*<div className="grid-col-5">
                            <div className="ticket-type-col">
                              <div className="text heading">
                                {t("Ticket Type")}
                              </div>
                              {bookingDetails?.booking_type_id !== 3 && (
                                <div>
                                  {seatTypes?.length > 0 &&
                                    seatTypes.map((seatType, seatTypeIndex) => (
                                      <div
                                        className="content"
                                        key={seatTypeIndex}
                                      >
                                        <div>
                                          <span className="">
                                            {seatType.ticket_type}&nbsp;
                                            {!isMobile && "-"}&nbsp;
                                          </span>
                                          {seatType.seat_types.map(
                                            (item, index) => (
                                              <span
                                                className={`${
                                                  isMobile ? "d-block" : ""
                                                }`}
                                              >
                                                {item}
                                                {seatType.seat_types.length -
                                                  1 !==
                                                  index && ", "}
                                              </span>
                                            )
                                          )}
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              )}
                            </div>
                            {bookingDetails?.fnb_items?.length > 0 && (
                              <div className="ticket-type-col">
                                <div className="heading">
                                  {t("Concessions")}
                                </div>

                                <div className="comma-separated content">
                                  {bookingDetails.fnb_items.map((item) => (
                                    <span>
                                      {item?.fst_quantity}&nbsp;
                                      {getLangSpecificAttribute(
                                        item?.FnbContent,
                                        lang_id,
                                        "item_lang_name"
                                      ) || item?.item_name}
                                    </span>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>*/}
                      </div>
                      <div className="ticket_bottom">
                        <div className="grid-col-2 mt-n2">
                          {/*{bookingDetails?.screening_name && (
                            <div>
                              <div className="heading">
                                {t("Screening Type")}:
                              </div>
                              <div className="content">
                                {" "}
                                {/* {booking_type === 1
                          ? t("Confirmed Screening")
                          : booking_type === 2
                          ? t("Crowdsourced Screening")
                          : booking_type === 3
                          ? t("Private Screening")
                          : t("Regular")}
                                {t(bookingDetails?.screening_name)}
                              </div>
                        </div>
                          )}*/}

                          <div
                            className={`booking_grid_align ${
                              isMobile ? '' : 'col-12'
                            }`}
                          >
                            <div className="heading heading_mob_down">
                              {t('BOOKING / FOOD & BEVERAGE SUMMARY')}
                            </div>
                            {/*<div className="booking_data_final">
                             <p>(x2 Seats) Standard Adult</p>
                             <p>CHF 30.00</p>
                             <p>(x2 Seats) Standard Adult</p>
                             <p>CHF 30.00</p>
                              <p>(x2 Seats) Standard Adult</p>
                             <p>CHF 30.00</p>
                            </div>*/}
                            {bookingDetails?.booking_type_id !== 3 && (
                              <>
                                <article
                                  className="payment_details_rows_final"
                                  style={{
                                    border: 'unset',
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                  }}
                                >
                                  {extractSeatTypes(
                                    bookingDetails?.selectedSeats || [],
                                  )?.length > 0 &&
                                    extractSeatTypes(
                                      bookingDetails?.selectedSeats || [],
                                    )?.map((seatType, seatTypeIndex) => (
                                      <>
                                        {seatType?.seats?.length > 0 &&
                                          seatType?.seats?.map(
                                            (rcg_child, index2) => (
                                              <section className="">
                                                <p className="left_value">
                                                  <span className="ml-0">
                                                    (x{rcg_child.no_of_seats}{' '}
                                                    {t('Seats')}){' '}
                                                    {/* {seatType.sst_seat_type} */}
                                                  </span>
                                                  {/* <span className="pl-1">

                                      </span>*/}
                                                  <span className="">
                                                    {rcg_child
                                                      ?.ticket_type_translations?.[
                                                      iso_2?.toLowerCase()
                                                    ] || rcg_child.tt_name}
                                                  </span>
                                                </p>

                                                <p
                                                  className="right_value"
                                                  style={{ textAlign: 'right' }}
                                                >
                                                  {curr_code}{' '}
                                                  {currencyFormatter(
                                                    rcg_child?.total_price,
                                                  )}
                                                </p>
                                              </section>
                                            ),
                                          )}
                                      </>
                                    ))}
                                </article>
                              </>
                            )}
                            {bookingDetails?.private_rate_card_detail &&
                              bookingDetails?.private_rate_card_detail.length >
                                0 && (
                                <article
                                  className="payment_details_rows"
                                  style={{
                                    border: 'unset',
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                  }}
                                >
                                  <section>
                                    <div className="left_value">
                                      {/*{<span className="ml-0">{bookingDetails?.private_rate_card_detail[0]?.customSeatCount} {t("Seats")}</span>}*/}
                                      {/*{<span className="ml-0">{t("entire-hall-booked")}</span>}*/}
                                      {
                                        <span className="ml-0">
                                          {
                                            bookingDetails
                                              ?.private_rate_card_detail[0]
                                              ?.customSeatCount
                                          }{' '}
                                          {t('Seats')}
                                        </span>
                                      }
                                      <span></span>
                                    </div>
                                    <div className="right_value">
                                      {curr_code}{' '}
                                      {currencyFormatter(
                                        (bookingDetails
                                          ?.private_rate_card_detail?.[0]
                                          ?.customSeatCount || 0) *
                                          (bookingDetails
                                            ?.private_rate_card_detail[0]?.slab?.[0]
                                            ?.price || 0),
                                      )}
                                    </div>
                                  </section>
                                </article>
                              )}

                            {/* Gross addons on rate card */}
                            {bookingDetails?.final_addon_gross_charges?.[0] &&
                              bookingDetails?.final_addon_gross_charges?.[0]
                                ?.length > 0 && (
                                <article
                                  className="payment_details_rows_final"
                                  style={{
                                    border: 'unset',
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                  }}
                                >
                                  <section className="">
                                    <p className="left_value">
                                      <span className="ml-0">{t('Addons')}</span>
                                      <span className=""></span>
                                    </p>
                                    <p
                                      className="right_value"
                                      style={{ textAlign: 'right' }}
                                    ></p>
                                  </section>

                                  {bookingDetails?.final_addon_gross_charges?.[0]?.map(
                                    (item, index) => (
                                      <>
                                        <section className="">
                                          <p className="left_value">
                                            <span className="ml-0">
                                              (x{item.quantity}){' '}
                                            </span>

                                            <span className="">
                                              {t(item.addons_name) ||
                                                item.addons_name}
                                            </span>
                                          </p>

                                          <p
                                            className="right_value"
                                            style={{ textAlign: 'right' }}
                                          >
                                            {curr_code}{' '}
                                            {currencyFormatter(item?.final_price)}
                                          </p>
                                        </section>
                                      </>
                                    ),
                                  )}

                                  <section className="mb-3">
                                    <p className="left_value">
                                      <span className="ml-0">
                                        {t('Addons')} {t('Total')}
                                      </span>
                                      <span className=""></span>
                                    </p>

                                    <p
                                      className="right_value"
                                      style={{ textAlign: 'right' }}
                                    >
                                      {curr_code}{' '}
                                      {currencyFormatter(
                                        getRateCardAddonsTotal(bookingDetails),
                                      )}
                                    </p>
                                  </section>
                                </article>
                              )}

                            {bookingDetails?.booking_type_id !== 3 &&
                              bookingDetails?.fnb_items &&
                              bookingDetails?.fnb_items.length > 0 && (
                                <article
                                  className="payment_details_rows"
                                  style={{
                                    border: 'unset',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                  }}
                                >
                                  {bookingDetails?.fnb_items.map((item) => (
                                    <section>
                                      <div className="left_value">
                                        {item?.fst_quantity && (
                                          <span className="ml-0">
                                            (x{item?.fst_quantity})
                                          </span>
                                        )}
                                        <span>
                                          {getLangSpecificAttribute(
                                            item?.FnbContent,
                                            lang_id,
                                            'item_lang_name',
                                          ) || item.item_name}
                                        </span>
                                      </div>

                                      <div className="right_value">
                                        {curr_code}{' '}
                                        {currencyFormatter(
                                          item.fp_total_amount_before_offer,
                                        )}
                                      </div>
                                    </section>
                                  ))}
                                </article>
                              )}

                            {bookingDetails?.private_rate_card_detail &&
                              bookingDetails?.private_rate_card_detail.length > 0 &&
                              bookingDetails?.private_rate_card_detail[0]?.fnb &&
                              bookingDetails?.private_rate_card_detail[0]?.fnb
                                .length > 0 && (
                                <article
                                  className="payment_details_rows"
                                  style={{
                                    border: 'unset',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                  }}
                                >
                                  {bookingDetails?.private_rate_card_detail[0]?.fnb.map(
                                    (item) => (
                                      <section>
                                        <div className="left_value">
                                          {bookingDetails
                                            ?.private_rate_card_detail[0]
                                            ?.customSeatCount && (
                                            <span className="ml-0">
                                              (x
                                              {
                                                bookingDetails
                                                  ?.private_rate_card_detail[0]
                                                  ?.customSeatCount
                                              }
                                              )
                                            </span>
                                          )}
                                          <span>{item.item_name}</span>
                                        </div>
                                        <div className="right_value">
                                          {curr_code}{' '}
                                          {currencyFormatter(
                                            item.price *
                                              bookingDetails
                                                ?.private_rate_card_detail[0]
                                                ?.customSeatCount,
                                          )}
                                        </div>
                                      </section>
                                    ),
                                  )}
                                </article>
                              )}
                            {bookingDetails?.private_rate_card_detail &&
                              bookingDetails?.private_rate_card_detail.length > 0 &&
                              bookingDetails?.private_rate_card_detail[0]?.addon &&
                              bookingDetails?.private_rate_card_detail[0]?.addon
                                .length > 0 && (
                                <article
                                  className="payment_details_rows"
                                  style={{
                                    border: 'unset',
                                    paddingTop: 0,
                                    paddingBottom: 0,
                                    marginBottom: '8px',
                                  }}
                                >
                                  {bookingDetails?.private_rate_card_detail[0]?.addon.map(
                                    (item) => (
                                      <section>
                                        <div className="left_value">
                                          {item?.quantity && (
                                            <span className="ml-0">
                                              (x{item?.quantity})
                                            </span>
                                          )}
                                          <span>{item.item_name}</span>
                                        </div>
                                        <div className="right_value">
                                          {curr_code}{' '}
                                          {currencyFormatter(
                                            item.price * item?.quantity,
                                          )}
                                        </div>
                                      </section>
                                    ),
                                  )}
                                </article>
                              )}

                            {getInternetHandlingFees(bookingDetails) > 0 && (
                              <article
                                className="payment_details_rows"
                                style={{
                                  border: 'unset',
                                  paddingTop: 0,
                                  paddingBottom: 0,
                                  marginBottom: '8px',
                                }}
                              >
                                <section>
                                  <div className="left_value">
                                    <span className="ml-0">
                                      {t('Conv.Charges')}
                                    </span>
                                    <span></span>
                                  </div>
                                  <div className="right_value">
                                    {curr_code}{' '}
                                    {currencyFormatter(
                                      getInternetHandlingFees(bookingDetails),
                                    )}
                                  </div>
                                </section>
                              </article>
                            )}

                            {((bookingDetails?.booking_type_id !== 3 &&
                              bookingDetails?.fnb_items &&
                              bookingDetails?.fnb_items.length > 0) ||
                              (bookingDetails?.private_rate_card_detail &&
                                bookingDetails?.private_rate_card_detail.length >
                                  0 &&
                                bookingDetails?.private_rate_card_detail[0]?.fnb &&
                                bookingDetails?.private_rate_card_detail[0]?.fnb
                                  .length > 0)) &&
                              !isMobile && (
                                <div>
                                  <p className="use_bar">
                                    {t('*Use the same Bar code to pick F&B Items.')}
                                  </p>
                                </div>
                              )}
                          </div>

                          {!isMobile && (
                            <div
                              className={`${
                                isMobile ? '' : 'spacing_payment_method '
                              }`}
                            >
                              <div className="heading heading_mob_down">
                                {t('Payment Method')}:
                              </div>

                              <div className="new_con_tick desktop_grid my-2">
                                {t('Total')}

                                <div className="">
                                  {curr_code}{' '}
                                  {currencyFormatter(
                                    getTotalWithoutDeductions(bookingDetails),
                                  )}
                                </div>
                              </div>

                              {/*offer on payment */}
                              {!!getOfferTotal(bookingDetails) && (
                                <div className="new_con_tick desktop_grid my-2 text-success">
                                  {`${
                                    bookingDetails?.pm_id == 10
                                      ? bookingDetails?.pm_payment_mode
                                      : `${bookingDetails?.voucher_code} ${bookingDetails?.voucher_code_fallback}`
                                          .toLowerCase()
                                          .includes('loyalty')
                                      ? `${
                                          bookingDetails?.voucher_code ||
                                          bookingDetails?.voucher_code_fallback
                                        }`
                                      : !!bookingDetails?.voucher_code ||
                                        !!bookingDetails?.voucher_code_fallback
                                      ? ` ${t('Voucher Code')} ${`(${
                                          bookingDetails?.voucher_code ||
                                          bookingDetails?.voucher_code_fallback
                                        })`}`
                                      : ` ${t('Discount')}`
                                  }`}

                                  <div className="">
                                    -{curr_code}{' '}
                                    {currencyFormatter(
                                      getOfferTotal(bookingDetails),
                                    )}
                                  </div>
                                </div>
                              )}

                              {/*cash card on payment */}
                              {!!getCashCardTotal(bookingDetails) && (
                                <div className="new_con_tick desktop_grid my-2">
                                  {`${t(
                                    'Cash Card',
                                  )} ${`(${bookingDetails?.cash_card_number})`}`}

                                  <div className="">
                                    {curr_code}{' '}
                                    {currencyFormatter(
                                      getCashCardTotal(bookingDetails) +
                                        (bookingDetails?.pm_id == 11
                                          ? getInternetHandlingFees(bookingDetails)
                                          : 0),
                                    )}
                                  </div>
                                </div>
                              )}
                              {/*ewallet on payment */}
                              {!!getEwalletTotal(bookingDetails) && (
                                <div className="new_con_tick desktop_grid my-2">
                                  {`${t('E-Wallet')}`}

                                  <div className="">
                                    {curr_code}{' '}
                                    {currencyFormatter(
                                      getEwalletTotal(bookingDetails) +
                                        getInternetHandlingFees(bookingDetails),
                                    )}
                                  </div>
                                </div>
                              )}

                              {![10, 11, 29].includes(bookingDetails?.pm_id) && (
                                <div className="new_con_tick desktop_grid my-2">
                                  {bookingDetails?.stripeDetails?.card
                                    ? 'Stripe'
                                    : bookingDetails?.pm_payment_mode
                                    ? bookingDetails?.pm_payment_mode
                                    : 'Debit Card'}

                                  <div className="">
                                    {curr_code}{' '}
                                    {currencyFormatter(
                                      bookingDetails?.booking_type_id !== 3
                                        ? parseFloat(
                                            bookingDetails?.sb_total_amount +
                                              getInternetHandlingFees(
                                                bookingDetails,
                                              ) +
                                              getFnbTotal(bookingDetails) -
                                              getOfferTotal(bookingDetails) +
                                              getRateCardAddonsTotal(
                                                bookingDetails,
                                              ),
                                          ) -
                                            getCashCardTotal(bookingDetails) -
                                            getEwalletTotal(bookingDetails)
                                        : parseFloat(
                                            private_rate_card_detail?.[0]?.total -
                                              getOfferTotal(bookingDetails),
                                          ) -
                                            getCashCardTotal(bookingDetails) -
                                            getEwalletTotal(bookingDetails),
                                    )}
                                  </div>
                                </div>
                              )}

                              <div
                                className="new_con_tick new_heading heading_mob_down desktop_grid"
                                id={'TotalAmountPaid'}
                              >
                                {t('Total Amount Paid')}
                                <div className="">
                                  {curr_code}{' '}
                                  {currencyFormatter(
                                    bookingDetails?.booking_type_id !== 3
                                      ? parseFloat(
                                          bookingDetails?.sb_total_amount +
                                            getInternetHandlingFees(
                                              bookingDetails,
                                            ) +
                                            getFnbTotal(bookingDetails) -
                                            getOfferTotal(bookingDetails) +
                                            getRateCardAddonsTotal(bookingDetails),
                                        )
                                      : parseFloat(
                                          private_rate_card_detail?.[0]?.total -
                                            getOfferTotal(bookingDetails),
                                        ),
                                  )}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {isMobile && (
                          <div
                            className={`${
                              isMobile ? '' : 'spacing_payment_method '
                            }`}
                            style={{ marginTop: '20px' }}
                          >
                            <div className="heading heading_mob_down">
                              {t('Payment Method')}:
                            </div>
                            <div className="new_con_tick desktop_grid my-2">
                              {t('Total')}

                              <div className="" style={{ textAlign: 'right' }}>
                                {curr_code}{' '}
                                {currencyFormatter(
                                  getTotalWithoutDeductions(bookingDetails),
                                )}
                              </div>
                            </div>
                            {/*offer on payment */}
                            {!!getOfferTotal(bookingDetails) && (
                              <div className="new_con_tick desktop_grid my-2 text-success">
                                {`${
                                  bookingDetails?.pm_id == 10
                                    ? bookingDetails?.pm_payment_mode
                                    : `${bookingDetails?.voucher_code} ${bookingDetails?.voucher_code_fallback}`
                                        .toLowerCase()
                                        .includes('loyalty')
                                    ? `${
                                        bookingDetails?.voucher_code ||
                                        bookingDetails?.voucher_code_fallback
                                      }`
                                    : !!bookingDetails?.voucher_code ||
                                      !!bookingDetails?.voucher_code_fallback
                                    ? ` ${t('Voucher Code')} ${`(${
                                        bookingDetails?.voucher_code ||
                                        bookingDetails?.voucher_code_fallback
                                      })`}`
                                    : ` ${t('Discount')}`
                                }`}

                                <div className="" style={{ textAlign: 'right' }}>
                                  -{curr_code}{' '}
                                  {currencyFormatter(getOfferTotal(bookingDetails))}
                                </div>
                              </div>
                            )}
                            {/*cash card on payment */}
                            {!!getCashCardTotal(bookingDetails) && (
                              <div className="new_con_tick desktop_grid my-2">
                                {`${t(
                                  'Cash Card',
                                )} ${`(${bookingDetails?.cash_card_number})`}`}
                                <div className="" style={{ textAlign: 'right' }}>
                                  {curr_code}{' '}
                                  {currencyFormatter(
                                    getCashCardTotal(bookingDetails) +
                                      (bookingDetails?.pm_id == 11
                                        ? getInternetHandlingFees(bookingDetails)
                                        : 0),
                                  )}
                                </div>
                              </div>
                            )}
                            {/*ewallet on payment */}
                            {!!getEwalletTotal(bookingDetails) && (
                              <div className="new_con_tick desktop_grid my-2">
                                {`${t('E-Wallet')}`}
                                <div className="" style={{ textAlign: 'right' }}>
                                  {curr_code}{' '}
                                  {currencyFormatter(
                                    getEwalletTotal(bookingDetails) +
                                      getInternetHandlingFees(bookingDetails),
                                  )}
                                </div>
                              </div>
                            )}

                            {![10, 11, 29].includes(bookingDetails?.pm_id) && (
                              <div className="new_con_tick desktop_grid my-2">
                                {bookingDetails?.stripeDetails?.card
                                  ? 'Stripe'
                                  : bookingDetails?.pm_payment_mode
                                  ? bookingDetails?.pm_payment_mode
                                  : 'Debit Card'}
                                <div className="" style={{ textAlign: 'right' }}>
                                  {curr_code}{' '}
                                  {currencyFormatter(
                                    bookingDetails?.booking_type_id !== 3
                                      ? parseFloat(
                                          bookingDetails?.sb_total_amount +
                                            getInternetHandlingFees(
                                              bookingDetails,
                                            ) +
                                            getFnbTotal(bookingDetails) -
                                            getOfferTotal(bookingDetails) +
                                            getRateCardAddonsTotal(bookingDetails),
                                        ) -
                                          getCashCardTotal(bookingDetails) -
                                          getEwalletTotal(bookingDetails)
                                      : parseFloat(
                                          private_rate_card_detail?.[0]?.total -
                                            getOfferTotal(bookingDetails),
                                        ) -
                                          getCashCardTotal(bookingDetails) -
                                          getEwalletTotal(bookingDetails),
                                  )}
                                </div>
                              </div>
                            )}
                            <div
                              className="new_con_tick new_heading heading_mob_down desktop_grid"
                              id={'TotalAmountPaid'}
                            >
                              {t('Total Amount Paid')}
                              <div className="" style={{ textAlign: 'right' }}>
                                {curr_code}{' '}
                                {currencyFormatter(
                                  bookingDetails?.booking_type_id !== 3
                                    ? parseFloat(
                                        bookingDetails?.sb_total_amount +
                                          getInternetHandlingFees(bookingDetails) +
                                          getFnbTotal(bookingDetails) -
                                          getOfferTotal(bookingDetails) +
                                          getRateCardAddonsTotal(bookingDetails),
                                      )
                                    : parseFloat(
                                        private_rate_card_detail?.[0]?.total -
                                          getOfferTotal(bookingDetails),
                                      ),
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="grid-col-2 d-none"></div>
                      </div>

                      {isMobile && (
                        <div
                          className={`col-md-3 mt-md-0 qr_sec ${
                            isMobile && isGoldColor(bookingDetails, category_id)
                              ? 'back_img_gold'
                              : ''
                          }`}
                        >
                          {/* <img
                      src={barCode}
                      alt="bar-code"
                      height="39px"
                      className="img-fluid"
                    /> */}
                          <div className="ticket_text">
                            <div
                              className={`final_heading final_heading_mob ${
                                isGoldColor(bookingDetails, category_id)
                                  ? 'gold_final_text'
                                  : ''
                              }`}
                            >
                              {t('Ticket ID')}:
                            </div>
                            <div className="final_para final_para_mob">
                              {bookingDetails?.sb_booking_code}
                            </div>
                          </div>
                          {false && <QRCode
                            value={`${BASEURL}/your-ticket/${bookingDetails?.sb_booking_code}`}
                            className="mx-auto ml-md-auto mr-md-0 qr-border qr_final"
                          />}
                          {bookingDetails?.sb_booking_code && (
                            <QRCode
                            level={'H'}
                            value={bookingDetails?.sb_booking_code}
                            className="mx-auto ml-md-auto mr-md-0 qr-border qr_final"
                          />
                          )}
                          <p className="qr_para">
                            {/* {t("Scan to get a ticket on your mobile")} */}
                            <span>{t('Use QR code for all tickets')}</span>
                          </p>

                          {isMobile && (
                            <div>
                              <p className="special_final_mob">
                                {!!bookingDetails?.special_request &&
                                  bookingDetails?.special_request}
                              </p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Desktop Final Ticket */}
            <div className="col-md-12 col-lg-3 col-xl-3 right tick_mob normal">
              {/* <div> */}
              <button
                className={`${
                  isGoldColor(bookingDetails, category_id)
                    ? 'ticket-btn-gold'
                    : 'ticket-btn share-btn'
                }`}
                onClick={() => setModalShow(true)}
              >
                {isGoldColor(bookingDetails, category_id) && (
                  <Sharegolden style={{ marginRight: '5px' }} />
                )}{' '}
                {t('Share Ticket')}
              </button>
              <button
                className={``}
                className={`
                          ${
                            isGoldColor(bookingDetails, category_id)
                              ? 'ticket-btn-gold'
                              : 'ticket-btn email-btn'
                          }
                          
                          ${sendEmailLoading ? 'loader-text' : ''}
                          `}
                onClick={onClickEmailTicket}
              >
                {isGoldColor(bookingDetails, category_id) && (
                  <Emailgolden style={{ marginRight: '5px' }} />
                )}{' '}
                {t('email-ticket')}
              </button>
              {/* </div>
                    <div> */}
              {!guestUser && (
                <button
                  className={`
                          ${
                            isGoldColor(bookingDetails, category_id)
                              ? 'ticket-btn-gold'
                              : 'ticket-btn mobile-btn'
                          }
                          ${sendSmsLoading ? 'loader-text' : ''} 
                                  `}
                  onClick={onClickSmsTicket}
                >
                  {isGoldColor(bookingDetails, category_id) && (
                    <Messagegolden style={{ marginRight: '5px' }} />
                  )}{' '}
                  {t('Send to mobile')}
                </button>
              )}
              {/* </div>
                    <div> */}

              <button
                className={`final_btn_main ${
                  isGoldColor(bookingDetails, category_id)
                    ? 'final_btn_main_golden'
                    : ''
                }`}
                onClick={() => {
                  dispatch(clearState());
                  history.replace(`/`);
                }}
              >
                {t('Back to home')}
              </button>
              {/* </div> */}
              {/* <div>
                    <button className="ticket-btn download-btn">
                      Download pdf
                    </button>
                  </div> */}
            </div>
          </div>
          {/* <div className="back-home mx-auto w-25 mt-5">
          <button
            className="payment-submit-btn"
            onClick={() => {
              dispatch(clearState());
              history.replace(`/`);
            }}
          >
            {t("Back to home")}
          </button>
        </div> */}
        </>
      </div>

      <ShareTicketPopup
        link={`${window.location.protocol}//${window.location.host}/final-ticket/${show_booking_id}/${category_id}`}
        show={modalShow}
        onHide={() => setModalShow(false)}
        sharedObject="Ticket"
        message={getShareMessage(bookingDetails, lang_id)}
      />

      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        className="share-ticket-popup"
        size="lg"
        centered
        onHide={() => setActionsModal(false)}
        show={actionsModal}
      >
        <section className="wrapper container-fluid">
          <img
            src={close}
            className="close-btn-main"
            alt=""
            onClick={() => setActionsModal()}
          />
          <div className="text-center title-row">
            <p className="title">{t('Get Ticket')}</p>
            <div className="golden-ticket-btn-group mt-4">
              <button
                className={`btn-main ${sendEmailLoading ? 'loader-text' : ''}`}
                onClick={onClickEmailTicket}
              >
                {/* <img src={mail} alt="" /> */}
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M29 5H3C2.44687 5 2 5.44687 2 6V26C2 26.5531 2.44687 27 3 27H29C29.5531 27 30 26.5531 30 26V6C30 5.44687 29.5531 5 29 5ZM27.75 8.4625V24.75H4.25V8.4625L3.3875 7.79063L4.61562 6.2125L5.95312 7.25313H26.05L27.3875 6.2125L28.6156 7.79063L27.75 8.4625ZM26.05 7.25L16 15.0625L5.95 7.25L4.6125 6.20937L3.38438 7.7875L4.24687 8.45938L14.9219 16.7594C15.2289 16.9979 15.6066 17.1273 15.9953 17.1273C16.3841 17.1273 16.7618 16.9979 17.0688 16.7594L27.75 8.4625L28.6125 7.79063L27.3844 6.2125L26.05 7.25Z"
                    fill="black"
                  />
                </svg>

                {t('email-ticket')}
              </button>
              {!guestUser && (
                <button
                  className={`btn-main ${sendSmsLoading ? 'loader-text' : ''}`}
                  onClick={onClickSmsTicket}
                >
                  {/* <img src={mobile} alt="" /> */}
                  <svg
                    width="14"
                    height="22"
                    viewBox="0 0 14 22"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.4375 0.453125H1.5625C0.735156 0.453125 0.0625 1.12578 0.0625 1.95312V19.9531C0.0625 20.7805 0.735156 21.4531 1.5625 21.4531H12.4375C13.2648 21.4531 13.9375 20.7805 13.9375 19.9531V1.95312C13.9375 1.12578 13.2648 0.453125 12.4375 0.453125ZM12.25 19.7656H1.75V2.14062H12.25V19.7656ZM6.0625 17.375C6.0625 17.6236 6.16127 17.8621 6.33709 18.0379C6.5129 18.2137 6.75136 18.3125 7 18.3125C7.24864 18.3125 7.4871 18.2137 7.66291 18.0379C7.83873 17.8621 7.9375 17.6236 7.9375 17.375C7.9375 17.1264 7.83873 16.8879 7.66291 16.7121C7.4871 16.5363 7.24864 16.4375 7 16.4375C6.75136 16.4375 6.5129 16.5363 6.33709 16.7121C6.16127 16.8879 6.0625 17.1264 6.0625 17.375Z"
                      fill="black"
                    />
                  </svg>

                  {t('Send to mobile')}
                </button>
              )}
              <button
                className="btn-main"
                onClick={() => {
                  setActionsModal(false);
                  setModalShow(true);
                }}
              >
                {/* <img src={share} alt="" /> */}
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M18.625 0.625H1.375C0.960156 0.625 0.625 0.960156 0.625 1.375V18.625C0.625 19.0398 0.960156 19.375 1.375 19.375H9.8125C9.91562 19.375 10 19.2906 10 19.1875V17.875C10 17.7719 9.91562 17.6875 9.8125 17.6875H2.3125V2.3125H17.6875V9.8125C17.6875 9.91562 17.7719 10 17.875 10H19.1875C19.2906 10 19.375 9.91562 19.375 9.8125V1.375C19.375 0.960156 19.0398 0.625 18.625 0.625ZM13.3117 12.0484L14.5352 10.825C14.5599 10.8001 14.5772 10.7687 14.5851 10.7345C14.5929 10.7002 14.591 10.6645 14.5795 10.6313C14.568 10.5981 14.5475 10.5687 14.5202 10.5466C14.4928 10.5245 14.4599 10.5105 14.425 10.5062L10.2203 10.0141C10.1008 10 9.99766 10.1008 10.0117 10.2227L10.5039 14.4273C10.5227 14.582 10.7125 14.6477 10.8227 14.5375L12.0508 13.3094L18.0555 19.3141C18.1281 19.3867 18.2477 19.3867 18.3203 19.3141L19.3141 18.3203C19.3867 18.2477 19.3867 18.1281 19.3141 18.0555L13.3117 12.0484Z"
                    fill="black"
                  />
                </svg>

                {t('Share Ticket')}
              </button>
            </div>
          </div>
        </section>
      </Modal>
    </section>
  );
};

function getShareMessage(bookingDetails, lang_id) {
  return `Here is a ticket for ${
    getLangSpecificAttribute(
      bookingDetails?.languageSpecificImages,
      lang_id,
      'mc_title',
    ) || bookingDetails?.movie_title
  } - ${bookingDetails?.lang_name} ${bookingDetails?.mf_name} at ${
    bookingDetails?.cine_name
  }, on ${moment(bookingDetails?.show_date).format('ll')} at ${moment(
    bookingDetails?.show_time,
    'HH:mm:ss',
  ).format('HH:mm')}`;
}

export default withNamespaces()(FinalTicket);
