import React, { useEffect, useState, useLayoutEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { useParams } from 'react-router';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import useIsMobile from '@src/customHooks/useIsMobile';
import RatingPopup from '@components/partials/popups/RatingPopup';
import { useHistory, useLocation } from 'react-router-dom';
//Assets
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';

// Components
import MovieInfo from './helpers/MovieInfoSideBar';
import { Accordion, Card } from 'react-bootstrap';
import StepOne from './helpers/StepOne';
import StepTwo from './helpers/StepTwo';
import StepThree from './helpers/StepThree';
import GoBack from '@components/partials/GoBack';
import Button from '@components/partials/Button';
import { BASEURL } from '@config/index';
//Actions
import {
  fetchMovieDetails,
  // fetchMovieSchedules,
  resetScreeningTypes,
  clearNewOndemandState,
  clearMovieSchedules,
  clearMovieDetails,
  setOndemandScreeningType,
  setOndemandShow,
  fetchConfirmedList,
  fetchOtherScreenings,
  checkCreateYourShowEnabled,
} from '@store/movies/movieActions';
import {
  clearSeatSelection,
  releaseSeatBrij,
} from '@store/seatLayout/seatLayoutActions';
import {
  clearFnbState,
  fetchFnbItems,
  skipFnbItems,
} from '@store/foodAndBeverages/foodAndBeveragesActions';
import { sortSeatsString } from '@store/auth/authActions';
import { getLangSpecificAttribute } from '@helper/languages';
import { getLabelToshow, getMovieVersionsText } from '@helper/formatting';
import _, { groupBy } from 'lodash';
import { getSeatIconUrl } from '@src/helper';

//helpers

const NowShowingBookingFlowContainer = ({ t }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { movie_id, content_lang_id, selectedParamLang, md_id, uid } = useParams();
  // console.log({ movie_id, content_lang_id, selectedParamLang, md_id, uid })
  const [ratingPopup, setratingPopup] = useState(false);
  const [activeAccordionTab, setActiveAccordionTab] = useState([
    true,
    false,
    false,
    false,
  ]);
  const [activeAccordionTabIndex, setActiveAccordionTabIndex] = useState('0');
  const [selectedCinema, setSelectedCinema] = useState(null);
  const [unavailableDateLoader, setUnavailableDateLoader] = useState(true);

  const category_id = 2;
  const movieDetails = useSelector((state) => state.movies.movieDetails);

  const movieDetailsLoading = useSelector(
    (state) => state.movies.movieDetailsLoading,
  );
  // const movieFormats = useSelector((state) => state.movies.movieFormats)
  // const movieLanguages = useSelector((state) => state.movies.movieLanguages)
  const selectedCinemaFromLocal = useSelector(
    (state) => state.movies.selectedCinema,
  );
  const selectedCity = useSelector((state) => state.movies.selectedCity);

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError, loginError } = loginDetails;
  let userSelection = useSelector((state) => state.movies.userSelection);

  let seats = useSelector((state) => state.seatLayout.seats_selected);
  const [seatString, setSeatString] = useState(
    seats?.map((seat) => seat?.sl_seat_name),
  );
  const [seatStringWithLogo, setSeatStringWithLogo] = useState([]);

  useEffect(() => {
    let str = [];
    let seatsWithLogo = [];
    Object.values(groupBy(seats, 'srt_id')).forEach((type) => {
      if (type && type.length) {
        let seats = [];

        type.forEach((s) => {
          seats.push(s?.sl_seat_name);
        });

        seats.sort(sortSeatsString);
        seats = seats.join(',');
        seatsWithLogo.push({
          seats: seats,
          icon: getSeatIconUrl(type[0]?.srt_online_image_url),
        });

        seats = `${type[0]?.srt_name}: ${seats}`;

        str.push(seats);
      }
    });

    setSeatString(str.join(' | '));
    setSeatStringWithLogo(seatsWithLogo);
  }, [seats]);

  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_id, lang_name, iso_2 } = selected_language;

  const handleAccordionTab = (index) => {
    let newActiveAccordionTab = [false, false, false, false];
    if (activeAccordionTab[index]) {
      setActiveAccordionTabIndex(null);
      newActiveAccordionTab[index] = false;
    } else {
      setActiveAccordionTabIndex(String(index));
      newActiveAccordionTab[index] = true;
    }
    setActiveAccordionTab(newActiveAccordionTab);
  };

  // call fetchMovieSchedule again to reset reservations made for current user
  const resetReservation = () => {
    if (userSelection?.selectedDate) {
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!movieDetails || movieDetailsLoading) {
      // const spinner = document.getElementById("main-spinner");
      // if (spinner) {
      //   spinner.style.display = "flex";
      //   setTimeout(() => setCalendarPopup(false), 10);
      //   setTimeout(() => {
      //     spinner.style.display = "none";
      //   }, 2000);
      // }
    }
  }, [movieDetails, movieDetailsLoading]);

  const reservation_id = useSelector((state) => state.seatLayout.reservation_id);
  useEffect(() => {
    if (reservation_id) {
      dispatch(releaseSeatBrij({ reservation_id }));
    }
  }, []);

  const [skipFnbItem, setSkipFnbItem] = useState(true);
  useLayoutEffect(() => {
    dispatch(
      skipFnbItems({
        cinema_id: selectedCinema?.cinema_id
          ? selectedCinema?.cinema_id
          : selectedCinemaFromLocal?.cinema_id
          ? selectedCinemaFromLocal?.cinema_id
          : null,
        reservation_id,
        city_id: selectedCity?.city_id,
      }),
    ).then((skipFnbItem) => {
      setSkipFnbItem(skipFnbItem);
    });
  }, [reservation_id, selectedCinema, selectedCinemaFromLocal]);

  useEffect(() => {
    setUnavailableDateLoader(true); // set false in call 2 of fetch sch
    dispatch(clearMovieSchedules())
      .then(() => {
        dispatch(clearMovieDetails()); // not async
        dispatch(clearNewOndemandState()); // not async
        return dispatch(clearSeatSelection());
      })
      .then(() => {
        return dispatch(clearFnbState());
      })
      .then(() => {
        return dispatch(
          fetchMovieDetails({
            movie_id,
            content_lang_id,
            cinema_id: selectedCinema?.cinema_id
              ? selectedCinema?.cinema_id
              : selectedCinemaFromLocal?.cinema_id
              ? selectedCinemaFromLocal?.cinema_id
              : null,
            cust_id: loggedInUser?.cust_id || null,
          }),
        );
      })
      .then(() => {
        return dispatch(
          fetchConfirmedList({
            movie_id,
            call: 1,
            cinema_id: selectedCinemaFromLocal?.cinema_id,
            city_id: selectedCity?.city_id,
            mdIdSelected: md_id,
            callback: () => {},
            setDate: () => {},
            setDateValue: () => {},
            isNowShowing: true,
            onChangeCinema: () => {},
            setSelectedLanguage: () => {},
            setSelectedFormat: () => {},
            onChangeLanguage: () => {},
            onChangeFormat: () => {},
            setUnavailableDateLoader,
          }),
        );
      });
    // dispatch(fetchOtherScreenings({ genre_id, movie_id }));

    return () => {
      dispatch(clearMovieSchedules());
      dispatch(clearMovieDetails()); // not async
      dispatch(clearNewOndemandState()); // not async
    };
  }, [content_lang_id, movie_id, selectedParamLang, md_id, uid]);

  useEffect(() => {
    setUnavailableDateLoader(true);
    if (userSelection?.selectedDate) {
      dispatch(
        fetchConfirmedList({
          movie_id,
          category_id,
          isNowShowing: true,
          dateSchedule: moment(new Date(userSelection?.selectedDate)).format(
            'YYYY-MM-DD',
          ),
          cinema_id: selectedCinemaFromLocal?.cinema_id,
          city_id: selectedCity?.city_id,
          // lang_id: userSelection?.selectedLang?.lang_id ?? undefined,
          mf_id: null,
          call: 2,
          mdIdSelected: md_id,
          callback: () => {},
        }),
      ).finally(() => {
        setUnavailableDateLoader(false);
      });
    } else
      setTimeout(() => {
        setUnavailableDateLoader && setUnavailableDateLoader(false);
      }, 1000 * 2);

    return () => {};
  }, [
    userSelection.selectedDate,
    // userSelection.selectedLang
  ]);

  useEffect(() => {
    if (selectedCinemaFromLocal?.cinema_id && movie_id) {
      dispatch(
        checkCreateYourShowEnabled({
          date: moment(userSelection?.selectedDate ?? moment()).format(
            'YYYY-MM-DD',
          ),
          cinema_id: selectedCinemaFromLocal?.cinema_id,
          city_id: selectedCity?.city_id,
          movie_id,
        }),
      );
    }
  }, [selectedCinemaFromLocal, userSelection.selectedDate, movie_id]);

  let [isAccordionTabIndexEverChanged, setActiveAccordionTabIndexEverChanged] =
    useState(false);
  useEffect(() => {
    if (isAccordionTabIndexEverChanged === false && !!isMobile) {
      setActiveAccordionTabIndexEverChanged(true);
      handleAccordionTab(1);
    }
  }, [isMobile]);

  const [movieInfoObj, setMovieInfoObj] = useState({});
  const [showPopupRating, setShowPopupRating] = useState(false);
  useEffect(() => {
    setMovieInfoObj({
      image: getLangSpecificAttribute(
        movieDetails?.languageSpecificImages,
        lang_id,
        'artwork',
      ),
      movie_title:
        getLangSpecificAttribute(
          movieDetails?.altMovieContent,
          lang_id,
          'mc_title',
          undefined,
          undefined,
          undefined,
          true,
        ) ||
        movieDetails?.original_title_of_movie ||
        movieDetails?.movie_title,
      lang: movieDetails?.lang_name,
      format: undefined, //selectedFormat?.mf_name,
      genre:
        movieDetails?.genres_with_translations
          ?.map((g) => g[iso_2?.toLowerCase()])
          .filter((x) => x)
          .splice(0, 13)
          .join(', ') || movieDetails?.genre,
      pp_banner: movieDetails?.pp_banner,
      pp_title: movieDetails?.pp_title,
      pp_description: movieDetails?.pp_description,
      rating: movieDetails?.rating,
      rating_id: movieDetails?.rating_id,
      trailers: movieDetails?.trailer_array ?? movieInfoObj?.trailers ?? [],
      duration: movieDetails?.mrrdr_runtime ?? '',
      year:
        (
          movieDetails?.mrrdr_release_date ||
          movieDetails?.original_mrrdr_release_date ||
          ''
        )?.split('-')?.[0] ?? '',
      plot:
        getLangSpecificAttribute(
          movieDetails?.altMovieContent,
          lang_id,
          'mc_plot',
        ) ?? movieDetails?.mc_plot,
      castCrewDetails:
        movieDetails?.castCrewDetails ?? movieInfoObj?.castCrewDetails ?? [],
      languages:
        movieDetails?.languages_with_translations
          ?.map((l) => l[iso_2?.toLowerCase()])
          .filter((x) => x)
          .filter((item, pos, arr) => arr.indexOf(item) == pos) ??
        movieInfoObj?.languages ??
        [],
      availLanguagesForOndemand:
        movieDetails?.movie_versions
          ?.filter((x) => x)
          .filter((item, pos, arr) => {
            if (!item?.cinema_details) return false;
            if (!item?.md_licence_end_date) return false;
            if (
              item?.is_confirmed_show === 'Y' ||
              item?.is_private_show === 'Y' ||
              item?.is_crowd_show === 'Y'
            )
              return true;
          }) ??
        movieInfoObj?.availLanguagesForOndemand ??
        [],
      versions:
        getMovieVersionsText(
          movieDetails?.movie_versions,
          selectedCinemaFromLocal?.region_name,
        ).map((item, i) => item) ??
        movieInfoObj?.versions ??
        [],
      original_lang: movieDetails?.lang_name ?? movieInfoObj?.original_lang ?? '',
      label_to_show: movieDetails?.movie_labels_with_translations?.length
        ? getLabelToshow(movieDetails?.movie_labels_with_translations[0])
        : movieInfoObj?.label_to_show ?? [],
    });
  }, [iso_2, lang_id, movieDetails]);

  useLayoutEffect(() => {
    if (movieDetails?.rating_id) {
      if (movieDetails?.rating_id != 4 && movieDetails?.pp_title) {
        setShowPopupRating(true);
      }
    }
  }, [movieDetails?.rating_id]);

  const handleCloseRatingPopup = () => {
    setShowPopupRating(false);
  };
  const handleCloseRatingHome = () => {
    history.push('/home');
  };
  let rating_description = movieDetails?.pp_description?.replaceAll('&lt;', '<');

  const custom_image_base_url = movieDetails?.artwork?.split('/');

  const image_base_url =
    custom_image_base_url?.[0] + '//' + custom_image_base_url?.[2] + '/';

  return (
    <>
      <div className="booking_flow_container">
        <Modal
          show={showPopupRating ?? false}
          aria-labelledby="contained-modal-title-vcenter"
          className="show-details-popup"
          centered
          backdrop="static"
          keyboard={false}
          onHide={handleCloseRatingPopup}
        >
          <section className="wrapper container-fluid rating_popup_wrapper">
            {/* <img
          src={close}
          className="close-btn-main"
          onClick={() => closeFunc(false)}
          alt=""
        /> */}
            {/* {cities?.length === 0 && (
          <div className="row">
            <div className="col-12 text-center">
              <Spinner />
            </div>
          </div>
        )} */}
            <div className={`row align-items-center ${isMobile ? 'mt-2' : 'mt-4'}`}>
              <div className="col-3">
                <img
                  src={
                    movieDetails?.pp_banner?.includes('S3')
                      ? image_base_url + movieDetails?.pp_banner
                      : BASEURL + movieDetails?.pp_banner
                  }
                  className="rating_img"
                />
              </div>
              <div
                className="col-9 text-center-mobile"
                style={{ lineHeight: '23px' }}
              >
                {movieDetails?.pp_title ? movieDetails?.pp_title : ``}
              </div>
              <div
                className="col-12 mt-2 rating_descripton"
                dangerouslySetInnerHTML={{ __html: rating_description }}
              />
            </div>
            <div
              className="d-flex align-items-center justify-content-center mt-3"
              style={{ gap: '20px' }}
            >
              <button
                className="cancel_btn"
                onClick={() => handleCloseRatingHome()}
              >
                Cancel
              </button>

              <button
                className="confirm_design_btn"
                onClick={() => handleCloseRatingPopup()}
              >
                Confirm
              </button>
            </div>
          </section>
        </Modal>

        {isMobile ? (
          <>
            <Accordion
              activeKey={activeAccordionTabIndex}
              className="booking_accordion"
            >
              {/* STEP ONE ~ CHECK MOVIE DETAILS */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="0"
                  onClick={() => {
                    handleAccordionTab(0);
                    resetReservation();
                    dispatch(setOndemandScreeningType());
                    dispatch(resetScreeningTypes());
                    dispatch(setOndemandShow());
                    dispatch(clearSeatSelection());
                    dispatch(setOndemandShow());
                  }}
                  className={`${activeAccordionTab[0] ? 'active' : ''}`}
                >
                  <article>
                    {activeAccordionTab[0] ? (
                      <h2>{t('Movie Details')}</h2>
                    ) : (
                      <>
                        {!!movieInfoObj.movie_title && (
                          <p> {`${movieInfoObj.movie_title}`}</p>
                        )}
                        {!!userSelection?.selectedMovieVersion?.version_full_name &&
                          !!movieInfoObj.lang && (
                            <p>{`${userSelection?.selectedMovieVersion?.version_full_name}`}</p>
                          )}
                      </>
                    )}
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <article className="booking_accordion_step">
                    <MovieInfo
                      isPrivate={false}
                      onClickNext={() => handleAccordionTab(1)}
                    />
                  </article>
                </Accordion.Collapse>
              </Card>

              {/* STEP TWO ~ SELECT DATE, LANGUAGE & TIME SLOTS */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="1"
                  onClick={() => {
                    handleAccordionTab(1);
                    if (!activeAccordionTab[1]) resetReservation();
                    dispatch(setOndemandScreeningType());
                    dispatch(resetScreeningTypes());
                    dispatch(setOndemandShow());
                    dispatch(clearSeatSelection());
                    dispatch(setOndemandShow());
                  }}
                  className={`${activeAccordionTab[1] ? 'active' : ''}`}
                >
                  <article>
                    {+activeAccordionTabIndex <= 1 &&
                    activeAccordionTabIndex != null ? (
                      <>
                        <h2>
                          <span>1.</span> {t('Select Date, Language & Time Slots')}
                        </h2>
                      </>
                    ) : (
                      <>
                        {userSelection?.selectedDate && (
                          <p>
                            {moment(new Date(userSelection.selectedDate)).format(
                              'MMM DD, YYYY',
                            )}{' '}
                          </p>
                        )}
                        {!!userSelection?.selectedSession && (
                          <p>{t(userSelection?.selectedSession.label)} </p>
                        )}
                        {!!userSelection?.selectedShow && (
                          // <p>{userSelection?.selectedShow.showTime}</p>
                          <p>
                            {moment(
                              userSelection?.selectedShow.date_final_output,
                            ).format('hh:mm A')}
                          </p>
                        )}
                        {!!userSelection?.selectedLang && (
                          <button style={{ color: 'white' }}>
                            {!!t(userSelection?.selectedLang?.lang_name)
                              ? t(userSelection?.selectedLang?.lang_name)
                              : userSelection?.selectedLang.lang_name}
                          </button>
                        )}
                      </>
                    )}
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <article className="booking_accordion_step">
                    <StepOne
                      isPrivate={false}
                      onClickNext={() => handleAccordionTab(2)}
                      unavailableDateLoader={unavailableDateLoader}
                    />
                  </article>
                </Accordion.Collapse>
              </Card>

              {/* STEP THREE ~ SELECT SEATS */}
              <Card>
                <Accordion.Toggle
                  as={Card.Header}
                  eventKey="2"
                  onClick={() => {
                    if (
                      // (activeAccordionTab[0] || activeAccordionTab[1]) &&
                      !userSelection.selectedScreeningType ||
                      userSelection?.selectedScreeningType?.booking_type_id == 3
                    )
                      return;

                    if (activeAccordionTab[2]) return;
                    handleAccordionTab(2);
                  }}
                  className={`${activeAccordionTab[2] ? 'active' : ''}`}
                  style={{
                    minHeight: activeAccordionTab[2] ? '32px' : undefined,
                  }}
                >
                  <article
                    style={{
                      minHeight: activeAccordionTab[2] ? '32px' : undefined,
                    }}
                  >
                    {seats.length === 0 ? (
                      <>
                        <h2>
                          <span>2.</span> {t('Pick Seats')}
                        </h2>
                      </>
                    ) : (
                      <>
                        {seats.length > 0 && seatString && (
                          <h6 className="text-success">
                            {/*{seatString.sort(sortSeatsString).map((seat, index, arr) => (
                              <React.Fragment key={index}>
                                {seat} {index !== arr.length - 1 ? ',' : ''}
                              </React.Fragment>
                            ))}*/}
                            {/*{seatString}{' '}{seats.length > 0 && false && `(${seats?.length})`}*/}
                            {seatStringWithLogo?.map((s, index, arr) => (
                              <React.Fragment key={index}>
                                <img
                                  loading="lazy"
                                  width="24px"
                                  height="24px"
                                  src={s.icon}
                                />
                                {'  '}: {s.seats}
                                {index !== arr.length - 1 ? ', ' : ' '}
                              </React.Fragment>
                            ))}
                          </h6>
                        )}
                        {/* <span>(Edit)</span> */}
                      </>
                    )}
                  </article>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <article className="booking_accordion_step">
                    {/* Condition to give screen and icons proper width */}
                    {activeAccordionTabIndex === '2' && (
                      <StepThree isPrivate={false} />
                    )}
                  </article>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          </>
        ) : (
          <section className="row">
            <article className="col-md-4 col-xl-3 pl-md-0 col-lg-4" id="movie_info">
              <MovieInfo isPrivate={false} />
            </article>

            <article className="col-md-8 col-xl-9 col-lg-8 pr-md-0 bg_custom_buildhaus">
              <section className="inner_pages_header">
                <GoBack
                  isPrivate={false}
                  onClick={() => {
                    if (+activeAccordionTabIndex === 0) history.go(-1);
                    else if (+activeAccordionTabIndex > 0) {
                      dispatch(resetScreeningTypes());
                      dispatch(setOndemandScreeningType());
                      resetReservation();
                      handleAccordionTab(+activeAccordionTabIndex - 1);
                    }
                  }}
                  className="mx-2"
                />
                <div className="custom_breadcrumbs">
                  <div className="steps_new">
                    <span className="bor_new">{t('Now Showing')}</span>
                    {!skipFnbItem && (
                      <span className="px-3 bor_new1">{t('Food & Beverages')}</span>
                    )}
                    <span className={` bor_new1 ${skipFnbItem && 'px-3'} `}>
                      {t('Checkout')}
                    </span>
                  </div>

                  {/*<span onClick={()=>history.push("/now-showing")}>
                  Now Showing</span> <span>
              Now Showing Booking</span> */}
                </div>

                <div />
              </section>

              <Accordion
                activeKey={activeAccordionTabIndex}
                className="booking_accordion"
              >
                {/* STEP ONE ~ SELECT DATE, LANGUAGE & TIME SLOTS */}
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={activeAccordionTab[0]}
                    onClick={() => {
                      handleAccordionTab(0);
                      if (!activeAccordionTab[0]) resetReservation();
                      dispatch(setOndemandScreeningType());
                      dispatch(resetScreeningTypes());
                      dispatch(clearSeatSelection());
                      dispatch(setOndemandShow());
                    }}
                    className={`${activeAccordionTab[0] ? 'active' : ''}`}
                  >
                    <article>
                      <h2>
                        <span>1.</span> {t('Select Date, Language & Time Slots')}
                      </h2>
                      {(+activeAccordionTabIndex !== 0 ||
                        activeAccordionTabIndex == null) && (
                        <>
                          {userSelection?.selectedDate && (
                            <p>
                              {moment(new Date(userSelection.selectedDate)).format(
                                'MMM DD, YYYY',
                              )}{' '}
                            </p>
                          )}
                          {!!userSelection?.selectedSession && (
                            <p>{t(userSelection?.selectedSession.label)} </p>
                          )}
                          {!!userSelection?.selectedShow && (
                            // <p>{userSelection?.selectedShow.showTime}</p>
                            <p>
                              {moment(
                                userSelection?.selectedShow.date_final_output,
                              ).format('hh:mm A')}
                            </p>
                          )}
                          {!!userSelection?.selectedLang && (
                            <button>
                              {!!t(userSelection?.selectedLang?.lang_name)
                                ? t(userSelection?.selectedLang?.lang_name)
                                : userSelection?.selectedLang.lang_name}
                            </button>
                          )}
                          {!!userSelection?.selectedShow && (
                            <span>({t('Edit')})</span>
                          )}
                        </>
                      )}
                    </article>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="0">
                    <article className="booking_accordion_step">
                      <StepOne
                        isPrivate={false}
                        onClickNext={() => handleAccordionTab(1)}
                        unavailableDateLoader={unavailableDateLoader}
                      />
                    </article>
                  </Accordion.Collapse>
                </Card>

                {/* STEP TWO ~ SELECT SEATS */}
                <Card>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey="1"
                    onClick={() => {
                      if (
                        // (activeAccordionTab[0] || activeAccordionTab[1]) &&
                        !userSelection.selectedScreeningType ||
                        userSelection?.selectedScreeningType?.booking_type_id == 3
                      )
                        return;

                      if (activeAccordionTab[1]) return;

                      handleAccordionTab(1);
                    }}
                    className={`${activeAccordionTab[1] ? 'active' : ''}`}
                    style={{
                      minHeight: activeAccordionTab[1] ? '32px' : undefined,
                    }}
                  >
                    <article
                      style={{
                        minHeight: activeAccordionTab[1] ? '32px' : undefined,
                      }}
                    >
                      <h2>
                        <span>2.</span>{' '}
                        {seats.length > 0 ? t('Selected Seats') : t('Pick Seats')}
                      </h2>

                      {seats.length > 0 && seatString && (
                        <h6 className="text-success">
                          {/*{seatString?.sort(sortSeatsString).map((seat, index, arr) => (
                            <React.Fragment key={index}>
                              {seat} {index !== arr.length - 1 ? ',' : ''}
                            </React.Fragment>
                          ))}*/}
                          {/*{seatString}{' '}{seats.length > 0 && false && `(${seats?.length})`}*/}
                          {seatStringWithLogo?.map((s, index, arr) => (
                            <React.Fragment key={index}>
                              <img
                                loading="lazy"
                                width="24px"
                                height="24px"
                                src={s.icon}
                              />
                              {'  '}:{s.seats}
                              {index !== arr.length - 1 ? ', ' : ' '}
                            </React.Fragment>
                          ))}
                        </h6>
                      )}

                      <h2 className="m-0">
                        {movieInfoObj.movie_title && (
                          <span className="px-2">|</span>
                        )}
                        {movieInfoObj.movie_title}
                        {userSelection?.selectedMovieVersion?.version_full_name
                          ? '-'
                          : ''}{' '}
                        {userSelection?.selectedMovieVersion?.version_full_name}
                      </h2>
                      {/*{seats.length > 0 && (<span>({t('Edit')})</span>)}*/}
                    </article>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <article className="booking_accordion_step">
                      {/* Condition to give screen and icons proper width */}
                      {activeAccordionTabIndex === '1' && (
                        <StepThree isPrivate={false} />
                      )}
                    </article>
                  </Accordion.Collapse>
                </Card>
              </Accordion>
            </article>
          </section>
        )}
      </div>
    </>
  );
};

export default withNamespaces()(NowShowingBookingFlowContainer);
