// import React from 'react';
import { withNamespaces } from 'react-i18next';

import { Row, Container, Col, Dropdown, Modal, Alert } from 'react-bootstrap';
// import YouTubeImage from '@assets/pictures/youtube_image.png';
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
// import useIsMobile from '../../customHooks/useIsMobile';
import { useHistory, useLocation } from 'react-router-dom';
import useIsMobile from '@src/customHooks/useIsMobile';
import moment from 'moment';
import { getLangSpecificAttribute } from '@helper/languages';
import { useSelector, useDispatch } from 'react-redux';

// Components

import Slider from 'react-slick';
import seatsImg from '@assets/pictures/dolby_main.jpeg';
// import seatsImg1 from '@assets/pictures/untitled-22.jpg';
import seatsImg1 from '@assets/pictures/platinum suites2.jpeg';
import seatsImg2 from '@assets/pictures/untitled-41.jpg';

// Components
import CenteredTitle from '@components/partials/CenteredTitle';
import BreadCrumb from '@components/partials/BreadCrumbs';
import MovieService from '@apiService/MovieService';
import TrailerIcon from '@assets/pictures/svgs/play_new_exp.svg';
import ModalVideo from 'react-modal-video';
// import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import dummyPosterStatic from '@assets/pictures/EPIX -  Icon 2.png';
import eicon from '@assets/pictures/svgs/eicon.png';

const Experience = ({ t }) => {
  const { t1, banners, isLoading, showOnly = null } = t;
  const isMobile = useIsMobile();
  const history = useHistory();
  const dispatch = useDispatch();
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { iso_2, lang_id, lang_name } = selected_language;
  const { pathname } = useLocation();
  const isAdvance = useSelector((state) => state.movies.isAdvance);
  const [isOpen, setOpen] = useState(false);
  const [currentVideoId, setCurrentVideoId] = useState(null);
  const [videoChannel, setVideoChannel] = useState(null);
  const [show, setShow] = useState(false);
  const [ExpPagesData, setExpPagesData] = useState([]);
  const ExpPagesDataFunc = () => async (dispatch) => {
    try {
      const response = await MovieService.GetAllMovieExperience();
      const { data } = response;
      if (data?.status) {
        setExpPagesData(data.Records);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    dispatch(ExpPagesDataFunc());
  }, []);

  let show_experirnce = ['KINDER', 'XL', 'XL BALCONY', 'VIP'];
  let filteredExpPagesData = ExpPagesData?.data?.filter((elem) => {
    return show_experirnce.includes(elem?.movie_experience);
  });
  // let expTranslatedData = [];
  // ExpPagesData?.data?.map((x) => {
  //   // x["movie_experience_description"] = JSON.parse(x?.translation_data)
  //   return expTranslatedData.push(JSON.parse(x?.translation_data));
  // });
  // expTranslatedData = expTranslatedData.flat();

  // ExpPagesData?.data?.forEach((records) => {
  //   records['movie_experience_description'] = expTranslatedData.map(
  //     (x) => x?.movie_experience_description,
  //   );
  // });
  // console.log(expTranslatedData, 'expTranslatedData');

  // console.log(filteredExpPagesData, 'filteredExpPagesData');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [swiping, setSwiping] = useState(false);
  const expSlider = {
    pauseOnFocus: true,
    onSwipe: () => setSwiping(true),
    arrows: true,
    dots: true,
    infinite: true,
    useTransform: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const getBackgroundColor = (experienceName) => {
    switch (experienceName.toLowerCase()) {
      case 'vip':
        return '#fa2d3f';
      case 'xl':
        return '#ffb230';
      case 'kinder':
        return '#52c248';
      case 'standard':
        return '#434A9F';
      case 'xl balcony':
        return '#ffb230';
      case '4dx':
        return '#3FB0D2';
      case 'dbox':
        return '#6F6E75';
      default:
        return '#434A9F';
    }
  };

  const getColorForLetter = (letter, word) => {
    const colors = {
      k: '#f6ed1c',
      i: '#fdb713',
      n: '#eb1946',
      d: '#eb4e99',
      e: '#414A9f',
      r: '#1c5026',
    };

    return word.toLowerCase() === 'kinder'
      ? colors[letter.toLowerCase()] || 'inherit'
      : 'inherit';
  };

  return (
    <>
      <div className="align_new">
        <div className="left_right_align">
          {/* <BreadCrumb
            firstHalf={`${t('More')} >`}
            // secondHalf={`${t('Experience')}`}
            secondHalf={`${('Experience')}`}
          /> */}
          {/* <CenteredTitle
               firstHalf={`${t('Privacy Policy')}`}
            firstHalf={`${'Experience'}`}
            secondHalf={''}
          /> */}

          <div className="mb-4" style={{ fontSize: '25px' }}>
            <span>
              <img src={eicon} height={40} width={38} className="mr-2" />
              Experience
            </span>
          </div>
        </div>

        <section className="container-fluid opening_hours">
          <Slider
            {...expSlider}
            className="home_banner experience_slider"
            style={{ marginBottom: '0px' }}
          >
            {filteredExpPagesData?.map((item, index) => (
              <Col xl={12} sm={12} md={12} key={index}>
                <div className="d-block parent_poster_wrapper">
                  <div
                    className={`${'movie_card_new custom_border_radius'} `}
                    // onMouseEnter={() => setIsShown(true)}
                    // onMouseLeave={() => setIsShown(false)}
                  >
                    {/* <figure className="experience_img">
                      <img
                        src={
                          item?.movie_experience_image_banner_url
                            ? item?.movie_experience_image_banner_url
                            : dummyPosterStatic
                        }
                        className="img-fluid "
                        alt={t('alt.Movie Poster')}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = () => {}; // prevents looping
                          currentTarget.src = dummyPosterStatic;
                        }}
                      />
                    </figure> */}
                    <figure className="experience_img pt-3">
                      {item?.movie_experience_image_banner_url ? (
                        <img
                          src={item?.movie_experience_image_banner_url}
                          className="img-fluid"
                          alt={t('alt.Movie Poster')}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = () => {};
                            currentTarget.src = dummyPosterStatic;
                          }}
                        />
                      ) : (
                        <div
                          className="desktop-filter-btn1"
                          style={{
                            backgroundColor: getBackgroundColor(
                              item?.movie_experience,
                            ),
                            color: 'white',
                            // margin: 'auto',
                            marginLeft: '15px',
                          }}
                        >
                          {/* <p
                            className="question_title12"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {item?.movie_experience
                              .split(' ')
                              .map((word, index) => (
                                <span
                                  key={index}
                                  style={{
                                    fontSize: index === 0 ? '20px' : '12px',
                                    // marginLeft: index > 0 ? '-0.3em' : '0',
                                    // marginTop: index > 0 ? '-4px' : '0',
                                    display: 'inline-block',
                                    textAlign: 'center',
                                  }}
                                >
                                  {word}
                                </span>
                              ))}
                          </p> */}
                          <p
                            className="question_title12"
                            style={{ display: 'flex', alignItems: 'center' }}
                          >
                            {item?.movie_experience
                              .split(' ')
                              .map((word, wordIndex) => (
                                <span key={wordIndex}>
                                  {word.split('').map((letter, index) => (
                                    <span
                                      key={index}
                                      style={{
                                        color: getColorForLetter(letter, word),
                                        fontSize: wordIndex === 0 ? '22px' : '12px',
                                        marginBottom: '-5px',
                                      }}
                                    >
                                      {letter}
                                    </span>
                                  ))}
                                  &nbsp;
                                </span>
                              ))}
                          </p>
                        </div>
                      )}
                    </figure>

                    <div className="experince_poster_details custon_z_index">
                      <div
                        style={{
                          padding: isMobile ? '5px' : '20px 0px',
                          textAlign: 'left',
                        }}
                        dangerouslySetInnerHTML={{
                          __html: item?.movie_experience_description
                            ? item?.movie_experience_description.replaceAll(
                                '&lt;',
                                '<',
                              )
                            : '',
                        }}
                        className="offer_description_wrapper p-0 all_exp"
                      />

                      <div className="mt-2 text-center d-flex justify-content-center align-items-center exp_btn_wrapper">
                        {/* explore_wrapper */}
                        {/* <p className="question_title">{item?.movie_experience}</p> */}

                        {/* <div
                          className="desktop-filter-btn1"
                          style={{
                            backgroundColor: getBackgroundColor(
                              item?.movie_experience,
                            ),
                            color: 'white',
                          }}
                        >
                          <p className="question_title">
                            {item?.movie_experience
                              .split(' ')
                              .map((word, index) => (
                                <span
                                  key={index}
                                  style={{ fontSize: index === 0 ? '15px' : '8px' }}
                                >
                                  {word}
                                </span>
                              ))}
                          </p>
                        </div> */}

                        <div>
                          <button
                            className="explore_btn"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              history.push(
                                `/experience-detail/${item?.movie_experience}/${item?.movie_experience_id}`,
                              );
                            }}
                          >
                            Explore
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Slider>
        </section>
      </div>
    </>
  );
};
export default withNamespaces()(Experience);
