import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
  useLayoutEffect,
} from 'react';
import { withNamespaces } from 'react-i18next';
import OtpInput from 'react-otp-input';
import { Card, Accordion, Button, Row, Col, Nav, Tab } from 'react-bootstrap';
import moment from 'moment';
import poster2 from '@assets/pictures/banner.png';
import benefitLogo from '@assets/pictures/benefit_pg_logo.png';
import masterCardLogo from '@assets/pictures/mastercard_pg_logo.png';
import masterCardStandardCharteredLogo from '@assets/pictures/scLogo.png';
import downArrow from '@assets/pictures/epixnewlogo/chevron.svg';
import arrowImage from '@assets/pictures/arrow.png';
import backArrow from '@assets/pictures/svgs/back-white-arrow.svg';
import greyArrowDown from '@assets/pictures/svgs/grey-arrow-down.svg';
import { ReactComponent as ArrowBack } from '@assets/pictures/svgs/arrow-back.svg';
import circles from '@assets/pictures/svgs/circles.svg';
import { ReactComponent as ArrowBackActive } from '@assets/pictures/svgs/arrow-back-active.svg';
import { setReservationID } from '../store/seatLayout/seatLayoutActions';
import {
  cinemaBookSeats,
  bookSeats,
  fetchScheduleDetails,
  onSuccessStripeToken,
  fetchStripePublisherKey,
  fetchReservationDetails,
  fetchReservationDetailsBrij,
  onSuccessStripeTokenBrij,
  makeSaferPayPayment,
  fetchActivePaymentModes,
  makeOfferPaymentBooking,
  makeFreeTicketBooking,
  makeFreeRateCardPaymentBooking,
  unpaidBooking,
  makeFullCashCardpayment,
  makeKhaltiPayment,
  makeHimalayanBankPayment,
  makeEsevaPayment,
  makeSecureAcceptancePayment,
  makeCCAvenuePayment,
  makeMasterCardPayment,
  validateBankOfferAction,
} from '../store/movies/movieActions';
import { seatTypesSelector } from '../store/movies/movieSelector';
import {
  clearSelectedVoucher,
  validateVoucherCode,
  validateLoyalty,
  validateEwallet,
  verifyPromotion,
  resetVoucherAndOfferInReservation,
} from '../store/promotions/promotionsActions';
import {
  login as loginAction,
  register,
  guestRegister,
  loginViaOtp,
  numberRegex,
  forgotPassword,
  startTimer,
  resetTimer,
  fetchUserHistory,
} from '../store/auth/authActions';
import LoginPopup from './partials/popups/LoginPopup';
import LoginOtp from './partials/LoginOtp';
import ForgotPasswordOtp from './partials/ForgotPasswordOtp';
import greenCheckCircle from '@assets/pictures/svgs/green-check-circle.svg';
import useIsMobile from '../customHooks/useIsMobile';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import CountryCodeDropdown from './partials/CountryCodeDropdown';
import { currencyFormatter } from '../helper/currencyFormatter';
import dummyPosterStatic from '@assets/pictures/dummyPoster.png';
import { getLangSpecificAttribute } from '@helper/languages';
import _ from 'lodash';
import showPasswordIcon from '@assets/pictures/svgs/show-password.svg';
import hidePasswordIcon from '@assets/pictures/svgs/hide-password.svg';
import paymentSuccessIcon from '@assets/pictures/svgs/payment_success.svg';
import location from '@assets/pictures/svgs/location.svg';
import locationnew from '@assets/pictures/svgs/newlocation.svg';
import video from '@assets/pictures/video.png';
import DayPicker from 'react-day-picker';
import MomentLocaleUtils from 'react-day-picker/moment';
import GoBack from '@components/partials/GoBack';
import { ReactComponent as LeftArrow } from '@assets/pictures/svgs/slider-arrow-left.svg';
import Spinner from './partials/Spinner';
import { fetchCashCardDetailsFromCardNumberPin } from '../store/cashCard/cashCardActions';
import { movieLanguagesWithSubs } from '@helper/formatting';

import qrCodeIcon from '@assets/pictures/svgs/camera.svg';
import { CashCardScanner } from '@components/GiftCards/helper/CashCardScanner';
import useIsTablet from '@src/customHooks/useIsTablet';
import { skipFnbItems } from '@store/foodAndBeverages/foodAndBeveragesActions';
import TicketCut from '../assets/pictures/mobiledividercard.png';
import useIsDesktop from '@src/customHooks/useIsDesktop.js';
import { Rotate } from 'react-reveal';

function TestMasterCard() {
  // window.Checkout.showPaymentPage()
}

function getInternetHandlingFees(
  category_id,
  is_internet_handling_enable,
  scheduleDetails,
  reservationDetails,
) {
  if (!is_internet_handling_enable) return 0;

  if (category_id == 2 && is_internet_handling_enable)
    return scheduleDetails?.internet_handling_fee ?? 0;
  else {
    if (reservationDetails?.selectedSeats?.length > 0)
      return (
        reservationDetails?.selectedSeats?.length *
        reservationDetails?.selectedSeats?.[0]?.internet_handling_fee
      );
    else return 0;
  }
}

function getRateCardAddonsTotal(add_ons_array = []) {
  if (!add_ons_array) return 0;
  if (add_ons_array?.length === 0) return 0;

  return add_ons_array?.[0]?.BoxOfficeFinal?.TotalAddons ?? 0;
}

const PaymentFlowApp = ({ t }) => {
  const isMobile = useIsMobile();
  const [loginIconBool, setLoginIconBool] = useState(false);
  const [login, setLogin] = useState(false);
  const showBookingID = useSelector((state) => state.movies.showBookingID);
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const [open, setOpen] = useState([1, 0, 0, 0, 0, 0, 0, 0]);
  const [otp, setOtp] = useState([]);
  const history = useHistory();
  const {
    category_id,
    movie_id,
    content_lang_id,
    genre_id,
    isPrivate,
    reservation_id,
  } = useParams();
  const isTablet = useIsTablet();
  const dispatch = useDispatch();

  let globalOptions = useSelector((state) => state.movies.globalOptions);
  const [GUEST_CHECKOUT, setGUEST_CHECKOUT] = useState(
    (globalOptions.find((g) => g.go_key === `GUEST_CHECKOUT`)?.go_value || 'N') ===
      'Y',
  );

  useEffect(() => {
    setGUEST_CHECKOUT(
      (globalOptions.find((g) => g.go_key === `GUEST_CHECKOUT`)?.go_value ||
        'N') === 'Y',
    );
    // console.log({
    //   GUEST_CHECKOUT_FLAG: globalOptions.find((g) => g.go_key === `GUEST_CHECKOUT`)
    //     ?.go_value,
    //   GUEST_CHECKOUT,
    // });
  }, [globalOptions]);

  const getStepWithOffset = (key, incrementBy1 = false) => {
    let k = parseInt(key);

    if (incrementBy1) k = k + 1;
    if (typeof key === 'string') k = String(k);

    return k;
  };

  const toggleHandle = (key) => {
    key = parseInt(key);

    if (GUEST_CHECKOUT) key = key + 1;

    setDefaultTab(`${key ? key : 0}`);
    setOpen((oldState) => {
      let openCopy = Array(8).fill(0);
      openCopy[key] = !oldState[key];
      return openCopy;
    });
  };
  const [showLoginPassword, setShowLoginPassword] = useState(false);
  const [showSignupPassword, setShowSignupPassword] = useState(false);

  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError, loginError, guestUser } =
    loginDetails;
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);

  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;

  const [registerLoading, setRegisterLoading] = useState(false);
  const [type, setType] = useState(null);
  const [registerConditionsCheckError, setRegisterConditionsCheckError] =
    useState(false);
  const handleChangeOtp = (value) => {
    setOtp(value);
  };
  useEffect(() => {
    category_id == 1
      ? dispatch(fetchReservationDetails({ reservation_id, category_id }))
      : dispatch(fetchReservationDetailsBrij({ reservation_id, category_id }));
    window.scrollTo(0, 0);
    dispatch(fetchStripePublisherKey());
    dispatch(fetchActivePaymentModes(2));
    dispatch(setReservationID(reservation_id));
    // setTimeout(() => {
    //   window.Checkout.configure({
    //     session: {
    //       id: 'SESSION0002084100631G6932452N06'
    //     }
    //   });
    // }, 1000)
  }, []);

  const booking_type = useSelector((state) => state.movies.booking_type);
  const reservationDetails = useSelector(
    (state) => state.movies.reservationDetails,
  );
  const add_ons_array = useSelector(
    (state) => state?.movies?.reservationDetails?.add_ons_array,
  );

  const minutes = useSelector((state) => state.movies.timer.minutes);
  const seconds = useSelector((state) => state.movies.timer.seconds);
  const timerCompleted = useSelector((state) => state.movies.timerCompleted);
  const selectedFnbItems = useSelector((state) => state.fnb.selected_fnb_items);
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);
  const seatTypes = useSelector((state) => seatTypesSelector(state));
  const activePaymentModes = useSelector(
    (state) => state.movies.activePaymentModes,
  );
  const is_internet_handling_enable = useSelector(
    (state) => state?.seatLayout?.is_internet_handling_enable,
  );

  useEffect(() => {
    // console.log('seatTypes in payment page: ===========>>', seatTypes);
  }, [scheduleDetails]);

  useEffect(() => {
    if (timerCompleted === true) {
      // toast.dismiss();
      // toast.error("Your session has expired");
      // history.replace(
      //   `/create-show-details/${scheduleDetails?.movie_id}/${scheduleDetails?.content_lang_id}`
      // );
      dispatch({
        type: `SET_SHOW`,
        payload: {
          movie_id: scheduleDetails?.movie_id,
          content_lang_id: scheduleDetails?.content_lang_id,
          category_id,
        },
      });
      dispatch({
        type: `SET_TIMEOUT_ERROR`,
        payload: { message: `Your session has expired`, show: true },
      });
    }
  }, [timerCompleted]);
  const [countryId, setCountryId] = useState(null);
  const [countryMobCode, setCountryMobCode] = useState(null);
  const [confirmDetails, setConfirmDetails] = useState(false);
  const [countryMobCodeGuest, setCountryMobCodeGuest] = useState(null);

  const onConfirmPayment = () => {
    // console.log('category_id :>> ', category_id);
    // category_id == 1
    //   ? dispatch(
    //       cinemaBookSeats({
    //         reservation_id,
    //         cinema_id: scheduleDetails.cinema_id,
    //         customer_id: loggedInUser?.cust_id,
    //         email,
    //       })
    //     )
    //   : dispatch(
    //       bookSeats({bookSeats
    //         reservation_id,
    //         cinema_id: scheduleDetails.cinema_id,
    //         customer_id: loggedInUser?.cust_id,
    //         email,
    //       })
    //     );
    dispatch(
      cinemaBookSeats({
        reservation_id,
        cinema_id: scheduleDetails.cinema_id,
        customer_id: loggedInUser?.cust_id,
        email,
      }),
    );

    if (isMobile) {
      toggleHandle(3);
    } else {
      toggleHandle(2);
    }
  };

  const [onClickLoginConfirmDetailsLoader, setonClickLoginConfirmDetailsLoader] =
    useState(false);
  const onClickLoginConfirmDetails = (
    e,
    guestUser = undefined,
    isFreeRateCardSkipPaymentStep = false,
  ) => {
    setonClickLoginConfirmDetailsLoader(true);
    if (e) {
      e.preventDefault();
    }

    // if (!loggedInConditionsCheck) {
    //   setLoginConditionsCheckError(true);
    //   return;
    // }
    setLoginSubmitted(true);
    if (!guestUser && (mobile === '' || email === '')) {
      setonClickLoginConfirmDetailsLoader(false);
      return;
    } else if (guestUser && email === '') {
      setonClickLoginConfirmDetailsLoader(false);
      return;
    }
    setIsConfirmed(true);

    if (guestUser) {
      // console.log({
      //   guest_country_code_og: guestUser.guest_country_code,
      //   guest_country_code: countryMobCodeGuest,
      // });
      let payload = {
        guest_email: guestUser.guest_email,
        guest_first_name: !!guestFirstName
          ? guestFirstName
          : guestUser.guest_first_name,
        guest_last_name: !!guestLastName
          ? guestLastName
          : guestUser.guest_last_name,
        guest_mobile: !!mobile ? mobile : guestUser.guest_mobile,
        guest_country_code: !!countryMobCodeGuest
          ? countryMobCodeGuest
          : guestUser.guest_country_code,
        callback: () => {},
      };
      dispatch(guestRegister(payload)).catch(console.error);
    }

    if (isFreeRateCardSkipPaymentStep) {
      dispatch(
        makeFreeRateCardPaymentBooking({
          reservation_id,
          cust_id: loggedInUser?.cust_id,
          email,
          cinema_id: scheduleDetails.cinema_id,
          category_id,
          callback: () => {
            setonClickLoginConfirmDetailsLoader(false);
          },
          secondCallback: toggleHandle,
          cinema_name: scheduleDetails.cine_name,
          mobile,
          countryMobCode,
          payment_mode_id: 19, // payment_mode_id = 19 = Free rate card
          booking_type_id,
        }),
      ).then(() => {
        let openNew = open;
        openNew[0] = false;
        setOpen(openNew);
      });
      return;
    }

    let openNew = open;
    openNew[0] = false;
    setOpen(openNew);
    setTimeout(() => {
      setConfirmDetails(true);
      isMobile ? toggleHandle(2) : toggleHandle(1);
    }, 100);
    setTimeout(() => {
      setonClickLoginConfirmDetailsLoader &&
        setonClickLoginConfirmDetailsLoader(false);
    }, 200);
  };
  const onClickRegisterConfirmDetails = (
    e,
    isFreeRateCardSkipPaymentStep = false,
  ) => {
    e.preventDefault();
    setRegisterConditionsCheckError(false);
    if (!registerConditionsCheck) {
      setSubmitted(true);
      setRegisterConditionsCheckError(true);
      return;
    }

    // console.log(memberBool === 'guestCheckout', {
    //   memberBool,
    // });

    if (memberBool === 'guestCheckout') {
      setSubmitted(true);
      if (emailNotMatched) return;
      if (!registerEmail) return;
      if (!lastName) return;
      if (!firstName) return;
      if (!registerMobile) return;

      setRegisterLoading(true);
      let payload = {
        guest_email: registerEmail,
        guest_first_name: firstName,
        guest_last_name: lastName,
        guest_mobile: registerMobile,
        guest_country_code: countryMobCode,
        callback: setRegisterLoading,
      };

      dispatch(guestRegister(payload)).then((r) => {
        if (isFreeRateCardSkipPaymentStep) {
          dispatch(
            makeFreeRateCardPaymentBooking({
              reservation_id,
              cust_id: loggedInUser?.cust_id,
              email,
              cinema_id: scheduleDetails.cinema_id,
              category_id,
              callback: () => {
                setonClickLoginConfirmDetailsLoader(false);
              },
              secondCallback: toggleHandle,
              cinema_name: scheduleDetails.cine_name,
              mobile,
              countryMobCode,
              payment_mode_id: 19, // payment_mode_id = 19 = Free rate card
              booking_type_id,
            }),
          ).then(() => {});
          return;
        } else
          setTimeout(() => {
            toggleHandle(isMobile ? 2 : 1);
          }, 10);
      });
      return;
    }

    if (
      registerEmail === '' ||
      registerMobile === '' ||
      firstName === '' ||
      lastName === '' ||
      password === '' ||
      !registerConditionsCheck
      //  || !selectedDate
    ) {
      return;
    }
    setRegisterLoading(true);
    let payload = {
      email: registerEmail,
      first_name: firstName,
      last_name: lastName,
      password,
      phone_no: registerMobile,
      country_id: countryId,
      callback: setRegisterLoading,
    };
    if (selectedDate) payload.dob = moment(selectedDate).format('YYYY-MM-DD');

    dispatch(register(payload));
  };

  const onErrorHandling = () => {
    if (loginError === 'Password is incorrect') {
      onClickForgotPassword();
    } else {
      setMemberBool('register');
      setLoginSubmitted(false);
    }
  };

  const onSubmitLogin = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setType('email');
    setLoginSubmitted(true);
    let count = 0;

    if (
      (!isNumber && loginField === '') ||
      (isNumber && (loginField === '' || !countryId))
    ) {
      count++;
    }

    if (loginPassword === '') {
      count++;
    }

    if (count > 0) {
      setLoading(false);
      return;
    }

    dispatch(
      loginAction({
        loginField,
        password: loginPassword,
        callback: setLoading,
        countryId,
      }),
    );
  };

  const show = useSelector((state) => state.movies.show);
  // const reservation_id = useSelector((state) => state.seatLayout.reservation_id);

  const bookingSuccessful = useSelector((state) => state.movies.bookingSuccessful);
  const [email, setEmail] = useState(``);
  const [mobile, setMobile] = useState(``);
  const [loggedInConditionsCheck, setLoggedInConditionsCheck] = useState(true);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const emailInput = React.useRef(null);
  const mobileInput = React.useRef(null);

  const [guestFirstName, setGuestFirstName] = useState(``);
  const [guestLastName, setGuestLastName] = useState(``);
  const guestFirstNameInput = React.useRef(null);
  const guestLastNameInput = React.useRef(null);

  useEffect(() => {
    if (loggedInUser) {
      emailInput.current.focus();
    }
  }, [email]);
  useEffect(() => {
    if (loggedInUser) {
      mobileInput.current.focus();
    }
  }, [mobile]);
  const stripeKey = useSelector((state) => state.movies.stripePublisherKey);
  const [loading, setLoading] = useState(false);
  const selected_seats = useSelector((state) => state.seatLayout.seats_selected);
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const isUnpaidBooking = useSelector((state) => state.movies.isUnpaidBooking);
  const netAmount = useSelector((state) => state.movies.netAmount);
  const [registerEmail, setRegisterEmail] = useState(``);
  const [confirmregisterEmail, setconfirmRegisterEmail] = useState(``);
  const [emailNotMatched, setEmailNotMatched] = useState(false);
  const [registerMobile, setRegisterMobile] = useState(``);
  const [firstName, setFirstName] = useState(``);
  const [defaultTab, setDefaultTab] = useState('0');
  const [lastName, setLastName] = useState(``);
  const [password, setPassword] = useState(``);
  const [registerConditionsCheck, setRegisterConditionsCheck] = useState(false);
  const [loginConditionsCheckError, setLoginConditionsCheckError] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [loginSubmitted, setLoginSubmitted] = useState(false);
  const [loginSubmittedViaOTP, setLoginSubmittedViaOTP] = useState(false);
  const [loginPassword, setLoginPassword] = useState('');
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [allowResendLogin, setAllowResendLogin] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [allowForgotPasswordResend, setAllowForgotPasswordResend] = useState(false);
  const [fpField, setFpField] = useState('');

  const [loginField, setLoginField] = useState('');
  const [isNumber, setisNumber] = useState(true);

  useEffect(() => {
    let ifNumber = numberRegex(loginField);
    if (!ifNumber) setCountryId(null);

    setisNumber(ifNumber);
  }, [loginField]);

  const [dobError, setDobError] = useState(false);
  const [calendarPopup, setCalendarPopup] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [ogDate, setOgDate] = useState(null);

  const state = useSelector((state) => state);
  const {
    auth: { loginOtpError },
  } = state;

  const isLoginViaOTPClicked = useRef(false);

  const onClickLoginViaOTP = () => {
    setType('otp');
    if (isLoginViaOTPClicked.current === true) {
      return;
    }

    setLoginSubmittedViaOTP(true);
    let count = 0;
    if (loginField === ``) {
      count++;
    }

    if (count > 0) {
      return;
    }
    setLoadingOtp(true);
    // setSubmittedviaOtp(false);
    isLoginViaOTPClicked.current = true;

    setTimeout(() => {
      isLoginViaOTPClicked.current = false;
    }, 5 * 1000);

    dispatch(
      loginViaOtp({
        loginField,
        callback: setLoadingOtp,
        successCallback: () => {
          setMemberBool('otp-login');
          dispatch(startTimer());
          setAllowResendLogin(false);
          setTimeout(() => setAllowResendLogin(true), 30000);
        },
      }),
    );
  };

  const onClickForgotPassword = () => {
    setOtpPopup(true);
    setMemberBool('forgot-password');
  };

  const onClickResendLogin = () => {
    if (allowResendLogin) {
      setAllowResendLogin(false);
      dispatch(
        loginViaOtp({
          loginField,
          callback: () => {},
          successCallback: () => {
            dispatch(resetTimer());
            dispatch(startTimer());
            setAllowResendLogin(false);
            setInterval(() => setAllowResendLogin(true), 30000);
          },
        }),
      );
    }
  };

  const onClickResend = () => {
    if (allowForgotPasswordResend) {
      setAllowForgotPasswordResend(false);
      dispatch(
        forgotPassword({
          field: fpField,
          callback: () => {},
          successCallback: () => {
            setAllowForgotPasswordResend(false);
            dispatch(resetTimer());
            dispatch(startTimer());
            setInterval(() => setAllowForgotPasswordResend(true), 30000);
          },
        }),
      );
    }
  };

  const emailRegisterInput = React.useRef(null);
  const confirmemailRegisterInput = React.useRef(null);
  const mobileRegisterInput = React.useRef(null);
  const fNameRegisterInput = React.useRef(null);
  const lnameRegisterInput = React.useRef(null);
  const passwordRef = React.useRef(null);

  useEffect(() => {
    const existingGuestUserId = localStorage.getItem(`guest_user_id`);
    if (!existingGuestUserId && !loggedInUser) {
      const guest_user_id = Math.floor(100000000 + Math.random() * 900000000);
      localStorage.setItem(`guest_user_id`, guest_user_id);
    }
  }, []);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      emailRegisterInput.current.focus();
    }
  }, [registerEmail]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      confirmemailRegisterInput.current.focus();
    }
  }, [confirmregisterEmail]);

  useEffect(() => {
    if (!loggedInUser && !guestUser && registerEmail !== confirmregisterEmail) {
      setEmailNotMatched(true);
    } else {
      setEmailNotMatched(false);
    }
  }, [registerEmail, confirmregisterEmail]);

  useEffect(() => {
    // console.log({ guestUser });
  }, [guestUser]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      mobileRegisterInput.current.focus();
    }
  }, [registerMobile]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      fNameRegisterInput.current.focus();
    }
  }, [firstName]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      lnameRegisterInput.current.focus();
    }
  }, [lastName]);
  useEffect(() => {
    if (loggedInUser) {
      setEmail(loggedInUser?.email || loggedInUser?.cust_email);
      setMobile(loggedInUser?.phone_no || loggedInUser?.cust_mobile);
    } else if (guestUser) {
      setEmail(guestUser.guest_email);
      if (!!guestUser.guest_mobile) setMobile(guestUser.guest_mobile);
      if (!!guestUser.guest_first_name)
        setGuestFirstName(guestUser.guest_first_name);
      if (!!guestUser.guest_last_name) setGuestLastName(guestUser.guest_last_name);
    }
  }, [loggedInUser, guestUser]);

  useEffect(() => {
    if (!loggedInUser && !guestUser) {
      passwordRef.current.focus();
    }
  }, [password]);
  useEffect(() => {
    if (reservation_id === null) {
      history.replace(`/movie-details/${movie_id}/${content_lang_id}`);
    }
  }, [reservation_id, history]);

  const [skipFnbItem, setSkipFnbItem] = useState(true);
  useLayoutEffect(() => {
    if (reservation_id) {
      dispatch(
        skipFnbItems({
          cinema_id: scheduleDetails?.cinema_id || null,
          reservation_id,
        }),
      ).then((skipFnbItem) => {
        setSkipFnbItem(skipFnbItem);
      });
    }
  }, [reservation_id, scheduleDetails]);

  const [memberBool, setMemberBool] = useState('login');
  const [switchTo, setSwitchTo] = useState('guestCheckout');
  const appliedVoucher = useSelector((state) => state.promotions.applied_voucher);
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_name, iso_2, lang_id } = selected_language;

  const [back, setBack] = useState(false);

  let private_rate_card_detail = reservationDetails?.private_rate_card_detail ?? [];

  const booking_type_id = reservationDetails?.booking_type_id ?? null;

  useEffect(() => {
    if (GUEST_CHECKOUT && (loggedInUser || guestUser)) {
      if (!isMobile) {
        setDefaultTab('1');
        setOpen([0, true, 0, 0, 0, 0, 0, 0]);
      } else {
        setDefaultTab('2');
        setOpen([0, 0, true, 0, 0, 0, 0, 0]);
      }
    }
  }, [loggedInUser, GUEST_CHECKOUT, isMobile]);

  const reservationDetailsLoading = useSelector(
    (state) => state.movies.reservationDetailsLoading,
  );
  const timeoutError = useSelector((state) => state.movies.timeoutError);

  const [isFreeRateCardSkipPaymentStep, setIsFreeRateCardSkipPaymentStep] =
    useState(false);
  useEffect(() => {
    let isFreeRateCardPaymentModeEnabled =
      activePaymentModes?.filter((p) => p?.pm_id == 19)?.length > 0 ?? false;

    let IHF =
      getInternetHandlingFees(
        category_id,
        is_internet_handling_enable,
        scheduleDetails,
        reservationDetails,
      ) ?? 0;

    if (
      isFreeRateCardPaymentModeEnabled &&
      reservationDetails?.amount != undefined &&
      reservationDetails?.amount != null &&
      fnbPrice?.total_price != undefined &&
      fnbPrice?.total_price != null &&
      reservationDetails?.amount + fnbPrice?.total_price + IHF == 0
    ) {
      setIsFreeRateCardSkipPaymentStep(true);
    } else {
      setIsFreeRateCardSkipPaymentStep(false);
    }
  }, [
    reservationDetails,
    discount,
    appliedVoucher,
    // cashCardRedemptionSelection,
    // cashCardDetails,
    // cashCardDetailsLoading,
    scheduleDetails,
    fnbPrice,
    activePaymentModes,
    category_id,
    is_internet_handling_enable,
  ]);

  useEffect(() => {
    if (loggedInUser?.cust_id) {
      dispatch(
        fetchUserHistory({
          time: Date.now(),
          customer_id: loggedInUser?.cust_id,
        }),
      );
    }
  }, [loggedInUser]);

  const [, reRender] = useState({});

  return (
    <>
      <style>
        {`.font-size-time>span{
            font-size:25px !important;
            font-weight:bold;
          }`}
      </style>
      <LoginPopup show={login} closeFunc={setLogin} />

      <section className="payment-page">
        <div className="container-fluid px-3">
          {/* OLD BACK BUTTON */}
          {false && (
            <div className="back-header">
              {!isMobile && (
                <button
                  className="btn-main mt-2 ml-3"
                  style={{ minWidth: 'unset' }}
                  onMouseOver={() => setBack(true)}
                  onMouseOut={() => setBack(false)}
                  onClick={() => history.go(-1)}
                >
                  {/* <img
                src={back ? arrowBackActive : arrowBack}
                className="mr3"
                alt=""
              /> */}
                  {back ? <ArrowBackActive /> : <ArrowBack />}
                </button>
              )}
              <div className="ml-auto mr-auto mr-md-0 ml-md-auto fs-14 px-3 px-md-0">
                {!timeoutError?.show ? (
                  <p className="font-size-time">
                    {t('Time Remaining')}:
                    <span>{minutes.toString().padStart(2, '0')}</span>:
                    <span>{seconds.toString().padStart(2, '0')}</span>
                  </p>
                ) : (
                  <p className="font-size-time">
                    {t('Time Remaining')}: <span>{'00'}</span>:<span>{'00'}</span>
                  </p>
                )}
              </div>
            </div>
          )}

          {/* <button onClick={onClickPay}>Pay Now</button> */}
          <div className="row page-content">
            {!isMobile && (
              <div className="col-md-4 col-xl-3 payment-booking-details pl-md-0">
                {!!reservationDetailsLoading || !scheduleDetails ? (
                  <div className="row">
                    <div className="col-12 text-center">
                      <Spinner />
                    </div>
                  </div>
                ) : !timeoutError || !timeoutError?.show ? (
                  <PaymentBookingDetails
                    category_id={category_id}
                    t={t}
                    r={reservationDetails}
                    s={scheduleDetails}
                  />
                ) : (
                  <></>
                )}
              </div>
            )}

            <div className="col-md-8 col-xl-9 userDetailsAccordian pr-0 pl-0 pl-md-3">
              {/* NEW BACK BUTTON */}
              {!isMobile ? (
                <>
                  <div className="inner_pages_header">
                    <div
                      className={`${category_id == 1 ? 'gold_go_back' : 'go_back'}`}
                      onClick={() => {
                        history.go(-1);
                      }}
                    >
                      <LeftArrow />
                    </div>
                    <div className="custom_breadcrumbs">
                      <div className="steps_new">
                        <span className=" bor_new1">
                          {category_id == '1'
                            ? t('Private Cinema Booking')
                            : `${t('Now Showing')}`}
                        </span>{' '}
                        {/* */}
                        {!skipFnbItem && (
                          <span className="px-3 bor_new1">
                            {t('Food & Beverages')}
                          </span>
                        )}
                        <span
                          className={`${
                            category_id == '1' ? 'bor_new_pvt' : 'bor_new'
                          } ${skipFnbItem && 'px-3'}`}
                        >
                          {t('Checkout')}
                        </span>
                        {/**/}
                      </div>
                      {/*<span onClick={()=>history.push("now-showing")}>
                  Now Showing</span> <span  onClick={()=>history.push("now-showing-booking")}>
                  Now Showing Booking</span>
                  <span>
                  Checkout</span> */}
                    </div>
                    <div className="fs-14  pl-3 pl-md-0 pr-3 time">
                      {!timeoutError?.show ? (
                        <p className="font-size-time">
                          {t('Time Remaining')}:{' '}
                          <span> {minutes.toString().padStart(2, '0')}:</span>
                          <span> {seconds.toString().padStart(2, '0')}</span>
                        </p>
                      ) : (
                        <p className="font-size-time">
                          {t('Time Remaining')}: <span>{'00'}</span>:
                          <span>{'00'}</span>
                        </p>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="fs-14  pl-3 pl-md-0 pr-3 mb-3"
                  style={{ marginTop: isMobile ? '20px' : undefined }}
                >
                  {!timeoutError?.show ? (
                    <p className="font-size-time">
                      {t('Time Remaining')}:
                      <span> {minutes.toString().padStart(2, '0')}</span>:
                      <span> {seconds.toString().padStart(2, '0')}</span>
                    </p>
                  ) : (
                    <p className="font-size-time">
                      {t('Time Remaining')}:<span>{'00'}</span> :<span>{'00'}</span>
                    </p>
                  )}
                </div>
              )}

              <Accordion
                activeKey={open[parseInt(defaultTab)] && defaultTab}
                className={`booking_accordion`}
              >
                {isMobile && (
                  <Card className="">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey="0"
                      onClick={() => {
                        toggleHandle(GUEST_CHECKOUT ? -1 : 0);
                      }}
                      className={`${open[0] ? 'active' : ''} ${
                        category_id == '1' && 'goldendrop'
                      }`}
                    >
                      {/* <div className="number-circle">1</div> */}
                      {/* <span>{t("Please enter contact information")}</span> */}
                      <article>
                        <h2>
                          <span>1.&nbsp;</span>
                          {open[0] ? t('Verify movie details') : t('Movie Details')}
                        </h2>

                        {/*{
                          open[0] &&
                            ? (<h2><span>1.&nbsp;</span>{t("Movie Details")}</h2>)
                            :
                            (<>
                              {
                                !!scheduleDetails?.movie_title && (
                                  <p> {`${
                                    getLangSpecificAttribute(
                                      scheduleDetails?.altMovieContent,
                                      lang_id,
                                      "mc_title"
                                    ) || scheduleDetails?.movie_title
                                  }`}</p>
                                )
                              }
                              {
                                !!scheduleDetails?.lang_name  && (
                                  <p>{`${
                                    !!t(scheduleDetails?.lang_name) ? t(scheduleDetails?.lang_name) : scheduleDetails?.lang_name
                                  }`}</p>
                                )
                              }
                            </>)
                        }*/}
                      </article>
                    </Accordion.Toggle>
                    <Accordion.Collapse id="collapse-padding" eventKey="0">
                      <article className="w-100 payment-booking-details booking_accordion_step">
                        {/* NEW DESIGN */}
                        {!!reservationDetailsLoading || !scheduleDetails ? (
                          <div className="row">
                            <div className="col-12 text-center">
                              <Spinner />
                            </div>
                          </div>
                        ) : !timeoutError || !timeoutError?.show ? (
                          <PaymentBookingDetails
                            category_id={category_id}
                            t={t}
                            r={reservationDetails}
                            s={scheduleDetails}
                          />
                        ) : (
                          <></>
                        )}
                        {(!GUEST_CHECKOUT || (isMobile && !!loggedInUser)) && (
                          <button
                            className={`btn-main payment-submit-btn mt-3 ${
                              category_id == 1 && 'golden_btn_pvt'
                            }`}
                            onClick={() => toggleHandle(1)}
                          >
                            {t('Confirm')}
                          </button>
                        )}
                      </article>
                    </Accordion.Collapse>
                  </Card>
                )}

                {GUEST_CHECKOUT && (
                  <Card className={``}>
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={getStepWithOffset('-1', GUEST_CHECKOUT)}
                      onClick={(e) => {
                        if (loggedInUser) return;
                        if (confirmDetails) return;
                        if (bookingSuccessful === true) {
                          e.preventDefault();
                          e.stopPropagation();
                          //return false;
                        }
                        loggedInUser && isMobile
                          ? toggleHandle(0)
                          : toggleHandle(-1);
                      }}
                      className={
                        (
                          isMobile
                            ? open[getStepWithOffset(0, GUEST_CHECKOUT)]
                            : open[getStepWithOffset(-1, GUEST_CHECKOUT)]
                        )
                          ? 'active'
                          : '' + category_id == '1'
                          ? ' goldendrop '
                          : ' '
                      }
                    >
                      {/* <div className="number-circle">{
                        getStepWithOffset(isMobile ? "1" : "0", GUEST_CHECKOUT)
                      }</div> */}
                      <article>
                        <h2>
                          <span>
                            {getStepWithOffset(
                              isMobile ? '1' : '0',
                              GUEST_CHECKOUT,
                            )}
                            .&nbsp;
                          </span>
                          {!isMobile
                            ? t('Sign in/Sign up to become a member')
                            : loggedInUser || guestUser
                            ? t('Signed In')
                            : t('Sign in/Sign up to become a member')}
                        </h2>
                      </article>
                    </Accordion.Toggle>
                    <Accordion.Collapse
                      eventKey={
                        isMobile && !!loggedInUser
                          ? '9999'
                          : getStepWithOffset('-1', GUEST_CHECKOUT)
                      }
                      id="collapse-padding"
                      className={`${category_id == '1' && 'goldendrop'}`}
                    >
                      <article className="booking_accordion_step">
                        <div
                          className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto payment_auth_options my-0"
                          style={{ gap: isMobile ? '10px' : '' }}
                        >
                          <>
                            <button
                              className={`btn-main mx-auto ${
                                category_id == 1 ? ' guest_btn_pvt' : ' guest_btn'
                              }`}
                              onClick={(e) => {
                                if (bookingSuccessful === true) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  //return false;
                                }
                                setMemberBool('guestCheckout');
                                setSwitchTo('guestCheckout');
                                if (isMobile) {
                                  toggleHandle(1);
                                } else {
                                  toggleHandle(0);
                                }
                              }}
                            >
                              {t('Guest Checkout')}
                            </button>
                            <button
                              className={`btn-main mx-auto ${
                                category_id == 1 && ' golden_btn_pvt'
                              }`}
                              onClick={(e) => {
                                if (bookingSuccessful === true) {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  //return false;
                                }
                                setMemberBool('login');
                                setSwitchTo('login');
                                if (isMobile) {
                                  toggleHandle(1);
                                } else {
                                  toggleHandle(0);
                                }
                              }}
                            >
                              {t('Sign In')}
                            </button>
                          </>
                        </div>
                        <div className="mt-3">
                          <p
                            className={`text-center not_a_member ${
                              category_id == 1 && ' golden-link'
                            }`}
                            onClick={(e) => {
                              if (bookingSuccessful === true) {
                                e.preventDefault();
                                e.stopPropagation();
                                //return false;
                              }
                              setMemberBool('register');
                              setSwitchTo('register');
                              if (isMobile) {
                                toggleHandle(1);
                              } else {
                                toggleHandle(0);
                              }
                            }}
                          >
                            {t('Not a member yet')}?{' '}
                            <a href="#">{t(`header-footer-menu.signup`)}</a>
                          </p>
                        </div>
                      </article>
                    </Accordion.Collapse>
                    {/* ) : null} */}
                  </Card>
                )}

                <Card className={``}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={getStepWithOffset(
                      isMobile ? '1' : '0',
                      GUEST_CHECKOUT,
                    )}
                    onClick={(e) => {
                      if (bookingSuccessful === true) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }
                      if (isMobile) {
                        toggleHandle(1);
                      } else {
                        toggleHandle(0);
                      }
                    }}
                    className={`
                    ${
                      (
                        isMobile
                          ? open[getStepWithOffset(1, GUEST_CHECKOUT)]
                          : open[getStepWithOffset(0, GUEST_CHECKOUT)]
                      )
                        ? 'active'
                        : ''
                    } 
                    ${category_id == '1' && 'goldendrop'}
                    ${
                      (isMobile
                        ? open[getStepWithOffset(1, GUEST_CHECKOUT)]
                        : open[getStepWithOffset(0, GUEST_CHECKOUT)]) && 'inactive'
                    }`}
                  >
                    {/* <div className="number-circle">{
                      getStepWithOffset(isMobile ? "2" : "1", GUEST_CHECKOUT)
                    }</div> */}
                    {/* <span>{t("Please enter contact information")}</span> */}
                    <article className="current-user-details-card-header">
                      <h2>
                        <span
                          className={`${
                            isMobile && open[getStepWithOffset(1, GUEST_CHECKOUT)]
                          }`}
                        >
                          {getStepWithOffset(isMobile ? '2' : '1', GUEST_CHECKOUT)}
                          .&nbsp;
                        </span>
                        {isMobile &&
                        open[getStepWithOffset(1, GUEST_CHECKOUT)] !== true &&
                        (loggedInUser || guestUser)
                          ? t('Verified')
                          : switchTo !== 'guestCheckout' || !guestUser
                          ? t(
                              'Verify your details', // "Sign in/Sign up to become a member"
                            )
                          : t('Verify your details as guest')}
                      </h2>
                      {!isMobile && (open[0] != 1 || open[0] != true) && (
                        <>
                          <p
                            className={`text-uppercase ${
                              category_id == 1 ? ' text-golden ' : ' text-primary '
                            }`}
                          >
                            {memberBool !== 'guestCheckout' ? (
                              !!guestUser && switchTo == 'guestCheckout' ? (
                                t('Guest Checkout')
                              ) : (
                                <>
                                  {
                                    ''
                                    // t(`header-footer-menu.Sign in`) } / { t(`header-footer-menu.signup`)
                                  }
                                </>
                              )
                            ) : (
                              t('Guest Checkout')
                            )}
                          </p>
                          {switchTo === 'guestCheckout' && email && (
                            <p className="current-user-details-card-header-email">
                              {email}
                            </p>
                          )}
                        </>
                      )}
                    </article>
                  </Accordion.Toggle>
                  {/* {defaultTab == "0" ? ( */}
                  <Accordion.Collapse
                    eventKey={getStepWithOffset(
                      isMobile ? '1' : '0',
                      GUEST_CHECKOUT,
                    )}
                    id="collapse-padding"
                  >
                    {/* <> */}
                    {guestUser && switchTo === 'guestCheckout' ? (
                      <>
                        <form
                          onSubmit={(e) =>
                            onClickLoginConfirmDetails(
                              e,
                              guestUser,
                              isFreeRateCardSkipPaymentStep,
                            )
                          }
                          className="booking_accordion_step"
                        >
                          <div className="custom-form col-md-10 col-lg-9 col-xl-7 px-0 mx-auto">
                            <div>
                              <label>
                                <span> {t('login_form.Email')}</span>
                              </label>
                              <input
                                disabled={guestUser ? true : undefined}
                                ref={emailInput}
                                type="email"
                                className="form-control"
                                placeholder={t('placeholder.Enter your Email ID')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />

                              <p
                                className={`error ${
                                  category_id == 1 && ' error-golden '
                                }`}
                              >
                                {loginSubmitted &&
                                  email === '' &&
                                  t('login_form.error_messages.Email is required')}
                              </p>
                            </div>
                            <div className="row justify-content-between align-items-center">
                              <div className="col-lg-6 pr-lg-2">
                                <label>
                                  <span>{t('login_form.First Name')}</span>
                                </label>
                                <input
                                  ref={guestFirstNameInput}
                                  type="text"
                                  className="form-control"
                                  placeholder={t(
                                    'login_form.placeholders.Enter your first name',
                                  )}
                                  value={guestFirstName}
                                  onChange={(e) =>
                                    setGuestFirstName(e.target.value)
                                  }
                                />
                                <p
                                  className={`error ${
                                    category_id == 1 && ' error-golden '
                                  }`}
                                >
                                  {loginSubmitted &&
                                    guestFirstNameInput === '' &&
                                    t('field_required')}
                                </p>
                              </div>
                              <div className="col-lg-6 pl-lg-2">
                                <label>
                                  <span> {t('login_form.Last Name')}</span>
                                </label>
                                <input
                                  ref={guestLastNameInput}
                                  type="text"
                                  className="form-control"
                                  placeholder={t(
                                    'login_form.placeholders.Enter your last name',
                                  )}
                                  value={guestLastName}
                                  onChange={(e) => setGuestLastName(e.target.value)}
                                />

                                <p
                                  className={`error ${
                                    category_id == 1 && ' error-golden '
                                  }`}
                                >
                                  {loginSubmitted &&
                                    guestLastNameInput === '' &&
                                    t('field_required')}
                                </p>
                              </div>
                            </div>
                            <div className="">
                              <label className="w-100">
                                <span className="d-inline-block mb-2">
                                  {' '}
                                  {t('login_form.Phone Number')}
                                </span>
                                <div className="d-flex">
                                  <CountryCodeDropdown
                                    isPrivate={category_id == 1}
                                    className="form-control mr-2"
                                    onSelectCountry={({
                                      country_id,
                                      country_mob_code,
                                    }) => {
                                      setCountryId(country_id);
                                      setCountryMobCode(country_mob_code);
                                      setCountryMobCodeGuest(country_mob_code);
                                    }}
                                  />

                                  <input
                                    ref={mobileInput}
                                    type="number"
                                    min="10"
                                    className="form-control mb-0"
                                    placeholder={t(
                                      'login_form.placeholders.Enter your phone number',
                                    )}
                                    value={mobile}
                                    onChange={(e) => setMobile(e.target.value)}
                                    onKeyPress={(e) => {
                                      // guest register process
                                      // console.log(
                                      //   'guest register process onKeyPress',
                                      //   e.key === 'Enter',
                                      //   e.key,
                                      // );
                                      e.key === 'Enter' && e.preventDefault();
                                    }}
                                  />
                                </div>
                              </label>
                            </div>
                            <p
                              className={`error ${
                                category_id == 1 && ' error-golden '
                              }`}
                            ></p>

                            <div className="pt-4 pb-3">
                              <button
                                className={`payment-submit-btn btn-main ${
                                  onClickLoginConfirmDetailsLoader && 'loader'
                                } ${category_id == 1 && 'golden_btn_pvt'}`}
                                type="submit"
                              >
                                {t('Confirm')}
                              </button>
                            </div>
                          </div>
                        </form>
                      </>
                    ) : loggedInUser ? (
                      // USER CONTACT INFO START
                      <>
                        <form
                          onSubmit={(e) =>
                            onClickLoginConfirmDetails(
                              e,
                              undefined,
                              isFreeRateCardSkipPaymentStep,
                            )
                          }
                          className="booking_accordion_step"
                        >
                          <div className="custom-form col-md-10 col-lg-9 col-xl-7 px-0 mx-auto">
                            <div>
                              <label>
                                <span> {t('login_form.Email')}</span>
                              </label>
                              <input
                                ref={emailInput}
                                type="email"
                                className="form-control"
                                placeholder={t('placeholder.Enter your Email ID')}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                              />

                              {/* {loginSubmitted && email === "" && ( */}
                              <p
                                className={`error ${
                                  category_id == 1 && ' error-golden '
                                }`}
                              >
                                {loginSubmitted &&
                                  email === '' &&
                                  t('login_form.error_messages.Email is required')}
                              </p>
                              {/* )} */}
                            </div>
                            <label>
                              <span> {t('login_form.Phone Number')}</span>
                            </label>
                            <div className="d-flex">
                              <CountryCodeDropdown
                                isPrivate={category_id == 1}
                                className="form-control mr-2"
                                onSelectCountry={({
                                  country_id,
                                  country_mob_code,
                                }) => {
                                  setCountryId(country_id);
                                  setCountryMobCode(country_mob_code);
                                }}
                              />

                              <input
                                ref={mobileInput}
                                type="number"
                                min="10"
                                className="form-control mb-0"
                                placeholder={t(
                                  'login_form.placeholders.Enter your phone number',
                                )}
                                value={mobile}
                                onChange={(e) => setMobile(e.target.value)}
                              />

                              {/* {loginSubmitted && mobile === "" && ( */}

                              {/* )} */}
                            </div>
                            <p
                              className={`error ${
                                category_id == 1 && ' error-golden '
                              }`}
                            >
                              {loginSubmitted &&
                                mobile === '' &&
                                t(
                                  'login_form.error_messages.Phone number is required',
                                )}
                            </p>
                            {/* <div className="agree-check">
                             <input
                               className="form-check-input"
                               type="checkbox"
                               name="exampleRadios"
                               id="exampleRadios1"
                               checked={loggedInConditionsCheck}
                               onChange={(e) => setLoggedInConditionsCheck(e.target.checked)}
                             />
                             <label className="form-check-label pt-1 pl-1" for="exampleRadios1">
                               I agree to all Terms & Conditions
                             </label>
                             {loginConditionsCheckError && (
                               <p className={`error ${ category_id == 1 && ' error-golden '}`}>
                                 You must agree to all Terms & Conditions
                               </p>
                             )}
                           </div> */}

                            <div className="pt-4 pb-3">
                              <button
                                className={`payment-submit-btn btn-main ${
                                  onClickLoginConfirmDetailsLoader && 'loader'
                                } ${category_id == 1 && 'golden_btn_pvt'}`}
                                type="submit"
                              >
                                {t('Confirm')}
                              </button>
                            </div>
                          </div>
                        </form>
                        {/* <div className="next-step pb-3">
                          <p>{t("Next Step")}</p>
                          <div>
                            <img
                              src={greyArrowDown}
                              alt="Arrow Down"
                              className="grey-arrow-down"
                            />
                          </div>
                        </div> */}
                      </>
                    ) : (
                      <>
                        <form
                          onSubmit={(e) =>
                            onClickRegisterConfirmDetails(
                              e,
                              isFreeRateCardSkipPaymentStep,
                            )
                          }
                          className="booking_accordion_step"
                        >
                          <div
                            className={`custom-form col-lg-9 col-xl-7 px-0 mx-auto ${
                              category_id == 1 && ' golden-tab'
                            }`}
                          >
                            {/* <div
                              className="register-btn mx-auto"
                              onClick={() => setLogin(true)}
                              onMouseOver={() => setLoginIconBool(true)}
                              onMouseOut={() => setLoginIconBool(false)}
                            >
                              <button
                                className="payment-submit-btn"
                                onClick={() => setMemberBool("login")}
                              >
                                 <img
                                  src={
                                    loginIconBool && !isMobile
                                      ? activeLoginIcon
                                      : loginIcon
                                  }
                                  className="img-fluid pr-3"
                                  alt=""
                                />
                                {t("Login")}
                              </button>
                            </div>
                            <div className="or-div">
                              <p className="or-text">
                                <span>{t("Or")}</span>
                              </p>
                            </div>
                            <div>
                              <button
                                className={`mb-3 payment-submit-btn btn-main ${
                                  registerLoading && "loader-text"
                                }`}
                                type="submit"
                                onClick={() => setMemberBool("register")}
                              >
                                {t("Register")}
                              </button>
                            </div> */}
                            {/* {memberBool ? ( */}
                            {memberBool !== 'guestCheckout' && (
                              <div className="d-flex w-100 justify-content-around align-items-center mb-3 register_tabs">
                                {memberBool !== 'guestCheckout' && (
                                  <div
                                    className={`w-100 tabs py-2 ${
                                      memberBool === 'login' && 'active'
                                    }`}
                                    onClick={() => {
                                      setMemberBool('login');
                                    }}
                                  >
                                    {t(`header-footer-menu.Sign in`)}
                                  </div>
                                )}

                                {memberBool !== 'guestCheckout' && (
                                  <div
                                    className={`w-100 tabs py-2 ${
                                      memberBool === 'register' && 'active'
                                    }`}
                                    onClick={() => {
                                      setMemberBool('register');
                                    }}
                                  >
                                    {/* {t(`login_form.Login`)} */}
                                    {t(`header-footer-menu.signup`)}
                                  </div>
                                )}

                                {/* {memberBool === "guestCheckout" && (
                                <div
                                  className={`w-100 tabs py-2 ${memberBool === "guestCheckout" && "active"
                                    }`}
                                  onClick={() => {
                                    setMemberBool("guestCheckout");
                                  }}
                                >
                                  {t("Guest Checkout")}
                                </div>
                              )} */}
                              </div>
                            )}
                            <div
                              className={`w-100 login-popup ${
                                memberBool === 'login' ? 'd-block' : 'd-none'
                              }`}
                            >
                              <div className="w-100 user-form">
                                <label className="mb-0">
                                  <span>
                                    {t('login_form.placeholders.email-mobile')}
                                  </span>
                                </label>
                                <div className="d-flex">
                                  {isNumber && (
                                    <CountryCodeDropdown
                                      isPrivate={category_id == 1}
                                      className="country_code_drop mr-2"
                                      onSelectCountry={({
                                        country_id,
                                        country_mob_code,
                                      }) => {
                                        setCountryId(country_id);
                                        setCountryMobCode(country_mob_code);
                                      }}
                                    />
                                  )}
                                  <input
                                    type="text"
                                    placeholder={t(`Enter_email_or_mobile`)}
                                    value={loginField}
                                    onChange={(e) => setLoginField(e.target.value)}
                                    onKeyPress={(e) => {
                                      e.key === 'Enter' && e.preventDefault();
                                    }}
                                  />
                                </div>

                                <p
                                  className={`error ${
                                    category_id == 1 && ' error-golden '
                                  }`}
                                >
                                  {(loginSubmitted || loginSubmittedViaOTP) &&
                                    loginField === '' &&
                                    t('field_required')}
                                </p>

                                <label className="position-relative mb-0">
                                  <span>{t('login_form.Password')}</span>
                                  <input
                                    type={showLoginPassword ? 'text' : 'password'}
                                    autocomplete="new-password"
                                    placeholder={t(
                                      'login_form.placeholders.Enter your password',
                                    )}
                                    value={loginPassword}
                                    min="4"
                                    onChange={(e) =>
                                      setLoginPassword(e.target.value)
                                    }
                                    onKeyPress={(e) => {
                                      // login process
                                      e.key === 'Enter' /*&& e.preventDefault()*/ &&
                                        onSubmitLogin(e);
                                    }}
                                  />
                                  {!showLoginPassword && (
                                    <img
                                      width="30"
                                      height="30"
                                      className="show-password-icon"
                                      src={showPasswordIcon}
                                      onClick={() =>
                                        setShowLoginPassword((x) => !x)
                                      }
                                      alt=""
                                    />
                                  )}
                                  {showLoginPassword && (
                                    <img
                                      width="30"
                                      height="30"
                                      className="show-password-icon"
                                      src={hidePasswordIcon}
                                      onClick={() =>
                                        setShowLoginPassword((x) => !x)
                                      }
                                      alt=""
                                    />
                                  )}
                                </label>
                                <p
                                  className={`error ${
                                    category_id == 1 && ' error-golden '
                                  }`}
                                >
                                  {loginSubmitted &&
                                    loginPassword === '' &&
                                    type === 'email' &&
                                    t(
                                      'login_form.error_messages.Password is required',
                                    )}
                                </p>

                                {loginError &&
                                loginPassword !== '' &&
                                type === 'email' ? (
                                  <p
                                    className={`error ${
                                      category_id == 1 && ' error-golden '
                                    }`}
                                  >
                                    {t(loginError)},{' '}
                                    <span
                                      style={{ cursor: `pointer` }}
                                      onClick={onErrorHandling}
                                    >
                                      {loginError === 'Password is incorrect' ? (
                                        <u>
                                          {t(
                                            `please click here to change password`,
                                          )}
                                        </u>
                                      ) : (
                                        <u>{t(`please click here to sign up`)}</u>
                                      )}
                                    </span>
                                  </p>
                                ) : null}
                                {loginOtpError && type === 'otp' ? (
                                  <p
                                    className={`error ${
                                      category_id == 1 && ' error-golden '
                                    }`}
                                  >
                                    {t(loginOtpError)},{' '}
                                    <span
                                      style={{ cursor: `pointer` }}
                                      onClick={() => {
                                        setMemberBool('register');
                                        setLoginSubmitted(false);
                                      }}
                                    >
                                      <u>{t('please click here to sign up')}</u>
                                    </span>
                                  </p>
                                ) : null}

                                <button
                                  type="button"
                                  onClick={onSubmitLogin}
                                  className={`payment-submit-btn btn-main mt-3 ${
                                    loading && 'loader-text'
                                  } ${category_id == 1 && 'golden_btn_pvt'}`}
                                >
                                  {t('login_form.Login')}
                                </button>
                              </div>

                              <div className="login-popup__actions">
                                <button
                                  className={`${
                                    category_id == 1
                                      ? 'btn-main guest_btn_pvt '
                                      : 'btn-basic '
                                  } `}
                                  onClick={onClickForgotPassword}
                                >
                                  {t(`login_form.forgot-password`)}
                                </button>

                                {/* <button
                                  onClick={onClickLoginViaOTP}
                                  className={`${
                                    category_id == 1
                                      ? 'btn-main guest_btn_pvt '
                                      : 'btn-basic '
                                  } ${loadingOtp && 'loader-text'}`}
                                >
                                  {t(`Login via OTP`)}
                                </button> */}
                              </div>
                              <div className="mt-3">
                                <p
                                  className={`text-center not_a_member  ${
                                    category_id == 1 && ' golden-link'
                                  }`}
                                  onClick={() => setMemberBool('register')}
                                >
                                  {t('Not a member yet')}?{' '}
                                  <a href="#">{t(`header-footer-menu.signup`)}</a>
                                </p>
                              </div>
                            </div>

                            {/* Login Via OTP */}
                            <div
                              className={`w-100 login-popup ${
                                memberBool === 'otp-login' ? 'd-block' : 'd-none'
                              }`}
                            >
                              <LoginOtp
                                isPrivate={category_id == 1}
                                allowResendLogin={allowResendLogin}
                                setAllowResendLogin={setAllowResendLogin}
                                onClickResendLogin={onClickResendLogin}
                                onSuccess={() => setMemberBool('login-success')}
                                closeFunc={() => setMemberBool('login')}
                              />
                            </div>

                            <div
                              className={`w-100 login-popup ${
                                memberBool === 'forgot-password'
                                  ? 'd-block'
                                  : 'd-none'
                              }`}
                            >
                              <ForgotPasswordOtp
                                isPrivate={category_id == 1}
                                onClickResend={onClickResend}
                                allowResend={allowForgotPasswordResend}
                                setAllowResend={setAllowForgotPasswordResend}
                                field={fpField}
                                setField={setFpField}
                                onSuccess={() =>
                                  setMemberBool('forgot-password-success')
                                }
                                closeFunc={() => setMemberBool('login')}
                              />
                            </div>

                            {/* ) : ( */}
                            <div
                              className={`w-100 ${
                                memberBool === 'register' ? 'd-block' : 'd-none'
                              }`}
                            >
                              <div>
                                <label className="">
                                  <span>{t('login_form.Email')}</span>
                                </label>
                                <input
                                  ref={emailRegisterInput}
                                  type="email"
                                  className="form-control"
                                  placeholder={t(
                                    'login_form.placeholders.Enter your email',
                                  )}
                                  value={registerEmail}
                                  onChange={(e) => setRegisterEmail(e.target.value)}
                                />

                                {/* {submitted && registerEmail === "" && ( */}
                                <p
                                  className={`error ${
                                    category_id == 1 && ' error-golden '
                                  }`}
                                >
                                  {submitted &&
                                    registerEmail === '' &&
                                    t(
                                      'login_form.error_messages.Email is required',
                                    )}
                                </p>
                                {/* )} */}
                              </div>
                              <label>
                                <span>{t('login_form.Phone Number')}</span>
                              </label>
                              <div className="d-flex">
                                <CountryCodeDropdown
                                  isPrivate={category_id == 1}
                                  className="form-control mr-2"
                                  onSelectCountry={({
                                    country_id,
                                    country_mob_code,
                                  }) => {
                                    setCountryId(country_id);
                                    setCountryMobCode(country_mob_code);
                                  }}
                                />
                                <input
                                  ref={mobileRegisterInput}
                                  type="number"
                                  min="9"
                                  className="form-control"
                                  placeholder={t(
                                    'login_form.placeholders.Enter your phone number',
                                  )}
                                  value={registerMobile}
                                  onChange={(e) =>
                                    setRegisterMobile(e.target.value)
                                  }
                                />
                                {/* {submitted && registerMobile === "" && ( */}

                                {/* )} */}
                              </div>

                              <p
                                className={`error ${
                                  category_id == 1 && ' error-golden '
                                }`}
                              >
                                {submitted &&
                                  registerMobile === '' &&
                                  t(
                                    'login_form.error_messages.Phone number is required',
                                  )}
                              </p>

                              <div className="w-100">
                                <label className="w-100">
                                  <span>{t('login_form.DateOfBirth')}</span>
                                </label>
                                <div className="w-100 position-relative">
                                  <input
                                    className="form-control"
                                    type="date"
                                    id="start"
                                    name="dateofbirth"
                                    placeholder={t('login_form.DateOfBirth')}
                                    min="1920-01-01"
                                    max={moment().format('YYYY-MM-DD')}
                                    onChange={(e) => {
                                      setSelectedDate(e?.target?.value);
                                    }}
                                  />
                                </div>

                                <p
                                  className={`error ${
                                    category_id == 1 && ' error-golden '
                                  }`}
                                >
                                  {submitted &&
                                    !selectedDate &&
                                    dobError &&
                                    t('Please enter event date')}
                                </p>
                              </div>

                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 pr-lg-2">
                                  <label>
                                    <span>{t('login_form.First Name')}</span>
                                  </label>
                                  <input
                                    ref={fNameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      'login_form.placeholders.Enter your first name',
                                    )}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                  />

                                  {/* {submitted && firstName === "" && ( */}
                                  <p
                                    className={`error ${
                                      category_id == 1 && ' error-golden '
                                    }`}
                                  >
                                    {submitted &&
                                      firstName === '' &&
                                      t(
                                        'login_form.error_messages.First name is required',
                                      )}
                                  </p>
                                  {/* )} */}
                                </div>
                                <div className="col-lg-6 pl-lg-2">
                                  <label>
                                    <span> {t('login_form.Last Name')}</span>
                                  </label>
                                  <input
                                    ref={lnameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      'login_form.placeholders.Enter your last name',
                                    )}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                  />

                                  {/* {submitted && lastName === "" && ( */}
                                  <p
                                    className={`error ${
                                      category_id == 1 && ' error-golden '
                                    }`}
                                  >
                                    {submitted &&
                                      lastName === '' &&
                                      t(
                                        'login_form.error_messages.Last name is required',
                                      )}
                                  </p>
                                  {/* )} */}
                                </div>
                              </div>
                              <label>
                                <span> {t('login_form.Password')}</span>
                              </label>
                              <div className="position-relative">
                                <input
                                  ref={passwordRef}
                                  type={showSignupPassword ? 'text' : 'password'}
                                  autocomplete="new-password"
                                  className="form-control mb-0"
                                  placeholder={t(
                                    'login_form.placeholders.Enter your password',
                                  )}
                                  value={password}
                                  onChange={(e) => setPassword(e.target.value)}
                                  onKeyPress={(e) => {
                                    // register process
                                    // console.log(
                                    //   'register process onKeyPress',
                                    //   e.key === 'Enter',
                                    //   e.key,
                                    // );
                                    e.key === 'Enter' && e.preventDefault();
                                  }}
                                />
                                {!showSignupPassword && (
                                  <img
                                    width="30"
                                    height="30"
                                    className="show-password-icon show-password-icon-2"
                                    src={showPasswordIcon}
                                    onClick={() => setShowSignupPassword((x) => !x)}
                                    alt=""
                                  />
                                )}
                                {showSignupPassword && (
                                  <img
                                    width="30"
                                    height="30"
                                    className="show-password-icon show-password-icon-2"
                                    src={hidePasswordIcon}
                                    onClick={() => setShowSignupPassword((x) => !x)}
                                    alt=""
                                  />
                                )}
                                {/* {submitted && password === "" && ( */}

                                {/* )} */}
                                <p
                                  className={`error ${
                                    category_id == 1 && ' error-golden '
                                  }`}
                                >
                                  {submitted &&
                                    password === '' &&
                                    t(
                                      'login_form.error_messages.Password is required',
                                    )}
                                </p>
                              </div>

                              <div className="agree-check mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  checked={registerConditionsCheck}
                                  onChange={(e) =>
                                    setRegisterConditionsCheck(e.target.checked)
                                  }
                                />
                                <label
                                  className={`form-check-label pt-1 pl-1 ${
                                    category_id == 1 && ' golden-link'
                                  }`}
                                  for="exampleRadios1"
                                >
                                  {/* {t("I agree to all Terms & Conditions")} */}
                                  {t('I agree to all')}{' '}
                                  <a href="/tnc" target="_blank">
                                    {t('Terms & Conditions')}
                                  </a>
                                </label>
                                {/* {registerConditionsCheckError && ( */}

                                {/* )} */}
                                {/* {registerError && <p>{registerError}</p>} */}
                              </div>
                              <p
                                className={`error ${
                                  category_id == 1 && ' error-golden '
                                }`}
                              >
                                {submitted && !registerConditionsCheck && (
                                  <>
                                    {t('You must agree to all Terms & Conditions')}
                                    <br /> <br />
                                  </>
                                )}
                                {registerError && <>{t(registerError)}</>}
                              </p>
                              {/* <p className={`error ${ category_id == 1 && ' error-golden '}`}>
                            </p> */}
                              {/* <div>
                              <button
                                className={`payment-submit-btn btn-main ${
                                  registerLoading && "loader-text"
                                }`}
                                type="submit"
                              >
                                {t("Continue")}
                              </button>
                            </div>
                            <div className="or-div">
                              <p className="or-text">
                                <span>{t("Or")}</span>
                              </p>
                            </div>
                            <div
                              className="register-btn w-75 mx-auto"
                              onClick={() => setLogin(true)}
                              onMouseOver={() => setLoginIconBool(true)}
                              onMouseOut={() => setLoginIconBool(false)}
                            >
                              <button className="payment-submit-btn">
                                <img
                                  src={
                                    loginIconBool && !isMobile
                                      ? activeLoginIcon
                                      : loginIcon
                                  }
                                  className="img-fluid pr-3"
                                  alt=""
                                />
                                {t("Login")}
                              </button>
                            </div> */}
                              <div>
                                <button
                                  className={`payment-submit-btn btn-main ${
                                    registerLoading && 'loader-text'
                                  } ${category_id == 1 && 'golden_btn_pvt'}`}
                                  type="submit"
                                >
                                  {t('Continue')}
                                </button>
                              </div>
                            </div>
                            <div
                              className={`w-100 ${
                                memberBool === 'guestCheckout'
                                  ? 'd-block'
                                  : 'd-none'
                              }`}
                            >
                              <div className="row justify-content-between align-items-center">
                                <div className="col-lg-6 pr-lg-2">
                                  <label>
                                    <span>{t('login_form.First Name')}</span>{' '}
                                  </label>
                                  <input
                                    ref={fNameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      'login_form.placeholders.Enter your first name',
                                    )}
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                  />
                                  <p
                                    className={`error ${
                                      category_id == 1 && ' error-golden '
                                    }`}
                                  >
                                    {submitted &&
                                      firstName === '' &&
                                      t(
                                        'login_form.error_messages.First name is required',
                                      )}
                                  </p>
                                </div>
                                <div className="col-lg-6 pl-lg-2">
                                  <label>
                                    <span>{t('login_form.Last Name')}</span>{' '}
                                  </label>
                                  <input
                                    ref={lnameRegisterInput}
                                    type="text"
                                    className="form-control"
                                    placeholder={t(
                                      'login_form.placeholders.Enter your last name',
                                    )}
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                  />
                                  <p
                                    className={`error ${
                                      category_id == 1 && ' error-golden '
                                    }`}
                                  >
                                    {submitted &&
                                      lastName === '' &&
                                      t(
                                        'login_form.error_messages.Last name is required',
                                      )}
                                  </p>
                                </div>
                              </div>

                              <div>
                                <label>
                                  <span>{t('login_form.Email')} </span>
                                </label>
                                <input
                                  ref={emailRegisterInput}
                                  type="email"
                                  className="form-control"
                                  placeholder={t(
                                    'login_form.placeholders.Enter your email',
                                  )}
                                  value={registerEmail}
                                  onChange={(e) => setRegisterEmail(e.target.value)}
                                />

                                <p
                                  className={`error ${
                                    category_id == 1 && ' error-golden '
                                  }`}
                                >
                                  {submitted &&
                                    registerEmail === '' &&
                                    t(
                                      'login_form.error_messages.Email is required',
                                    )}
                                </p>
                              </div>

                              <div>
                                <label>
                                  <span>{t('Confirm Email')}</span>{' '}
                                </label>
                                <input
                                  ref={confirmemailRegisterInput}
                                  type="email"
                                  className="form-control"
                                  placeholder={t(
                                    'login_form.placeholders.Enter your email',
                                  )}
                                  value={confirmregisterEmail}
                                  onChange={(e) =>
                                    setconfirmRegisterEmail(e.target.value)
                                  }
                                />

                                <p
                                  className={`error ${
                                    category_id == 1 && ' error-golden '
                                  }`}
                                >
                                  {submitted &&
                                    emailNotMatched &&
                                    t('Email did not matched')}
                                </p>
                              </div>

                              <label>
                                <span>{t('login_form.Phone Number')}</span>{' '}
                              </label>
                              <div className="d-flex">
                                <CountryCodeDropdown
                                  isPrivate={category_id == 1}
                                  className="form-control mr-2"
                                  onSelectCountry={({
                                    country_id,
                                    country_mob_code,
                                  }) => {
                                    setCountryId(country_id);
                                    setCountryMobCode(country_mob_code);
                                  }}
                                />
                                <input
                                  ref={mobileRegisterInput}
                                  type="number"
                                  min="9"
                                  className="form-control"
                                  placeholder={t(
                                    'login_form.placeholders.Enter your phone number',
                                  )}
                                  value={registerMobile}
                                  onChange={(e) =>
                                    setRegisterMobile(e.target.value)
                                  }
                                />
                                {/* {submitted && registerMobile === "" && ( */}

                                {/* )} */}
                              </div>
                              <p
                                className={`error ${
                                  category_id == 1 && ' error-golden '
                                }`}
                              >
                                {submitted &&
                                  !registerMobile &&
                                  t(
                                    'login_form.placeholders.Enter your phone number',
                                  )}
                              </p>
                              <div className="agree-check mb-1">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  name="exampleRadios"
                                  id="exampleRadios1"
                                  checked={registerConditionsCheck}
                                  onChange={(e) =>
                                    setRegisterConditionsCheck(e.target.checked)
                                  }
                                />
                                <label
                                  className={`form-check-label pt-1 pl-1 ${
                                    category_id == 1 && ' golden-link'
                                  }`}
                                  for="exampleRadios1"
                                >
                                  {/* {t("I agree to all Terms & Conditions")} */}
                                  {t('I agree to all')}{' '}
                                  <a href="/tnc" target="_blank">
                                    {t('Terms & Conditions')}
                                  </a>
                                </label>
                                {/* {registerConditionsCheckError && ( */}

                                {/* )} */}
                                {/* {registerError && <p>{registerError}</p>} */}
                              </div>
                              <p
                                className={`error ${
                                  category_id == 1 && ' error-golden '
                                }`}
                              >
                                {submitted && !registerConditionsCheck && (
                                  <>
                                    {t('You must agree to all Terms & Conditions')}
                                    <br /> <br />
                                  </>
                                )}
                                {registerError && <>{t(registerError)}</>}
                              </p>
                              <div>
                                <button
                                  className={`payment-submit-btn btn-main ${
                                    registerLoading && 'loader-text'
                                  } ${category_id == 1 && 'golden_btn_pvt'}`}
                                  type="submit"
                                >
                                  {t('Guest Checkout')}
                                </button>
                              </div>
                            </div>
                            {/* )} */}
                          </div>
                        </form>
                        {/* <div className="next-step">
                          <p>Next Step</p>
                          <div>
                            <img
                              src={greyArrowDown}
                              alt="Arrow Down"
                              className="grey-arrow-down"
                            />
                          </div>
                        </div> */}
                      </>
                    )}

                    {/* <PaymentOtp /> */}
                    {/* </> */}
                  </Accordion.Collapse>
                  {/* ) : null} */}
                </Card>
                {/* {loggedInUser && loggedInConditionsCheck && isConfirmed && ( */}
                <Card className={``}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={getStepWithOffset('1', GUEST_CHECKOUT)}
                    onClick={(e) => {
                      if (bookingSuccessful === true) {
                        e.preventDefault();
                        e.stopPropagation();
                        return false;
                      }
                      loggedInUser && isMobile ? toggleHandle(2) : toggleHandle(1);
                    }}
                    className={`${
                      (
                        isMobile
                          ? open[getStepWithOffset(2, GUEST_CHECKOUT)]
                          : open[getStepWithOffset(1, GUEST_CHECKOUT)]
                      )
                        ? 'active'
                        : ''
                    } ${
                      !(loggedInUser && loggedInConditionsCheck && isConfirmed) &&
                      'inactive'
                    } ${category_id == '1' && 'goldendrop'}`}
                  >
                    {/* <div className="number-circle">{
                      getStepWithOffset(isMobile ? "3" : "2", GUEST_CHECKOUT)
                    }</div> */}
                    <article>
                      <h2>
                        <span>
                          {getStepWithOffset(isMobile ? '3' : '2', GUEST_CHECKOUT)}
                          .&nbsp;
                        </span>
                        {t('Choose Payment Mode')}
                      </h2>
                    </article>
                  </Accordion.Toggle>
                  {/* {defaultTab == "1" ? ( */}
                  <Accordion.Collapse
                    eventKey={getStepWithOffset(
                      isMobile ? '2' : '1',
                      GUEST_CHECKOUT,
                    )}
                    id="collapse-padding"
                  >
                    <div className="booking_accordion_step">
                      <PaymentSelectMode
                        email={email}
                        category_id={category_id}
                        toggleHandle={toggleHandle}
                        onConfirmPayment={onConfirmPayment}
                        t={t}
                        mobile={mobile}
                        countryMobCode={countryMobCode}
                      />
                    </div>
                    {/* <>
                    <PaymentCardDetails />
                  </> */}
                  </Accordion.Collapse>
                  {/* ) : null} */}
                </Card>
                {/* // )} */}
                {/* {loggedInUser &&
                  isConfirmed &&
                  bookingSuccessful === true &&
                  loggedInConditionsCheck && ( */}
                <Card className={` booking-success-accordian `}>
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={getStepWithOffset('2', GUEST_CHECKOUT)}
                    // onClick={() => {
                    //   isMobile ? toggleHandle(3) : toggleHandle(2);
                    // }}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className={`${
                      (
                        isMobile
                          ? open[getStepWithOffset(3, GUEST_CHECKOUT)]
                          : open[getStepWithOffset(2, GUEST_CHECKOUT)]
                      )
                        ? 'active'
                        : ''
                    } 
                    ${
                      !(
                        loggedInUser &&
                        isConfirmed &&
                        bookingSuccessful === true &&
                        loggedInConditionsCheck
                      ) && 'inactive'
                    }
                    ${category_id == '1' && 'goldendrop'} `}
                  >
                    {/* <div className="number-circle">{
                      getStepWithOffset(isMobile ? "4" : "3", GUEST_CHECKOUT)
                    }</div> */}
                    <article>
                      <h2>
                        <span>
                          {getStepWithOffset(isMobile ? '4' : '3', GUEST_CHECKOUT)}
                          .&nbsp;
                        </span>
                        {t('Confirmation')}
                      </h2>
                    </article>
                  </Accordion.Toggle>
                  {/* {defaultTab == "2" ? ( */}
                  <Accordion.Collapse
                    eventKey={getStepWithOffset(
                      isMobile ? '3' : '2',
                      GUEST_CHECKOUT,
                    )}
                    id="collapse-padding"
                  >
                    <div className="booking_accordion_step">
                      {/* <PaymentConfirmMessage t={t} /> */}
                      <div className="col-md-10 col-lg-9 col-xl-7 mx-auto text-center payment-success">
                        {/* <div> */}
                        {/* <h4 className="font-weight-bold">Pay ₹{totalAmount}</h4> */}
                        {/* <p className="grey-font">Transaction #1234567889</p> */}
                        {/* </div> */}
                        <div className="">
                          <img
                            src={paymentSuccessIcon}
                            alt="Payment Success"
                            className=""
                          />
                        </div>
                        <div className="pt-2">
                          <h2
                            className="font-weight-bold"
                            style={{ color: '#fff' }}
                          >
                            {t('Enjoy your movie!')}
                          </h2>
                          <p className="" style={{ color: '#fff' }}>
                            {t(
                              'Your transaction was successfully processed, check your email for your tickets',
                            )}
                          </p>
                        </div>
                        <div className="mb-0">
                          <button
                            className={`payment-submit-btn ${
                              category_id == 1 && 'golden_btn_pvt'
                            }`}
                            onClick={() => {
                              // console.log({ isUnpaidBooking });
                              if (isUnpaidBooking)
                                history.push(
                                  `/unpaid-ticket/${reservation_id}/${category_id}`,
                                );
                              else
                                history.replace(
                                  `/final-ticket/${showBookingID}/${category_id}`,
                                );
                            }}
                          >
                            {t('Close and collect ticket')}
                          </button>
                        </div>
                      </div>
                    </div>
                  </Accordion.Collapse>
                  {/* ) : null} */}
                </Card>
                {/* )} */}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      {/* <MovieFooter
        movie_title={scheduleDetails?.movie_title}
        image={scheduleDetails?.md_thumbnail_url}
        lang={scheduleDetails?.lang_name}
        format={scheduleDetails?.mf_name}
        rating={scheduleDetails?.rating}
        genre={scheduleDetails?.g_name}
        cinemaName={scheduleDetails?.cine_name}
        cine_location={scheduleDetails?.cine_location}
        cine_address={scheduleDetails?.cine_address}
        date={scheduleDetails?.ss_actual_start_date_time}
        time={scheduleDetails?.ss_actual_start_date_time}
        totalAmount={totalAmount}
        curr_code={curr_code}
        screeningType={
          booking_type == 1
            ? "Confirmed Screening"
            : booking_type == 2
            ? "Crowdsourced Screening"
            : booking_type == 3
            ? "Private Screening"
            : null
        }
        showType={
          show?.showType == 2
            ? "Unconfirmed"
            : show?.showType == 1
            ? "Confirmed"
            : null
        }
        category_id={category_id}
        booking_type={booking_type}
      /> */}
    </>
  );
};
// const stripePublisherKey = useSelector(
//   (state) => state.movies.stripePublisherKey
// );

// Start  Payment Select Mode
const PaymentSelectMode = ({
  email,
  category_id,
  toggleHandle,
  onConfirmPayment,
  t,
  mobile,
  countryMobCode,
}) => {
  const history = useHistory();
  const stripe = useStripe();
  const elements = useElements();
  const stripeKey = useSelector((state) => state.movies.stripePublisherKey);
  const currency = useSelector((state) => state.movies.currency);
  const cardInfo = useSelector((state) => state.movies.cardInfo);
  const {
    is_loading,
    bank_card_number,
    bank_card_number_with_space,
    bank_validate_msg,
    is_valid,
    bank = null,
  } = cardInfo;
  const { curr_code, curr_id } = currency;
  // const reservation_id = useSelector((state) => state.seatLayout.reservation_id);
  const { reservation_id } = useParams();
  const loginDetails = useSelector((state) => state.auth);
  const { loggedInUser, userHistory, registerError, guestUser } = loginDetails;
  const isSaferPayActive = useSelector((state) => state.movies.isSaferPayActive);
  const isSaferPayButtonDisable = useSelector(
    (state) => state.movies.isSaferPayButtonDisable,
  );
  const userHistoryLoading = useSelector((state) => state.auth.userHistoryLoading);
  const userLoyaltyHistory = useSelector((state) => state.auth.userLoyaltyHistory);
  const userEwalletHistory = useSelector((state) => state.auth.userEwalletHistory);
  const userLoyaltyPoints =
    Number(userLoyaltyHistory?.loyalty?.[0]?.points ?? 0) ?? 0;
  const userEwalletBalance = Number(
    userEwalletHistory?.walletBalance?.[0]?.wallet_balance ?? 0,
  );

  const [activeTab, setActiveTab] = useState('promotion');
  const [promoCode, setPromoCode] = useState(null);
  const [promoCodeError, setPromoCodeError] = useState(null);
  const [showStripe, setShowStripe] = useState(false);
  const [is100PercentOff, setIs100PercentOff] = useState(false);
  const [isFreeRateCard, setIsFreeRateCard] = useState(false);
  const [SelectedOffer, setSelectedOffer] = useState(null);
  const [isOfferApplied, setOfferAppliedFlag] = useState(false);
  const [cardPaymentModes, setCardPaymentModes] = useState([]);
  const appliedVoucher = useSelector((state) => state.promotions.applied_voucher);
  const fnbPrice = useSelector((state) => state.fnb.fnb_price);
  const discount = useSelector((state) => state.promotions.voucher_discount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);
  const reservationDetails = useSelector(
    (state) => state.movies?.reservationDetails,
  );
  const [activePromotion, setActivePromotion] = useState(null);
  const add_ons_array = useSelector(
    (state) => state?.movies?.reservationDetails?.add_ons_array,
  );

  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_name, iso_2, lang_id } = selected_language;
  const isMobile = useIsMobile();
  const isDeskTop = useIsDesktop();
  const isTablet = useIsTablet();
  const [activeGroup, setActiveGroup] = useState(null);

  const promotions = useSelector((s) => s.promotions.availablePromotionsOffers);
  const Grouppromotions = useSelector(
    (s) => s.promotions.availablePromotionsGroupOffers,
  );
  // console.log('Grouppromotions', Grouppromotions);
  // console.log('promotions:', promotions?.offerGroup?.length > 0);
  const promotionsLoading = useSelector(
    (s) => s.promotions.availablePromotionsOffersLoading,
  );

  const [expandedGroup, setExpandedGroup] = useState(null);

  const groupedPromotions = {};
  Grouppromotions.forEach((promotion) => {
    if (!groupedPromotions[promotion.ogt_id]) {
      groupedPromotions[promotion.ogt_id] = {
        groupName: promotion.ogt_type_name,
        promotions: [],
      };
    }
  });

  promotions.forEach((promotion) => {
    if (groupedPromotions[promotion.offer_group_type_id]) {
      groupedPromotions[promotion.offer_group_type_id].promotions.push(promotion);
    }
  });

  const handleOfferClick = (promotionId, groupId) => {
    if (activePromotion === promotionId && activeGroup === groupId) {
      setActivePromotion(null);
    } else {
      setActivePromotion(promotionId);
      setActiveGroup(groupId);
    }
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleAccordionToggle = (groupId) => {
    setActiveGroup(activeGroup === groupId ? null : groupId);
  };

  const [showDescription, setShowDescription] = useState(false);
  const [hoveredPromotion, setHoveredPromotion] = useState(null);
  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [selectedPromotionLoading, setSelectedPromotionLoading] = useState(false);
  const [selectedLoyaltyLoading, setSelectedLoyaltyLoading] = useState(false);
  const [selectedEwalletLoading, setSelectedEwalletLoading] = useState(false);

  const appliedPromotion = useSelector((s) => s.promotions.appliedPromotion);
  const appliedLoyalty = useSelector((s) => s.promotions.appliedLoyalty);
  const appliedEwallet = useSelector((s) => s.promotions.appliedEwallet);
  let totalOfferPrice = appliedPromotion?.BoxOffice?.TotalOfferPrice || 0;

  const booking_type_id = reservationDetails?.booking_type_id ?? null;

  const [loading, setLoading] = useState(false);
  const [loadingOfferpayment, setOfferpaymentLoading] = useState(false);
  const [SaferPayloading, setSaferPayLoading] = useState(false);
  const [ccavenueloading, setCCAvenueLoading] = useState(false);
  const [bankOfferLoading, setBankLoading] = useState(false);
  const [cardInfoTemp, setCardInfoTemp] = useState({
    bank_card_number: '',
    bank_card_number_with_space: '',
    bank_validate_msg: '',
    is_loading: false,
    is_valid: false,
    bank: null,
  });
  const [secureAcceptancePaymentLoading, setSecureAcceptancePaymentLoading] =
    useState(false);
  const [UnpaidBookingLoading, setUnpaidBookingLoading] = useState(false);

  const [stripeError, setStripeError] = useState(null);
  const dispatch = useDispatch();
  const timerCompleted = useSelector((state) => state.movies.timerCompleted);
  let isPayBtnClick = useRef(false);

  const bookSeatError = useSelector((state) => state.movies.bookSeatError);
  const activePaymentModes = useSelector(
    (state) => state.movies.activePaymentModes,
  );

  let isEwalletPaymnetEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 29)?.length > 0 ?? false;

  let isLoyaltyPaymentModeEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 10)?.length > 0 ?? false;

  let isPromotionsOffersPaymentModeEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 12)?.length > 0 ?? false;
  // console.log(
  //   isPromotionsOffersPaymentModeEnabled,
  //   'isPromotionsOffersPaymentModeEnabled',
  // );
  let isVouchersPaymentModeEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 13)?.length > 0 ?? false;

  let isStripePaymentModeEnabled = false;

  let isUnpaidBookingEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 17)?.length > 0 ?? false;

  let isCashCardPaymentEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 11)?.length > 0 ?? false;

  let isSecureAcceptancePaymentEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 32)?.length > 0 ?? false;

  let isCCAvenueEnabled =
    activePaymentModes?.filter((p) => p?.pm_id == 18)?.length > 0 ?? false;

  let ActivePaymentModeAvailable =
    activePaymentModes?.filter(
      (p) =>
        p?.pm_id == 32 ||
        p?.pm_id == 19 ||
        p?.pm_id == 18 ||
        p?.pm_id == 17 ||
        p?.pm_id == 11 ||
        p?.pm_id == 43 ||
        p?.pm_id == 44 ||
        p?.pm_id == 45,
    )?.length > 0 ?? false;

  const [showCashCardSection, setShowCashCardSection] = useState(false);
  const [hidePaymentOptions, sethidePaymentOptions] = useState(false);
  const [validGiftCardCredentials, setValidGiftCardCredentials] = useState(false);
  const [appliedRedemption, setRedemptionAmount] = useState('');
  const [selectedEwallet, setSelectedEwallet] = useState(0);

  const [firstCashCard, setFirstCashCard] = useState({});
  const [fullCashCardaymentLoading, setfullCashCardaymentLoading] = useState(false);

  let [submittedCashCardDetails, setSubmittedCashCardDetails] = useState(null);
  let [showRedeemTab, setShowRedeemTab] = useState(true);

  let [card, setCard] = useState('');
  let [cardError, setCardError] = useState(false);

  let [pin, setPin] = useState('');
  let [pinError, setPinError] = useState(false);
  let [errorMsg, setErrorMsg] = useState('');

  const is_internet_handling_enable = useSelector(
    (state) => state?.seatLayout?.is_internet_handling_enable,
  );

  const cashCardDetails = useSelector((state) => state.cashCard.cashCardDetails);
  const cashCardDetailsLoading = useSelector(
    (state) => state.cashCard.cashCardDetailsLoading,
  );
  const cashCardTopUpSelection = useSelector(
    (state) => state.cashCard.cashCardTopUpSelection,
  );
  const cashCardRedemptionSelection = useSelector(
    (state) => state.cashCard.cashCardRedemptionSelection,
  );

  const [redemptionAmountEqGrandTotal, setRedemptionAmountEqGrandTotal] =
    useState(false);
  const [showCashCardPin, setshowCashCardPin] = useState(false);
  const [showCashCardPinScanner, setshowCashCardPinScanner] = useState(false);

  useEffect(() => {
    if (SelectedOffer) {
      reserveSelectedOfferPromotion(
        SelectedOffer,
        reservation_id,
        reservationDetails,
      );
      setSelectedOffer(null);
      setCardInfoTemp({
        ...cardInfoTemp,
        bank_validate_msg: bank_validate_msg,
        is_valid: is_valid,
        bank: bank,
      });
    }
  }, [is_valid]);

  useEffect(() => {
    let filterCardPaymentModes = [];
    filterCardPaymentModes = activePaymentModes?.filter((pm) =>
      ['43', '44', '45'].includes(String(pm.pm_id)),
    );
    // console.log('filterCardPaymentModes', filterCardPaymentModes);
    filterCardPaymentModes = filterCardPaymentModes.map((_pm) => {
      if (_pm['pm_id'] == 43) {
        let tempObj = {
          card_image: masterCardLogo,
          name_on_btn: 'Credit Card',
          sub_name_2: '',
          img_height: null,
          img_width: null,
          order: 1,
          bank: null,
        };
        _pm = { ..._pm, ...tempObj };
      } else if (_pm['pm_id'] == 44) {
        let tempObj = {
          card_image: masterCardStandardCharteredLogo,
          name_on_btn: 'SCB CREDIT CARD',
          sub_name_2: 'Standard Chartered Bank 50% Discount offer',
          img_height: 40,
          img_width: 30,
          order: 3,
          bank: 'SCB BANK',
        };
        _pm = { ..._pm, ...tempObj };
      } else if (_pm['pm_id'] == 45) {
        let tempObj = {
          card_image: benefitLogo,
          name_on_btn: 'Debit Card',
          sub_name_2: '',
          img_height: null,
          img_width: null,
          order: 2,
          bank: null,
        };
        _pm = { ..._pm, ...tempObj };
      }
      return _pm;
    });
    filterCardPaymentModes = filterCardPaymentModes.sort(
      (a, b) => parseFloat(a.order) - parseFloat(b.order),
    );
    filterCardPaymentModes = filterCardPaymentModes.filter((_pm) =>
      bank &&
      selectedPromotion?.oc_id &&
      isOfferApplied &&
      is_valid &&
      bank?.toLowerCase()?.includes('scb')
        ? _pm?.pm_id == 44
        : _pm?.bank == null,
    );
    setCardPaymentModes(filterCardPaymentModes);
  }, [activePaymentModes, bank, selectedPromotion, isOfferApplied]);

  const MakeUnpaidBooking = () => {
    // console.log({ booking_type_id });
    setUnpaidBookingLoading(true);
    dispatch(
      unpaidBooking({
        reservation_id,
        cust_id: loggedInUser?.cust_id,
        cinema_id: scheduleDetails.cinema_id,
        category_id,
        callback: setUnpaidBookingLoading,
        secondCallback: toggleHandle,
        booking_type_id,
      }),
    );
    // history.push(`/unpaid-ticket/${reservation_id}/${category_id}`);
  };
  const MakeSaferPayPayment = () => {
    setSaferPayLoading(true);
    let payload = {
      reservation_id,
      cust_id: loggedInUser?.cust_id ?? 0,
      guest_user_id: guestUser?.guest_user_id ?? 0,
      email,
      cinema_id: scheduleDetails.cinema_id,
      category_id,
      callback: setSaferPayLoading,
      secondCallback: toggleHandle,
      cinema_name: scheduleDetails.cine_name,
      mobile,
      countryMobCode,
    };
    let appliedCashCard = {
      usedCashCards: [],
      isCashCardActive: true,
      CashCardDetails: {
        cash_card_number: firstCashCard?.cash_card_number,
        customer_email: firstCashCard?.cust_email,
        isStreetSale: firstCashCard?.is_street_sale,
        type: '2',
        reason: 'Deduction from website',
        amount: cashCardRedemptionSelection?.amount,
        cardAmount: cashCardRedemptionSelection?.amount,
      },
      isCashCardTransaction: true,
    };
    if (cashCardRedemptionSelection?.amount)
      payload.appliedCashCard = appliedCashCard;
    setShowStripe(false);
    dispatch(makeSaferPayPayment(payload));
  };
  const MakeOfferPayment = () => {
    setOfferpaymentLoading(true);
    // console.log('Offer payment');

    dispatch(
      makeOfferPaymentBooking({
        reservation_id,
        cust_id: loggedInUser?.cust_id,
        email,
        cinema_id: scheduleDetails.cinema_id,
        category_id,
        callback: setOfferpaymentLoading,
        secondCallback: toggleHandle,
        cinema_name: scheduleDetails.cine_name,
        mobile,
        countryMobCode,
        payment_mode_id: 13, // payment_mode_id = 13 = Vouchers applied
        booking_type_id,
      }),
    );
  };
  const MakeFreeTicketBooking = () => {
    setOfferpaymentLoading(true);

    dispatch(
      makeFreeTicketBooking({
        reservation_id,
        cust_id: loggedInUser?.cust_id ?? 0,
        guest_id: guestUser?.guest_id ?? 0,

        callback: setOfferpaymentLoading,
        secondCallback: toggleHandle,
      }),
    );
  };
  const MakeFreeRateCardPayment = () => {
    setOfferpaymentLoading(true);
    // console.log('free rate payment');

    dispatch(
      makeFreeRateCardPaymentBooking({
        reservation_id,
        cust_id: loggedInUser?.cust_id,
        email,
        cinema_id: scheduleDetails.cinema_id,
        category_id,
        callback: setOfferpaymentLoading,
        secondCallback: toggleHandle,
        cinema_name: scheduleDetails.cine_name,
        mobile,
        countryMobCode,
        payment_mode_id: 19, // payment_mode_id = 19 = Free rate card
        booking_type_id,
      }),
    );
  };
  const MakeSecureAcceptancePayment = () => {
    setSecureAcceptancePaymentLoading(true);

    let payload = {
      reservation_id,
      cust_id: loggedInUser?.cust_id ?? 0,
      guest_user_id: guestUser?.guest_user_id ?? 0,
      email,
      cinema_id: scheduleDetails.cinema_id,
      category_id,
      callback: setSaferPayLoading,
      secondCallback: toggleHandle,
      cinema_name: scheduleDetails.cine_name,
      mobile,
      countryMobCode,
    };
    let appliedCashCard = {
      usedCashCards: [],
      isCashCardActive: true,
      CashCardDetails: {
        cash_card_number: firstCashCard?.cash_card_number,
        customer_email: firstCashCard?.cust_email,
        isStreetSale: firstCashCard?.is_street_sale,
        type: '2',
        reason: 'Deduction from website',
        amount: cashCardRedemptionSelection?.amount,
        cardAmount: cashCardRedemptionSelection?.amount,
      },
      isCashCardTransaction: true,
    };
    if (cashCardRedemptionSelection?.amount)
      payload.appliedCashCard = appliedCashCard;
    setShowStripe(false);
    dispatch(makeSecureAcceptancePayment(payload));
  };
  const MakeCCAvenuePayment = () => {
    setCCAvenueLoading(true);

    let payload = {
      reservation_id,
      cust_id: loggedInUser?.cust_id ?? 0,
      guest_user_id: guestUser?.guest_user_id ?? 0,
      email,
      cinema_id: scheduleDetails.cinema_id,
      category_id,
      callback: setCCAvenueLoading,
      secondCallback: toggleHandle,
      cinema_name: scheduleDetails.cine_name,
      mobile,
      countryMobCode,
    };
    let appliedCashCard = {
      usedCashCards: [],
      isCashCardActive: true,
      CashCardDetails: {
        cash_card_number: firstCashCard?.cash_card_number,
        customer_email: firstCashCard?.cust_email,
        isStreetSale: firstCashCard?.is_street_sale,
        type: '2',
        reason: 'Deduction from website',
        amount: cashCardRedemptionSelection?.amount,
        cardAmount: cashCardRedemptionSelection?.amount,
      },
      isCashCardTransaction: true,
    };
    if (cashCardRedemptionSelection?.amount)
      payload.appliedCashCard = appliedCashCard;
    setShowStripe(false);
    dispatch(makeCCAvenuePayment(payload));
  };

  const enterCardNumber = (event) => {
    setCardInfoTemp({
      ...cardInfoTemp,
      bank_card_number: event['target']['value'] || '',
      bank_card_number_with_space: event['target']['value'] || '',
    });
    console.log('cardInfoTemp', cardInfoTemp);
  };

  const validateBankOffer = (offer) => {
    // console.log('offer', offer);
    if (!cardInfoTemp?.bank_card_number) {
    } else {
      setCardInfoTemp({
        ...cardInfoTemp,
        is_loading: true,
      });
      setBankLoading(true);
      setSelectedOffer(offer);

      let payload = {
        reservation_id,
        card_number: cardInfoTemp?.bank_card_number ?? null,
        offer_id: offer?.os_id ?? null,
        callback: setBankLoading,
        cardInfo: cardInfoTemp,
      };
      dispatch(validateBankOfferAction(payload));
      console.log('cardInfoTemp', cardInfoTemp);
    }
  };

  const MakeMasterCardAsfPayment = (pmId) => {
    setCCAvenueLoading(true);

    let payload = {
      reservation_id,
      cust_id: loggedInUser?.cust_id ?? 0,
      guest_user_id: guestUser?.guest_user_id ?? 0,
      email,
      cinema_id: scheduleDetails.cinema_id,
      category_id,
      callback: setCCAvenueLoading,
      secondCallback: toggleHandle,
      cinema_name: scheduleDetails.cine_name,
      mobile,
      countryMobCode,
      payment_mode_id: pmId || 43,
    };
    let appliedCashCard = {
      usedCashCards: [],
      isCashCardActive: true,
      CashCardDetails: {
        cash_card_number: firstCashCard?.cash_card_number,
        customer_email: firstCashCard?.cust_email,
        isStreetSale: firstCashCard?.is_street_sale,
        type: '2',
        reason: 'Deduction from website',
        amount: cashCardRedemptionSelection?.amount,
        cardAmount: cashCardRedemptionSelection?.amount,
      },
      isCashCardTransaction: true,
    };
    if (cashCardRedemptionSelection?.amount)
      payload.appliedCashCard = appliedCashCard;
    setShowStripe(false);
    dispatch(makeMasterCardPayment(payload));
  };

  const stripeHandleSubmit = async (event) => {
    event.preventDefault();

    if (isPayBtnClick.current === true) {
      return;
    }

    // await stripe.create;
    isPayBtnClick.current = true;
    const response = await stripe.createToken(elements.getElement(CardElement), {
      currency: curr_code,
      account_holder_name: loggedInUser?.first_name,
      account_holder_type: 'individual',
    });
    // console.log('response :>> ', response);

    if (response.token && !response.token.used) {
      setStripeError(null);
      setLoading(true);
      // console.log(`reservation_id`, reservation_id);
      let voucher = {
        cinema_id: scheduleDetails.cinema_id,
        cinema_name: scheduleDetails.cine_name,
        isTicket: true,
        md_id: scheduleDetails.md_id,
        net_amount: reservationDetails?.net_amount,
        total_amount:
          reservationDetails?.amount + getRateCardAddonsTotal(add_ons_array),
        total_ticket: scheduleDetails?.selectedSeats?.length ?? 0,
        voucher_title: promoCode,
        voucher_code: promoCode,
      };

      if (reservationDetails?.fnb_data?.selected_fnb_items?.length) {
        voucher.isComboApplied = true;

        voucher.fnb_total_amount =
          reservationDetails?.fnb_data?.fnb_price.total_price;

        voucher.fnb_total_item =
          reservationDetails?.fnb_data?.selected_fnb_items?.length;

        voucher.fnb_net_amount =
          reservationDetails?.fnb_data?.fnb_price.total_price -
          reservationDetails?.fnb_data?.fnb_price.total_tax;
      }
      if (
        reservationDetails?.private_rate_card_detail?.[0]?.fnb.length > 0 ||
        reservationDetails?.private_rate_card_detail?.[0]?.addon.length > 0
      ) {
        voucher.isComboApplied = true;
        voucher.fnb_total_item = 0;
        voucher.fnb_total_amount = 0;
        voucher.fnb_net_amount = 0;
        voucher.total_ticket =
          reservationDetails?.private_rate_card_detail[0].customSeatCount;

        if (reservationDetails?.private_rate_card_detail[0]?.fnb.length > 0) {
          voucher.fnb_total_item += 1;
          voucher.fnb_total_amount +=
            reservationDetails?.private_rate_card_detail[0].customSeatCount *
            reservationDetails?.private_rate_card_detail[0]?.fnb[0].price;
          voucher.fnb_net_amount +=
            reservationDetails?.private_rate_card_detail[0].customSeatCount *
            reservationDetails?.private_rate_card_detail[0]?.fnb[0].net_price;
        }
        if (reservationDetails?.private_rate_card_detail[0]?.addon.length > 0) {
          voucher.fnb_total_item += 1;
          voucher.fnb_total_amount +=
            reservationDetails?.private_rate_card_detail[0]?.addon[0].quantity *
            reservationDetails?.private_rate_card_detail[0]?.addon[0].price;
          voucher.fnb_net_amount +=
            reservationDetails?.private_rate_card_detail[0]?.addon[0].quantity *
            reservationDetails?.private_rate_card_detail[0]?.addon[0].net_price;
        }

        voucher.total_amount = voucher.total_amount - voucher.fnb_total_amount;
        voucher.net_amount = voucher.net_amount - voucher.fnb_net_amount;
      }

      // console.log({ voucher });

      let appliedCashCard = {
        usedCashCards: [],
        isCashCardActive: true,
        CashCardDetails: {
          cash_card_number: firstCashCard?.cash_card_number,
          customer_email: firstCashCard?.cust_email,
          isStreetSale: firstCashCard?.is_street_sale,
          type: '2',
          reason: 'Deduction from website',
          amount: cashCardRedemptionSelection?.amount,
          cardAmount: cashCardRedemptionSelection?.amount,
        },
        isCashCardTransaction: true,
      };
      if (timerCompleted === false) {
        dispatch(
          category_id == 1
            ? onSuccessStripeToken({
                reservation_id,
                token: response.token,
                cust_id: loggedInUser?.cust_id,
                email,
                cinema_id: scheduleDetails.cinema_id,
                category_id,
                callback: setLoading,
                secondCallback: toggleHandle,
                cinema_name: scheduleDetails.cine_name,
                mobile,
                countryMobCode,
                appliedVoucher: !appliedVoucher ? null : voucher,
                appliedCashCard: !!cashCardRedemptionSelection?.amount
                  ? appliedCashCard
                  : {},
              })
            : onSuccessStripeTokenBrij({
                reservation_id,
                token: response.token,
                cust_id: loggedInUser?.cust_id,
                email,
                cinema_id: scheduleDetails.cinema_id,
                category_id,
                callback: setLoading,
                secondCallback: toggleHandle,
                cinema_name: scheduleDetails.cine_name,
                mobile,
                countryMobCode,
                appliedVoucher: !appliedVoucher ? null : voucher,
                appliedCashCard: !!cashCardRedemptionSelection?.amount
                  ? appliedCashCard
                  : {},
              }),
        );
      }

      // onConfirmPayment({stripe:true,})
    } else {
      isPayBtnClick.current = false;
      setStripeError(response.error.message);
      return;
    }
  };

  const onRemoveVoucher = () => {
    setIs100PercentOff(false);
    dispatch(clearSelectedVoucher());
  };
  const submitCashCardDetails = () => {
    setErrorMsg('');
    setCardError(false);
    setPinError(false);
    setSubmittedCashCardDetails(true);

    let count = 0;
    if (!card) {
      ++count;
      setCardError(true);
    }
    if (!pin) {
      ++count;
      setPinError(true);
    }

    if (count > 0) {
      // console.log({ pin, card });
      return;
    }

    dispatch(
      fetchCashCardDetailsFromCardNumberPin({
        cash_card_number: card,
        cash_pin: pin,
      }),
    )
      .then(({ status, message }) => {
        if (!!status) {
          // history.push(`/cash-card-topup/${card}`);
          setTimeout(() => {
            setValidGiftCardCredentials(true);
          }, 100);
        } else {
          if (message != 'Incorrect Password.') {
            setCardError(true);
          } else {
            setPinError(true);
          }
          setErrorMsg(message);
        }
      })
      .catch(console.log)
      .finally(() => {});
  };
  const setRedemptionValue = (amount) => {
    setRedemptionAmount(amount);
    // dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: amount })
  };
  const fullCashCardpayment = () => {
    setfullCashCardaymentLoading(true);

    let appliedCashCard = {
      usedCashCards: [],
      isCashCardActive: true,
      CashCardDetails: {
        cash_card_number: firstCashCard?.cash_card_number,
        customer_email: firstCashCard?.cust_email,
        isStreetSale: firstCashCard?.is_street_sale,
        type: '2',
        reason: 'Deduction from website',
        amount: cashCardRedemptionSelection?.amount,
        cardAmount: cashCardRedemptionSelection?.amount,
      },
      isCashCardTransaction: true,
      payment_mode: {
        bs_id: 1,
        bs_source: 'Box Office',
        bs_is_active: 'Y',
        created_by: null,
        created_at: null,
        updated_by: 1,
        updated_at: '2022-01-05T15:10:00.000Z',
        bspm_id: 11,
        booking_source_id: 1,
        payment_mode_id: 11,
        mapping_is_active: 'Y',
        pm_id: 11,
        pm_payment_mode: 'Cash Card',
        pm_icon_url: null,
        pm_is_active: 'Y',
        amount: 0,
      },
    };

    dispatch(
      makeFullCashCardpayment({
        reservation_id,
        cust_id: loggedInUser?.cust_id,
        email,
        cinema_id: scheduleDetails.cinema_id,
        category_id,
        callback: setfullCashCardaymentLoading,
        secondCallback: toggleHandle,
        cinema_name: scheduleDetails.cine_name,
        mobile,
        countryMobCode,
        payment_mode_id: 11, // payment_mode_id = 13 = CashCard
        booking_type_id,
        appliedCashCard,
      }),
    );
  };
  const onRemoveCashCardRedeem = () => {
    setRedemptionValue('');
    dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' });
    setValidGiftCardCredentials(false);
    setShowRedeemTab(true);
  };
  const onApplyCashCardRedeem = () => {
    onRemoveVoucher();
    setSelectedEwallet(0);
    setShowRedeemTab(false);
    dispatch({
      type: 'SET_CASHCARD_REDEMPTION_AMOUNT',
      payload: appliedRedemption,
    });
  };
  const onApplyPromoCode = () => {
    setSelectedPromotion(null);
    dispatch({ type: 'CLEAR_APPLIED_PROMOTION' });
    dispatch({ type: 'CLEAR_APPLIED_LOYALTY' });
    dispatch({ type: 'CLEAR_APPLIED_EWALLET' });
    setRedemptionValue('');
    dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' });
    setValidGiftCardCredentials(false);
    // setShowCashCardSection(false)

    setIs100PercentOff(false);

    if (!promoCode || promoCode.length === 0) {
      setPromoCodeError('Invalid Promo Code');
      // toast.dismiss();
      // toast.error("Please enter a promo code");
      return;
    }
    setPromoCodeError(null);

    let voucher = {
      cinema_id: scheduleDetails.cinema_id,
      cinema_name: scheduleDetails.cine_name,
      isTicket: true,
      md_id: scheduleDetails.md_id,
      net_amount: reservationDetails?.net_amount,
      total_amount:
        reservationDetails?.amount + getRateCardAddonsTotal(add_ons_array),
      total_ticket: scheduleDetails?.selectedSeats?.length,
      voucher_title: promoCode,
      voucher_code: promoCode,
      reservation_id: reservation_id,
    };

    if (reservationDetails?.fnb_data?.selected_fnb_items?.length) {
      voucher.isComboApplied = true;

      voucher.fnb_total_amount =
        reservationDetails?.fnb_data?.fnb_price.total_price;

      voucher.fnb_total_item =
        reservationDetails?.fnb_data?.selected_fnb_items?.length;

      voucher.fnb_net_amount =
        reservationDetails?.fnb_data?.fnb_price.total_price -
        reservationDetails?.fnb_data?.fnb_price.total_tax;
    }
    if (
      reservationDetails?.private_rate_card_detail?.[0]?.fnb.length > 0 ||
      reservationDetails?.private_rate_card_detail?.[0]?.addon.length > 0
    ) {
      voucher.isComboApplied = true;
      voucher.fnb_total_item = 0;
      voucher.fnb_total_amount = 0;
      voucher.fnb_net_amount = 0;
      voucher.total_ticket =
        1 ?? reservationDetails?.private_rate_card_detail[0].customSeatCount;

      if (reservationDetails?.private_rate_card_detail[0]?.fnb.length > 0) {
        voucher.fnb_total_item += 1;
        voucher.fnb_total_amount +=
          reservationDetails?.private_rate_card_detail[0].customSeatCount *
          reservationDetails?.private_rate_card_detail[0]?.fnb[0].price;
        voucher.fnb_net_amount +=
          reservationDetails?.private_rate_card_detail[0].customSeatCount *
          reservationDetails?.private_rate_card_detail[0]?.fnb[0].net_price;
      }
      if (reservationDetails?.private_rate_card_detail[0]?.addon.length > 0) {
        voucher.fnb_total_item += 1;
        voucher.fnb_total_amount +=
          reservationDetails?.private_rate_card_detail[0]?.addon[0].quantity *
          reservationDetails?.private_rate_card_detail[0]?.addon[0].price;
        voucher.fnb_net_amount +=
          reservationDetails?.private_rate_card_detail[0]?.addon[0].quantity *
          reservationDetails?.private_rate_card_detail[0]?.addon[0].net_price;
      }

      voucher.total_amount = voucher.total_amount - voucher.fnb_total_amount;
      voucher.net_amount = voucher.net_amount - voucher.fnb_net_amount;
    }

    // console.log({ voucher });

    dispatch(validateVoucherCode(voucher, reservation_id))
      .then((response) => {
        // console.log('response :>> ', response);
        let { is100PercentOff } = response;
        if (
          getInternetHandlingFees(
            category_id,
            is_internet_handling_enable,
            scheduleDetails,
            reservationDetails,
          ) == 0
        ) {
          setIs100PercentOff(is100PercentOff);
        }

        if (response && response.status === false) {
          setPromoCodeError(response.message);
        }
      })
      .catch((err) => {
        setIs100PercentOff(false);
        console.log('error applying voucher', err);
        setPromoCodeError(err.message);
      });
  };
  const reserveSelectedOfferPromotion = (
    promotion,
    reservation_id,
    reservationDetails,
  ) => {
    onRemoveVoucher();
    setRedemptionValue('');
    dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' });
    dispatch({ type: 'CLEAR_APPLIED_LOYALTY' });
    dispatch({ type: 'CLEAR_APPLIED_EWALLET' });
    setSelectedEwallet(0);
    setValidGiftCardCredentials(false);
    setSelectedPromotionLoading(true);
    setOfferAppliedFlag(false);

    if (promotion?.is_bank_offer == 'N') {
      dispatch({ type: 'CLEAR_CARD_INFO' });
      setCardInfoTemp({
        bank_card_number: '',
        bank_card_number_with_space: '',
        bank_validate_msg: '',
        is_loading: false,
        is_valid: false,
        bank: null,
      });
    }

    let promotionPayload = {
      cinema_id: reservationDetails?.cinema_id,
      cinema_name: reservationDetails?.cine_name,
      isTicket: true,
      reservation_id,
      customer_id: loggedInUser?.cust_id ?? '',
      md_id: reservationDetails?.md_id,
      net_amount: reservationDetails?.net_amount,
      total_amount: reservationDetails?.amount, // ||  scheduleData.amount, // + getRateCardAddonsTotal(add_ons_array),
      total_ticket: reservationDetails?.selectedSeats?.length,
      fnb_items: [],
      // voucher_title: promoCodeValue,
      // voucher_code: promoCodeValue,
      oc_id: promotion.details.PromotionOfferTypesRecords[0].oc_id,
    };

    if (reservationDetails?.fnb_data?.selected_fnb_items?.length > 0) {
      promotionPayload.isComboApplied = true;
      promotionPayload.fnb_items =
        reservationDetails.fnb_data.selected_fnb_items || [];
      promotionPayload.fnb_total_amount =
        reservationDetails.fnb_data.fnb_price.total_price;
      promotionPayload.fnb_total_item =
        reservationDetails.fnb_data.selected_fnb_items.length;
      promotionPayload.fnb_net_amount =
        reservationDetails.fnb_data.fnb_price.total_price -
        reservationDetails.fnb_data.fnb_price.total_tax;
    }
    // console.log({
    //   promotion,
    //   reservation_id,
    //   reservationDetails,
    //   promotionPayload,
    // });

    if (
      !selectedPromotion ||
      (selectedPromotion && selectedPromotion.oc_id !== promotion.oc_id)
    ) {
      setSelectedPromotion(promotion);

      dispatch(
        verifyPromotion(
          promotion,
          reservation_id,
          reservationDetails,
          promotionPayload,
        ),
      )
        .then((response) => {
          // console.log('response :>> ', response);
          let { is100PercentOff } = response;
          if (
            getInternetHandlingFees(
              category_id,
              is_internet_handling_enable,
              scheduleDetails,
              reservationDetails,
            ) == 0
          ) {
            setIs100PercentOff(is100PercentOff);
          }

          if (!response?.status) {
            // remove promotion
            setSelectedPromotion(null);
            setOfferAppliedFlag(false);
            dispatch({ type: 'CLEAR_APPLIED_PROMOTION' });
          } else {
            setOfferAppliedFlag(true);
          }
        })
        .finally(() => {
          setSelectedPromotionLoading(false);
        });
    } else {
      // remove promotion
      dispatch(resetVoucherAndOfferInReservation(reservation_id)).then(() => {
        setSelectedPromotion(null);
        dispatch({ type: 'CLEAR_APPLIED_PROMOTION' });
        dispatch({ type: 'CLEAR_CARD_INFO' });
        setCardInfoTemp({
          bank_card_number: '',
          bank_card_number_with_space: '',
          bank_validate_msg: '',
          is_loading: false,
          is_valid: false,
          bank: null,
        });
        setSelectedPromotionLoading(false);
        setOfferAppliedFlag(false);
      });
    }
  };
  const onRemoveLoyaltyPoints = () => {
    dispatch({ type: 'CLEAR_APPLIED_LOYALTY' });
    setIs100PercentOff(false);
  };
  const onApplyLoyaltyPoints = () => {
    onRemoveVoucher();
    setSelectedPromotion(null);
    dispatch({ type: 'CLEAR_APPLIED_PROMOTION' });
    dispatch({ type: 'CLEAR_APPLIED_EWALLET' });
    setRedemptionValue('');
    dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' });
    setValidGiftCardCredentials(false);
    // setShowCashCardSection(false)
    setSelectedEwallet(0);
    setIs100PercentOff(false);

    setSelectedLoyaltyLoading(true);

    let payload = {
      isGroupRateCard: true,
      totalTax: 0,
      cashInput: 0,
      reservation_id,
      customer_id: loggedInUser?.cust_id ?? '',
      fnbItems: [],

      md_id: reservationDetails?.md_id,
      cinema_id: reservationDetails?.cinema_id,
      cinema_name: reservationDetails?.cine_name,
    };

    if (reservationDetails?.fnb_data?.selected_fnb_items?.length > 0) {
      payload.fnbItems = reservationDetails.fnb_data.selected_fnb_items || [];
    }

    if (!payload.customer_id) return;

    dispatch(validateLoyalty(payload, reservation_id))
      .then((data) => {
        if (data?.status) {
          let grandTotal =
            (reservationDetails?.amount || 0) +
            (fnbPrice?.total_price || 0) +
            (getInternetHandlingFees(
              category_id,
              is_internet_handling_enable,
              scheduleDetails,
              reservationDetails,
            ) || 0) +
            getRateCardAddonsTotal(add_ons_array);

          if (grandTotal == data?.LoyaltyAmount) {
            setIs100PercentOff(true);
          }
        } else {
          onRemoveLoyaltyPoints();
        }
      })
      .finally(() => {
        setSelectedLoyaltyLoading(false);
      });
  };
  const onRemoveEwallet = () => {
    dispatch({ type: 'CLEAR_APPLIED_EWALLET' });
    setSelectedEwallet(0);
    setIs100PercentOff(false);
  };
  const onApplyEwallet = (selectedEwallet) => {
    onRemoveVoucher();
    setSelectedPromotion(null);
    dispatch({ type: 'CLEAR_APPLIED_PROMOTION' });
    setRedemptionValue('');
    dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' });
    setValidGiftCardCredentials(false);
    // setShowCashCardSection(false)
    setIs100PercentOff(false);

    if (!selectedEwallet) return;

    setSelectedEwalletLoading(true);

    dispatch(validateEwallet(selectedEwallet, reservation_id))
      .then((data) => {
        if (data?.status) {
          let grandTotal =
            (reservationDetails?.amount || 0) +
            (fnbPrice?.total_price || 0) +
            (getInternetHandlingFees(
              category_id,
              is_internet_handling_enable,
              scheduleDetails,
              reservationDetails,
            ) || 0) +
            getRateCardAddonsTotal(add_ons_array);

          if (grandTotal === selectedEwallet) {
            setIs100PercentOff(true);
          }
        } else {
          onRemoveEwallet();
        }
      })
      .finally(() => {
        setSelectedEwalletLoading(false);
      });
  };

  // useEffect(() => {
  //   if(!!redemptionAmountEqGrandTotal){
  //     setShowStripe(false)
  //   }
  // }, [redemptionAmountEqGrandTotal])
  useEffect(() => {
    if (
      cashCardRedemptionSelection?.amount &&
      cashCardRedemptionSelection?.amount ==
        (reservationDetails?.amount || 0) +
          (fnbPrice?.total_price || 0) -
          (discount ? discount : 0) +
          (getInternetHandlingFees(
            category_id,
            is_internet_handling_enable,
            scheduleDetails,
            reservationDetails,
          ) || 0) +
          getRateCardAddonsTotal(add_ons_array) -
          (totalOfferPrice ? totalOfferPrice : 0)
    ) {
      setRedemptionAmountEqGrandTotal(true);
    } else {
      setRedemptionAmountEqGrandTotal(false);
    }
  }, [
    discount,
    fnbPrice,
    appliedRedemption,
    reservationDetails,
    cashCardRedemptionSelection,
    totalOfferPrice,
  ]);
  useEffect(() => {
    if (cashCardDetails && cashCardDetails?.length)
      setFirstCashCard(cashCardDetails[0]);
  }, [cashCardDetails]);
  useEffect(() => {
    if (cashCardDetails && cashCardDetails?.length) {
      let valueToSet = '';
      let grandTotal =
        (reservationDetails?.amount || 0) +
        (fnbPrice?.total_price || 0) +
        (getInternetHandlingFees(
          category_id,
          is_internet_handling_enable,
          scheduleDetails,
          reservationDetails,
        ) || 0) +
        getRateCardAddonsTotal(add_ons_array);
      let cardValue = cashCardDetails?.[0]?.balance_amount ?? 0;
      if (cardValue < grandTotal) valueToSet = cardValue;
      else valueToSet = grandTotal;

      setRedemptionValue(valueToSet);
    }
  }, [cashCardDetails, reservationDetails, fnbPrice]);
  useEffect(() => {
    let isFreeRateCardPaymentModeEnabled =
      activePaymentModes?.filter((p) => p?.pm_id == 19)?.length > 0 ?? false;
    // console.log({
    //   'reservationDetails?.amount': reservationDetails?.amount,
    //   'fnbPrice.total_price': fnbPrice?.total_price,
    // });

    let IHF =
      getInternetHandlingFees(
        category_id,
        is_internet_handling_enable,
        scheduleDetails,
        reservationDetails,
      ) ?? 0;

    if (
      isFreeRateCardPaymentModeEnabled &&
      reservationDetails?.amount != undefined &&
      reservationDetails?.amount != null &&
      fnbPrice?.total_price != undefined &&
      fnbPrice?.total_price != null &&
      reservationDetails?.amount + fnbPrice?.total_price + IHF == 0
    ) {
      setIsFreeRateCard(true);
      sethidePaymentOptions(true);
    } else {
      setIsFreeRateCard(false);
      sethidePaymentOptions(false);
    }
  }, [
    reservationDetails,
    discount,
    appliedVoucher,
    cashCardRedemptionSelection,
    cashCardDetails,
    cashCardDetailsLoading,
    scheduleDetails,
    fnbPrice,
    activePaymentModes,
    category_id,
    is_internet_handling_enable,
    totalOfferPrice,
  ]);
  useEffect(() => {
    // console.log({ isFreeRateCard });
  }, [isFreeRateCard]);
  useEffect(() => {}, [hidePaymentOptions]);

  useEffect(() => {
    return () => {
      // console.log('PaymentSelectMode unmounted');
      onRemoveVoucher();
      setRedemptionValue('');
      dispatch({ type: 'SET_CASHCARD_REDEMPTION_AMOUNT', payload: '' });
      dispatch({ type: 'CLEAR_APPLIED_PROMOTION' });
      dispatch({ type: 'CLEAR_APPLIED_LOYALTY' });
      dispatch({ type: 'CLEAR_APPLIED_EWALLET' });
      setSelectedEwallet(0);
      setValidGiftCardCredentials(false);

      // remove promotion
      setSelectedPromotion(null);
    };
  }, []);

  return (
    <>
      <div className="d-flex register_tabs mt-4">
        {isVouchersPaymentModeEnabled && false && (
          <div
            className={`w-100 tabs py-2  ${activeTab === 'voucher' && 'active'} ${
              // prettier-ignore
              (appliedPromotion || appliedLoyalty || appliedRedemption || selectedEwallet) && 'disable'
            }`}
            onClick={() => {
              setShowCashCardSection(false);
              sethidePaymentOptions(false);
              // prettier-ignore
              if (appliedPromotion || appliedLoyalty || appliedRedemption || selectedEwallet) return;

              setActiveTab('voucher');
            }}
          >
            <div className="voucher-btn "> {t('Vouchers')}</div>
          </div>
        )}

        {isPromotionsOffersPaymentModeEnabled &&
          !promotionsLoading &&
          promotions?.length > 0 && (
            <div
              className={`w-100 tabs py-2 ${
                activeTab === 'promotion' && 'active'
              } ${
                // prettier-ignore
                (appliedVoucher || appliedLoyalty || appliedRedemption || selectedEwallet) && 'disable'
              }`}
              onClick={() => {
                setShowCashCardSection(false);
                sethidePaymentOptions(false);

                // prettier-ignore
                if (appliedVoucher || appliedLoyalty || appliedRedemption || selectedEwallet) return;

                setActiveTab('promotion');
              }}
            >
              <div className="voucher-btn"> {t('Promotions & Offers')}</div>
            </div>
          )}

        {isCashCardPaymentEnabled && (
          <div
            className={`w-100 tabs py-2 ${activeTab === 'cashcard' && 'active'} ${
              // prettier-ignore
              (appliedVoucher || appliedLoyalty || appliedPromotion || selectedEwallet) && 'disable'
            }`}
            onClick={() => {
              setShowCashCardSection(false);
              sethidePaymentOptions(false);

              // prettier-ignore
              if (appliedVoucher || appliedLoyalty || appliedPromotion || selectedEwallet) return;

              setShowCashCardSection(true);
              setShowStripe(false);
              sethidePaymentOptions(true);

              setActiveTab('cashcard');
            }}
          >
            {t('Apply Cash Card')}
          </div>
        )}

        {isLoyaltyPaymentModeEnabled && userLoyaltyPoints >= 0 && (
          <div
            className={`w-100 tabs py-2 ${activeTab === 'loyalty' && 'active'} ${
              // prettier-ignore
              (appliedVoucher || appliedPromotion || appliedRedemption || selectedEwallet) && 'disable'
            }`}
            onClick={() => {
              setShowCashCardSection(false);
              sethidePaymentOptions(false);

              // prettier-ignore
              if (appliedVoucher || appliedPromotion || appliedRedemption || selectedEwallet) return;

              setActiveTab('loyalty');
            }}
          >
            <div className="voucher-btn "> {t('Loyalty')}</div>
          </div>
        )}

        {isEwalletPaymnetEnabled && userEwalletBalance >= 0 && (
          <div
            className={`w-100 tabs py-2 ${activeTab === 'ewallet' && 'active'} ${
              // prettier-ignore
              (appliedVoucher || appliedPromotion || appliedRedemption || appliedLoyalty) && 'disable'
            }`}
            onClick={() => {
              setShowCashCardSection(false);
              sethidePaymentOptions(false);

              // prettier-ignore
              if (appliedVoucher || appliedPromotion || appliedRedemption || appliedLoyalty) return;

              setActiveTab('ewallet');
            }}
          >
            <div className="voucher-btn">{t('EWallet')}</div>
          </div>
        )}
      </div>

      {!isFreeRateCard &&
        activeTab === 'ewallet' &&
        category_id == 2 &&
        !appliedVoucher &&
        !selectedPromotion &&
        userEwalletBalance >= 0 &&
        isEwalletPaymnetEnabled && (
          <>
            <div
              className={`promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mx-auto my-3 ${
                appliedEwallet
                  ? 'd-flex justify-content-center align-items-center'
                  : ''
              } mx-4`}
            >
              {userHistoryLoading ? (
                <div className="row mt-5">
                  <div className="col-12 text-center">
                    <Spinner />
                  </div>
                </div>
              ) : (
                <>
                  {appliedEwallet ? (
                    <>
                      <p>
                        {t('E-Wallet Applied')}: {appliedEwallet ?? 0}
                      </p>
                      <button
                        onClick={() => {
                          dispatch(
                            resetVoucherAndOfferInReservation(reservation_id),
                          ).then(() => {
                            onRemoveEwallet();
                          });
                        }}
                        className={`promo-btn remove-code ml-2 ${
                          category_id == 1 && 'golden_btn_pvt'
                        }`}
                      >
                        {t('Remove')}
                      </button>
                    </>
                  ) : (
                    <div className="l-border_">
                      <h6
                        className={`mt-3 gift_card_redemption_balance ${
                          category_id == 1 && ' error-golden '
                        } `}
                      >
                        {t('Your E-Wallet balance is')}
                        <p>
                          {curr_code} {currencyFormatter(userEwalletBalance)}
                        </p>
                      </h6>
                      <p>{t('Enter amount to be used')}</p>
                      <input
                        type="text"
                        className="form-control mb-1"
                        placeholder="Enter amount to be reduced"
                        value={selectedEwallet}
                        onChange={(e) => {
                          if (!userEwalletBalance) return;

                          setSelectedEwallet(
                            Number.isNaN(parseInt(e.target.value, 10))
                              ? ''
                              : parseInt(e.target.value, 10),
                          );
                          // if (Number.isNaN(parseInt(e.target.value, 10))) {
                          //   dispatch({
                          //     type: 'SET_EWALLET_AMOUNT',
                          //     payload: '',
                          //   });
                          // }
                        }}
                      />
                      {userEwalletBalance <= 0 && (
                        <p className={`error ${category_id == 1 && 'error'}`}>
                          {' '}
                          {t('No balance')}
                        </p>
                      )}
                      {userEwalletBalance < selectedEwallet && (
                        <p className={`error ${category_id == 1 && ' error '}`}>
                          {' '}
                          {t('Redeem Amount cant be greater than E-Wallet balance')}
                        </p>
                      )}
                      {selectedEwallet >
                        (reservationDetails?.amount || 0) +
                          (fnbPrice?.total_price || 0) -
                          (discount ? discount : 0) +
                          (getInternetHandlingFees(
                            category_id,
                            is_internet_handling_enable,
                            scheduleDetails,
                            reservationDetails,
                          ) || 0) +
                          getRateCardAddonsTotal(add_ons_array) -
                          (totalOfferPrice ? totalOfferPrice : 0) && (
                        <p className={`error ${category_id == 1 && 'error'}`}>
                          {' '}
                          {t('Redeem Amount cant be greater than grand total')}
                        </p>
                      )}

                      <div className="mb-3 gift_card_redemption_btn_group">
                        <button
                          className="btn-main"
                          onClick={() => {
                            if (!userEwalletBalance) return;
                            if (!selectedEwallet) return;

                            if (
                              selectedEwallet >
                              (reservationDetails?.amount || 0) +
                                (fnbPrice?.total_price || 0) -
                                (discount ? discount : 0) +
                                (getInternetHandlingFees(
                                  category_id,
                                  is_internet_handling_enable,
                                  scheduleDetails,
                                  reservationDetails,
                                ) || 0) +
                                getRateCardAddonsTotal(add_ons_array) -
                                (totalOfferPrice ? totalOfferPrice : 0)
                            ) {
                              return;
                            }

                            Promise.resolve().then(() => {
                              onApplyEwallet(selectedEwallet);
                            });
                          }}
                        >
                          {t('Use E-Wallet')}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

      {!isFreeRateCard &&
        activeTab === 'loyalty' &&
        category_id == 2 &&
        !appliedVoucher &&
        !selectedPromotion &&
        userLoyaltyPoints >= 0 &&
        isLoyaltyPaymentModeEnabled && (
          <>
            <div
              className={`promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mx-auto my-3 ${
                appliedLoyalty
                  ? 'd-flex justify-content-center align-items-center'
                  : ''
              } mx-4`}
            >
              {userHistoryLoading ? (
                <div className="row mt-5">
                  <div className="col-12 text-center">
                    <Spinner />
                  </div>
                </div>
              ) : (
                <>
                  {appliedLoyalty ? (
                    <>
                      <p>
                        {t('Loyalty Applied')}: {appliedLoyalty?.BurnPoints ?? 0}
                      </p>
                      <button
                        onClick={() => {
                          dispatch(
                            resetVoucherAndOfferInReservation(reservation_id),
                          ).then(() => {
                            onRemoveLoyaltyPoints();
                          });
                        }}
                        className={`promo-btn remove-code ml-2 ${
                          category_id == 1 && 'golden_btn_pvt'
                        }`}
                      >
                        {t('Remove')}
                      </button>
                    </>
                  ) : (
                    <div className="l-border_">
                      <h6
                        className={`mt-3 gift_card_redemption_balance ${
                          category_id == 1 && ' error-golden '
                        } `}
                      >
                        {t('Your Total Loyalty Points Are')}
                        {/*<p>{currencyFormatter(userLoyaltyPoints)}</p>*/}
                      </h6>
                      {/*<p>{t('Points to be redeemed')}</p>*/}
                      <input
                        type="text"
                        disabled={true}
                        className="form-control mb-1"
                        placeholder="Enter amount to be reduced"
                        value={userLoyaltyPoints}
                      />
                      <div className="mb-3 gift_card_redemption_btn_group">
                        <button
                          className="btn-main"
                          onClick={() => {
                            if (
                              !userLoyaltyPoints ||
                              userLoyaltyPoints == 0 ||
                              !loggedInUser?.cust_id
                            ) {
                              return;
                            }
                            onApplyLoyaltyPoints();
                          }}
                        >
                          {t('Use Points')}
                        </button>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </>
        )}

      {!isFreeRateCard && activeTab === 'promotion' && category_id == 2 && (
        <>
          {!showCashCardSection && isVouchersPaymentModeEnabled && (
            <>
              <div
                className={`promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mt-4 mx-auto ${
                  appliedVoucher
                    ? 'd-flex justify-content-center align-items-center'
                    : ''
                } mx-4`}
              >
                {!appliedVoucher ? (
                  <div>
                    <p>{t('Do you have a voucher or promo code?')}</p>
                    <div className="promo_code_wrapper">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={t('Apply your promo code here')}
                        value={promoCode || ''}
                        onChange={(e) => setPromoCode(e.target.value)}
                      />
                      <button
                        onClick={() => onApplyPromoCode()}
                        className={`btn-main ${
                          category_id == 1 && 'golden_btn_pvt'
                        }`}
                      >
                        {t('Apply')}
                      </button>
                    </div>
                    {promoCodeError ? (
                      <p className="error mt-1"> {t(promoCodeError)}</p>
                    ) : (
                      <p className="error mt-1"> </p>
                    )}
                  </div>
                ) : (
                  <>
                    <p>
                      {t('Voucher Applied')}: {appliedVoucher?.voucher_title}
                    </p>
                    <button
                      onClick={() => {
                        dispatch(
                          resetVoucherAndOfferInReservation(reservation_id),
                        ).then(() => {
                          onRemoveVoucher();
                        });
                      }}
                      className={`promo-btn remove-code ml-2 ${
                        category_id == 1 && 'golden_btn_pvt'
                      }`}
                    >
                      {t('Remove')}
                    </button>
                  </>
                )}
              </div>
            </>
          )}

          {!promotionsLoading &&
            promotions.length > 0 &&
            isPromotionsOffersPaymentModeEnabled && (
              <div className="promo-code-input">
                <div className="col-11 px-0 mx-auto mt-4 pb-3 mx-4">
                  {/* <p>{t('Offers')}</p> */}

                  {/* <article className="payment-methods"> */}
                  {/* {promotions.map((promotion, index) => (
                      <button
                        key={`offer-${index}`}
                        className={`btn-main ${
                          (promotionsLoading ||
                            (selectedPromotionLoading &&
                              selectedPromotion &&
                              selectedPromotion?.oc_id == promotion?.oc_id)) &&
                          'loader-text inactive'
                        } ${
                          selectedPromotion &&
                          selectedPromotion.oc_id == promotion.oc_id
                            ? 'golden_btn_pvt'
                            : ''
                        }`}
                        disabled={promotionsLoading || selectedPromotionLoading}
                        onClick={() => {
                          reserveSelectedOfferPromotion(
                            promotion,
                            reservation_id,
                            reservationDetails,
                          );
                        }}
                        style={{ position: 'relative' }}
                      >
                        {getLangSpecificAttribute(
                          promotion?.details?.OfferContent,
                          lang_id,
                          'offer_title',
                        ) || promotion?.offer_title}
                        <span className="circle left"></span>
                        <span className="circle right"></span>
                      </button>
                    ))} */}
                  {/* <div className="custom-accordion">
                    {Object.keys(groupedPromotions).map((groupId, index) => (
                      <div key={index} className="accordion-group">
                        <div
                          className={`accordion-header ${
                            activeGroup === groupId ? 'active' : ''
                          }`}
                          onClick={() => handleAccordionToggle(groupId)}
                        >
                          <span
                            className="group-name"
                            style={{ width: isMobile ? '50%' : '30%' }}
                          >
                            {groupedPromotions[groupId].groupName}
                          </span>
                          <div
                            className="horizontal-line"
                            style={{ width: '100%', border: '1px solid white' }}
                          ></div>
                          <span className="arrow-icon">
                            {activeGroup === groupId ? '▼' : '>'}
                          </span>
                        </div>
                        <div
                          className={`accordion-content ${
                            activeGroup === groupId ? 'expanded' : ''
                          }`}
                        >
                          <ul className="custom-list">
                            {groupedPromotions[groupId].promotions.map(
                              (promotion, index) => (
                                <li key={index} className="promotion">
                                  <div
                                    className={`promotion-title ${
                                      activePromotion === promotion.oc_id
                                        ? 'active'
                                        : ''
                                    }`}
                                    onClick={() =>
                                      handleOfferClick(promotion.oc_id, groupId)
                                    }
                                  >
                                    <div className="d-flex">
                                      <span className="bullet">-</span>{' '}
                                      {promotion.offer_title}
                                      <div className="horizontal-line hrp1"></div>
                                      <span className="arrow-icon">
                                        {activePromotion === promotion.oc_id &&
                                        activeGroup === groupId
                                          ? '▼'
                                          : '>'}
                                      </span>
                                    </div>
                                  </div>
                                  {activePromotion === promotion.oc_id && (
                                    <div>
                                      <div className="description ml-2">
                                        {promotion.offerContent.map((offer) => (
                                          <p
                                            key={offer.offer_content_id}
                                            style={{
                                              fontSize: '14px',
                                              fontWeight: 'normal',
                                            }}
                                          >
                                            {offer.offer_description
                                              .replaceAll('<p>', '')
                                              .replaceAll('</p>', '')}
                                          </p>
                                        ))}
                                      </div>
                                      <div className="d-flex justify-content-between align-item-center">
                                        <div
                                          className="accordion-group"
                                          style={{ border: 'none' }}
                                        >
                                          <div
                                            className={`accordion-header ${
                                              isOpen ? 'active' : ''
                                            }`}
                                            onClick={toggleAccordion}
                                          >
                                            <span
                                              className="group-name"
                                              style={{ fontSize: '12px' }}
                                            >
                                              Term & Conditions
                                            </span>
                                            <span className="arrow-icon ml-2">
                                              {isOpen ? '▼' : '>'}
                                            </span>
                                          </div>
                                          <div
                                            className={`accordion-content ${
                                              isOpen ? 'expanded' : ''
                                            }`}
                                          >
                                            <p
                                              style={{
                                                fontSize: '12px',
                                                fontWeight: 'normal',
                                              }}
                                            >
                                              Description
                                            </p>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center gap_25">
                                          {promotion?.is_bank_offer == 'Y' &&
                                            !selectedPromotion && (
                                              <div className="bank-card-input">
                                                <div className="card_number_input">
                                                  <label>Card Number </label>
                                                  <input
                                                    name="card_number"
                                                    id="card_number"
                                                    className="cardInputBank"
                                                    maxLength="9"
                                                    placeholder="0000 0000"
                                                    type="text"
                                                    onInput={enterCardNumber}
                                                  />
                                                  <span className="mask-text">
                                                    XXXX XXXX
                                                  </span>
                                                </div>
                                                {!!cardInfoTemp?.bank_validate_msg && (
                                                  <span
                                                    className={
                                                      !!cardInfoTemp?.is_valid
                                                        ? 'text-success card_validMsg'
                                                        : 'text-danger card_validMsg'
                                                    }
                                                  >
                                                    {cardInfoTemp?.bank_validate_msg &&
                                                      cardInfoTemp?.bank_validate_msg}
                                                  </span>
                                                )}
                                              </div>
                                            )}
                                          {promotion?.is_bank_offer == 'Y' &&
                                          !cardInfoTemp?.is_valid &&
                                          !selectedPromotion ? (
                                            <button
                                              className={`select-button btn-main ${
                                                !!bankOfferLoading &&
                                                'loader-text inactive'
                                              }`}
                                              disabled={!!bankOfferLoading}
                                              onClick={() => {
                                                validateBankOffer(promotion);
                                              }}
                                              style={{
                                                borderRadius: '10px',
                                                // backgroundColor: '#434A9F',
                                                color: 'white',
                                                // width: "80px",
                                                height: '35px',
                                                padding: '5px',
                                                position: 'relative',
                                              }}
                                            >
                                              {!!cardInfoTemp?.is_valid
                                                ? 'Validated'
                                                : 'Validate'}
                                            </button>
                                          ) : (
                                            <button
                                              className={`select-button btn-main ${
                                                (promotionsLoading ||
                                                  (selectedPromotionLoading &&
                                                    selectedPromotion &&
                                                    selectedPromotion?.oc_id ==
                                                      promotion?.oc_id)) &&
                                                'loader-text inactive'
                                              } ${
                                                selectedPromotion &&
                                                selectedPromotion.oc_id ==
                                                  promotion.oc_id
                                                  ? 'golden_btn_pvt'
                                                  : ''
                                              }`}
                                              disabled={
                                                promotionsLoading ||
                                                selectedPromotionLoading
                                              }
                                              onClick={() => {
                                                reserveSelectedOfferPromotion(
                                                  promotion,
                                                  reservation_id,
                                                  reservationDetails,
                                                );
                                              }}
                                              style={{
                                                borderRadius: '10px',
                                                // backgroundColor: '#434A9F',
                                                color: 'white',
                                                // width: "80px",
                                                height: '35px',
                                                padding: '5px',
                                                position: 'relative',
                                              }}
                                            >
                                              {selectedPromotion &&
                                              selectedPromotion.oc_id ==
                                                promotion.oc_id
                                                ? promotionsLoading || selectedPromotionLoading ? 'Applying..' : 'Applied'
                                                : 'Select'}
                                            </button>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      </div>
                    ))}
                  </div> */}

                  <div className="custom-accordion">
                    <div
                      className="accordion-group"
                      style={{ boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}
                    >
                      <div className="accordion-content expanded">
                        <ul className="custom-list mb-0">
                          {Object.values(groupedPromotions).map((group) =>
                            group.promotions.map((promotion, index) => {
                              if (promotion.offer_redeem_at_online === 'Y') {
                                return (
                                  <li key={index} className="promotion">
                                    <div
                                      className={`promotion-title ${
                                        activePromotion === promotion.oc_id
                                          ? 'active'
                                          : ''
                                      }`}
                                      onClick={() =>
                                        handleOfferClick(
                                          promotion.oc_id,
                                          group.groupId,
                                        )
                                      }
                                    >
                                      <div className="d-flex align-items-center">
                                        {promotion.offer_title ===
                                          'Standard Chartered Credit Card Offer' && (
                                          <img
                                            src={masterCardStandardCharteredLogo}
                                            height={50}
                                            width={30}
                                            alt="Offer Image"
                                          />
                                        )}
                                        <span className="bullet">-</span>{' '}
                                        <p style={{ color: '#1AAC52' }}>
                                          {promotion.offer_title}
                                        </p>
                                        {/* <div className="horizontal-line hrp1"></div> */}
                                        {/* <span
                                          className="arrow-icon"
                                          style={{
                                            marginLeft: isDeskTop ? '20px' : '10px',
                                          }}
                                        >
                                          {activePromotion === promotion.oc_id &&
                                          activeGroup === group.groupId
                                            ? '▶'
                                            : '▽'}
                                        </span> */}
                                        <div className="ml-3">
                                          {activePromotion === promotion.oc_id &&
                                          activeGroup === group.groupId ? (
                                            <img
                                              src={downArrow}
                                              className="arrow_icon_class"
                                            />
                                          ) : (
                                            <img
                                              style={{
                                                transform: 'rotate(180deg)',
                                              }}
                                              src={downArrow}
                                              className="arrow_icon_class"
                                            />
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                    {activePromotion === promotion.oc_id && (
                                      <div>
                                        <div className="description">
                                          {promotion.offerContent.map((offer) => (
                                            <p
                                              key={offer.offer_content_id}
                                              style={{
                                                fontSize: '14px',
                                                fontWeight: 'bold',
                                                color: 'red',
                                              }}
                                            >
                                              {offer.offer_description.replace(
                                                /<\/?[^>]+(>|$)/g,
                                                '',
                                              )}
                                            </p>
                                          ))}
                                          {isMobile && (
                                            <div className="d-flex flex-column justify-content-center align-items-center gap_25">
                                              {promotion?.is_bank_offer == 'Y' &&
                                                !selectedPromotion && (
                                                  <div className="bank-card-input">
                                                    <div className="card_number_input">
                                                      <label
                                                        style={{ color: '#000' }}
                                                      >
                                                        Card Number{' '}
                                                      </label>
                                                      <input
                                                        name="card_number"
                                                        id="card_number"
                                                        className="cardInputBank"
                                                        maxLength="6"
                                                        placeholder="000 000"
                                                        type="text"
                                                        onInput={enterCardNumber}
                                                      />
                                                      <span
                                                        className="mask-text"
                                                        style={{ color: '#000' }}
                                                      >
                                                        XXX XXX
                                                      </span>
                                                    </div>
                                                    {!!cardInfoTemp?.bank_validate_msg && (
                                                      <span
                                                        className={
                                                          !!cardInfoTemp?.is_valid
                                                            ? 'text-success card_validMsg'
                                                            : 'text-danger card_validMsg'
                                                        }
                                                      >
                                                        {cardInfoTemp?.bank_validate_msg &&
                                                          cardInfoTemp?.bank_validate_msg}
                                                      </span>
                                                    )}
                                                  </div>
                                                )}
                                              {promotion?.is_bank_offer == 'Y' &&
                                              !cardInfoTemp?.is_valid &&
                                              !selectedPromotion ? (
                                                <button
                                                  className={`select-button btn-main-standard-offer ${
                                                    !!bankOfferLoading &&
                                                    'loader-text inactive'
                                                  }`}
                                                  disabled={!!bankOfferLoading}
                                                  onClick={() => {
                                                    validateBankOffer(promotion);
                                                  }}
                                                  style={{
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '5px',
                                                    position: 'relative',
                                                  }}
                                                >
                                                  {!!cardInfoTemp?.is_valid
                                                    ? 'Validated'
                                                    : 'Validate'}
                                                </button>
                                              ) : (
                                                <button
                                                  className={`select-button btn-main ${
                                                    (promotionsLoading ||
                                                      (selectedPromotionLoading &&
                                                        selectedPromotion &&
                                                        selectedPromotion?.oc_id ==
                                                          promotion?.oc_id)) &&
                                                    'loader-text inactive'
                                                  } ${
                                                    selectedPromotion &&
                                                    selectedPromotion.oc_id ==
                                                      promotion.oc_id
                                                      ? 'golden_btn_pvt'
                                                      : ''
                                                  }`}
                                                  disabled={
                                                    promotionsLoading ||
                                                    selectedPromotionLoading
                                                  }
                                                  onClick={() => {
                                                    reserveSelectedOfferPromotion(
                                                      promotion,
                                                      reservation_id,
                                                      reservationDetails,
                                                    );
                                                  }}
                                                  style={{
                                                    borderRadius: '10px',
                                                    color: 'white',
                                                    height: '35px',
                                                    padding: '5px',
                                                    position: 'relative',
                                                  }}
                                                >
                                                  {selectedPromotion &&
                                                  selectedPromotion.oc_id ==
                                                    promotion.oc_id
                                                    ? promotionsLoading ||
                                                      selectedPromotionLoading
                                                      ? 'Applying..'
                                                      : 'Applied'
                                                    : 'Select'}
                                                </button>
                                              )}
                                            </div>
                                          )}
                                        </div>

                                        {false && (
                                          <div
                                            className="accordion-group"
                                            style={{ border: 'none' }}
                                          >
                                            <div
                                              className={`accordion-header ${
                                                isOpen ? 'active' : ''
                                              }`}
                                              onClick={toggleAccordion}
                                            >
                                              <span
                                                className="group-name"
                                                style={{ fontSize: '12px' }}
                                              >
                                                Term & Conditions
                                              </span>
                                              <span className="arrow-icon ml-2">
                                                {isOpen ? '▶' : '▽'}
                                              </span>
                                            </div>
                                            <div
                                              className={`accordion-content ${
                                                isOpen ? 'expanded' : ''
                                              }`}
                                            >
                                              <p
                                                style={{
                                                  fontSize: '12px',
                                                  fontWeight: 'normal',
                                                }}
                                              >
                                                Description
                                              </p>
                                            </div>
                                          </div>
                                        )}
                                        {!isMobile && (
                                          <div className="d-flex justify-content-start align-items-center gap_25 mt-3">
                                            {promotion?.is_bank_offer == 'Y' &&
                                              !selectedPromotion && (
                                                <div className="bank-card-input">
                                                  <div className="card_number_input">
                                                    <label
                                                      style={{ color: '#000' }}
                                                    >
                                                      Card Number{' '}
                                                    </label>
                                                    <input
                                                      name="card_number"
                                                      id="card_number"
                                                      className="cardInputBank"
                                                      maxLength="6"
                                                      placeholder="000 000"
                                                      type="text"
                                                      onInput={enterCardNumber}
                                                    />
                                                    <span
                                                      className="mask-text"
                                                      style={{ color: '#000' }}
                                                    >
                                                      XXX XXX
                                                    </span>
                                                  </div>
                                                  {!!cardInfoTemp?.bank_validate_msg && (
                                                    <span
                                                      className={
                                                        !!cardInfoTemp?.is_valid
                                                          ? 'text-success card_validMsg'
                                                          : 'text-danger card_validMsg'
                                                      }
                                                    >
                                                      {cardInfoTemp?.bank_validate_msg &&
                                                        cardInfoTemp?.bank_validate_msg}
                                                    </span>
                                                  )}
                                                </div>
                                              )}
                                            {promotion?.is_bank_offer == 'Y' &&
                                            !cardInfoTemp?.is_valid &&
                                            !selectedPromotion ? (
                                              <button
                                                className={`select-button btn-main-standard-offer ${
                                                  !!bankOfferLoading &&
                                                  'loader-text inactive'
                                                }`}
                                                disabled={!!bankOfferLoading}
                                                onClick={() => {
                                                  validateBankOffer(promotion);
                                                }}
                                                style={{
                                                  color: 'white',
                                                  height: '35px',
                                                  padding: '5px',
                                                  maxWidth: isTablet ? '80px' : '',
                                                }}
                                              >
                                                {!!cardInfoTemp?.is_valid
                                                  ? 'Validated'
                                                  : 'Validate'}
                                              </button>
                                            ) : (
                                              <button
                                                className={`select-button btn-main ${
                                                  (promotionsLoading ||
                                                    (selectedPromotionLoading &&
                                                      selectedPromotion &&
                                                      selectedPromotion?.oc_id ==
                                                        promotion?.oc_id)) &&
                                                  'loader-text inactive'
                                                } ${
                                                  selectedPromotion &&
                                                  selectedPromotion.oc_id ==
                                                    promotion.oc_id
                                                    ? 'golden_btn_pvt'
                                                    : ''
                                                }`}
                                                disabled={
                                                  promotionsLoading ||
                                                  selectedPromotionLoading
                                                }
                                                onClick={() => {
                                                  reserveSelectedOfferPromotion(
                                                    promotion,
                                                    reservation_id,
                                                    reservationDetails,
                                                  );
                                                }}
                                                style={{
                                                  borderRadius: '10px',
                                                  color: 'white',
                                                  height: '35px',
                                                  padding: '5px',
                                                  position: 'relative',
                                                  minWidth: isTablet
                                                    ? '80px'
                                                    : '117px',
                                                }}
                                              >
                                                {selectedPromotion &&
                                                selectedPromotion.oc_id ==
                                                  promotion.oc_id
                                                  ? promotionsLoading ||
                                                    selectedPromotionLoading
                                                    ? 'Applying..'
                                                    : 'Applied'
                                                  : 'Select'}
                                              </button>
                                            )}
                                          </div>
                                        )}
                                      </div>
                                    )}
                                  </li>
                                );
                              } else {
                                return null;
                              }
                            }),
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>

                  {/* </article> */}
                </div>
              </div>
            )}
        </>
      )}

      {!isFreeRateCard &&
        activeTab === 'voucher' &&
        !selectedPromotion &&
        isVouchersPaymentModeEnabled &&
        false && (
          <>
            {!showCashCardSection && (
              <>
                <div
                  className={`promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mt-4 mx-auto ${
                    appliedVoucher
                      ? 'd-flex justify-content-center align-items-center'
                      : ''
                  } mx-4`}
                >
                  {!appliedVoucher ? (
                    <div>
                      <p>{t('Do you have a voucher or promo code?')}</p>
                      <div className="promo_code_wrapper">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={t('Apply your promo code here')}
                          value={promoCode || ''}
                          onChange={(e) => setPromoCode(e.target.value)}
                        />
                        <button
                          onClick={() => onApplyPromoCode()}
                          className={`btn-main ${
                            category_id == 1 && 'golden_btn_pvt'
                          }`}
                        >
                          {t('Apply')}
                        </button>
                      </div>
                      {promoCodeError ? (
                        <p className="error mt-1"> {t(promoCodeError)}</p>
                      ) : (
                        <p className="error mt-1"> </p>
                      )}
                    </div>
                  ) : (
                    <>
                      <p>
                        {t('Voucher Applied')}: {appliedVoucher?.voucher_title}
                      </p>
                      <button
                        onClick={() => {
                          dispatch(
                            resetVoucherAndOfferInReservation(reservation_id),
                          ).then(() => {
                            onRemoveVoucher();
                          });
                        }}
                        className={`promo-btn remove-code ml-2 ${
                          category_id == 1 && 'golden_btn_pvt'
                        }`}
                      >
                        {t('Remove')}
                      </button>
                    </>
                  )}
                </div>
              </>
            )}
          </>
        )}

      {!isFreeRateCard &&
        activeTab === 'cashcard' &&
        !!isCashCardPaymentEnabled &&
        !showCashCardSection &&
        !appliedVoucher && (
          <>
            <div className="mt-3 mb-3 gift_card_redemption_btn_group mx-4">
              <button
                className={`btn-main ${category_id == 1 && 'golden_btn_pvt'}`}
                onClick={() => {
                  setShowCashCardSection(true);
                  setShowStripe(false);
                  sethidePaymentOptions(true);
                }}
              >
                {t('Apply Cash Card')}
              </button>
            </div>
          </>
        )}

      {!isFreeRateCard &&
        activeTab === 'cashcard' &&
        showCashCardSection &&
        !appliedVoucher && (
          <>
            {showRedeemTab && (
              <div className="promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mx-auto my-3 mx-4">
                {validGiftCardCredentials ? (
                  <div className="l-border_">
                    <h6
                      className={`mt-3 gift_card_redemption_balance ${
                        category_id == 1 && ' error-golden '
                      } `}
                    >
                      {t('Your Current Balance is')}
                      <p>
                        {curr_code}{' '}
                        {currencyFormatter(firstCashCard?.balance_amount)}
                      </p>
                    </h6>
                    <p>{t('Enter amount to be reduced')}</p>
                    <input
                      type="text"
                      className="form-control mb-1"
                      placeholder="Enter amount to be reduced"
                      value={appliedRedemption}
                      onChange={(e) => {
                        if (!firstCashCard?.balance_amount) return;
                        setRedemptionValue(
                          Number.isNaN(parseInt(e.target.value, 10))
                            ? ''
                            : parseInt(e.target.value, 10),
                        );
                        if (Number.isNaN(parseInt(e.target.value, 10))) {
                          dispatch({
                            type: 'SET_CASHCARD_REDEMPTION_AMOUNT',
                            payload: '',
                          });
                        }
                      }}
                    />
                    {firstCashCard?.balance_amount <= 0 && (
                      <p className={`error ${category_id == 1 && 'error'}`}>
                        {' '}
                        {t('No balance')}
                      </p>
                    )}
                    {firstCashCard?.balance_amount < appliedRedemption && (
                      <p className={`error ${category_id == 1 && ' error '}`}>
                        {' '}
                        {t('Redeem Amount cant be greater than cash card balance')}
                      </p>
                    )}
                    {appliedRedemption >
                      (reservationDetails?.amount || 0) +
                        (fnbPrice?.total_price || 0) -
                        (discount ? discount : 0) +
                        (getInternetHandlingFees(
                          category_id,
                          is_internet_handling_enable,
                          scheduleDetails,
                          reservationDetails,
                        ) || 0) +
                        getRateCardAddonsTotal(add_ons_array) -
                        (totalOfferPrice ? totalOfferPrice : 0) && (
                      <p className={`error ${category_id == 1 && 'error'}`}>
                        {' '}
                        {t('Redeem Amount cant be greater than grand total')}
                      </p>
                    )}

                    <div className="mb-3 gift_card_redemption_btn_group">
                      <button
                        className="btn-main no_background"
                        onClick={() => {
                          setRedemptionValue('');
                          dispatch({
                            type: 'SET_CASHCARD_REDEMPTION_AMOUNT',
                            payload: '',
                          });
                          setValidGiftCardCredentials(false);
                          setShowStripe(false);
                        }}
                      >
                        {t('Back')}
                      </button>
                      <button
                        className={`btn-main ${
                          !appliedRedemption ||
                          firstCashCard?.balance_amount == 0 ||
                          firstCashCard?.balance_amount < appliedRedemption ||
                          appliedRedemption >
                            (reservationDetails?.amount || 0) +
                              (fnbPrice?.total_price || 0) -
                              (discount ? discount : 0) +
                              (getInternetHandlingFees(
                                category_id,
                                is_internet_handling_enable,
                                scheduleDetails,
                                reservationDetails,
                              ) || 0) +
                              getRateCardAddonsTotal(add_ons_array) -
                              (totalOfferPrice ? totalOfferPrice : 0)
                            ? 'inactive'
                            : ''
                        } ${category_id == 1 && 'golden_btn_pvt'}`}
                        onClick={() => {
                          setShowStripe(false);
                          sethidePaymentOptions(false);
                          onApplyCashCardRedeem();
                        }}
                      >
                        {t('Apply')}
                      </button>
                    </div>
                  </div>
                ) : (
                  <div className="l-border_">
                    <p className="mt-3">{t('Card Number')}</p>

                    <input
                      type="number"
                      className="form-control mb-1"
                      placeholder={t('Enter your Card number')}
                      value={card}
                      onChange={(e) => {
                        setCard(e?.target?.value);
                      }}
                    />

                    {!!showCashCardPinScanner && (
                      <CashCardScanner
                        onResult={(result, error) => {
                          if (result && result?.text) {
                            setCard(
                              result?.text
                                ?.split(' ')
                                .join('')
                                .split('-')
                                .join('') || '',
                            );
                            setshowCashCardPinScanner(!showCashCardPinScanner);
                          }
                          if (error) {
                            console.log('cant scan qrcode', error.message);
                          }
                        }}
                      />
                    )}
                    <div style={{ backgroundColor: 'grey' }}>
                      <img
                        title={t('Scan QR Code')}
                        alt=""
                        width="28"
                        height="28"
                        className="show-password-icon"
                        src={qrCodeIcon}
                        onClick={() =>
                          setshowCashCardPinScanner(!showCashCardPinScanner)
                        }
                      />
                    </div>

                    {submittedCashCardDetails && (cardError || errorMsg) && (
                      <p className={`error ${category_id == 1 && 'error'}`}>
                        {t(
                          errorMsg && errorMsg != 'Incorrect Password.'
                            ? errorMsg
                            : cardError
                            ? 'Please enter Card number'
                            : '',
                        )}
                      </p>
                    )}
                    <p className={'mt-3'}>{t('PIN number')}</p>

                    <label className={`w-100 position-relative`}>
                      <input
                        type={showCashCardPin ? 'number' : 'password'}
                        autocomplete="new-password"
                        className="form-control mb-1"
                        placeholder={t('Enter your PIN number')}
                        value={pin}
                        onChange={(e) => {
                          setPin(e?.target?.value);
                        }}
                      />
                      {!showCashCardPin && (
                        <img
                          alt=""
                          width="30"
                          height="30"
                          className="show-password-icon"
                          src={showPasswordIcon}
                          style={{ top: '5px' }}
                          onClick={() => setshowCashCardPin(!showCashCardPin)}
                        />
                      )}
                      {!!showCashCardPin && (
                        <img
                          alt=""
                          width="30"
                          height="30"
                          className="show-password-icon"
                          src={hidePasswordIcon}
                          style={{ top: '5px' }}
                          onClick={() => setshowCashCardPin(!showCashCardPin)}
                        />
                      )}
                    </label>
                    {submittedCashCardDetails &&
                      (cardError || errorMsg || pinError) && (
                        <p className={`error ${category_id == 1 && 'error'}`}>
                          {t(
                            errorMsg && errorMsg != 'Card Detail Not Found'
                              ? errorMsg
                              : pinError
                              ? 'Please enter PIN number'
                              : '',
                          )}
                        </p>
                      )}
                    <div className="mt-3 mb-3 gift_card_redemption_btn_group">
                      <button
                        className={`btn-main ${
                          category_id == 1 ? 'no_background_gold' : 'no_background'
                        }`}
                        onClick={() => {
                          setShowCashCardSection(false);
                          sethidePaymentOptions(false);
                        }}
                      >
                        {t('Back')}
                      </button>
                      <button
                        className={`btn-main ${
                          cashCardDetailsLoading && 'loader-text inactive'
                        } ${category_id == 1 && 'golden_btn_pvt'}`}
                        onClick={() => {
                          submitCashCardDetails();
                        }}
                      >
                        {t('Next')}
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
            {!showRedeemTab && (
              <div className="promo-code-input col-md-10 col-lg-9 col-xl-7 px-0 mx-auto my-3 d-flex justify-content-center align-items-center mx-4">
                <p>
                  {t('Cash Card Applied')}: {curr_code}{' '}
                  {currencyFormatter(appliedRedemption)}
                </p>
                <button
                  onClick={() => {
                    setShowCashCardSection(false);
                    sethidePaymentOptions(false);
                    onRemoveCashCardRedeem();
                  }}
                  className={`promo-btn remove-code ml-2 ${
                    category_id == 1 && 'golden_btn_pvt'
                  }`}
                >
                  {t('Remove')}
                </button>
              </div>
            )}
          </>
        )}

      {!isFreeRateCard && (
        <>
          {!hidePaymentOptions && (
            <>
              {!redemptionAmountEqGrandTotal && (
                <>
                  {!is100PercentOff && (
                    <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-none">
                      <article className="payment-methods">
                        {isSecureAcceptancePaymentEnabled && (
                          <button
                            className={`btn-main ${
                              secureAcceptancePaymentLoading &&
                              'loader-text inactive'
                            } ${category_id == 1 && 'golden_btn_pvt'}`}
                            disabled={
                              ccavenueloading || secureAcceptancePaymentLoading
                            }
                            onClick={MakeSecureAcceptancePayment}
                          >
                            {t('Make Payment')}
                            {''} - {''}
                            {curr_code}{' '}
                            {currencyFormatter(
                              reservationDetails?.amount +
                                fnbPrice.total_price -
                                (discount ? discount : 0) +
                                (getInternetHandlingFees(
                                  category_id,
                                  is_internet_handling_enable,
                                  scheduleDetails,
                                  reservationDetails,
                                ) || 0) +
                                (getRateCardAddonsTotal(add_ons_array) || 0) -
                                (totalOfferPrice ? totalOfferPrice : 0),
                            )}
                          </button>
                        )}

                        {isCCAvenueEnabled && (
                          <button
                            className={`btn-main ${
                              ccavenueloading && 'loader-text inactive'
                            } ${category_id == 1 && 'golden_btn_pvt'}`}
                            disabled={
                              ccavenueloading || secureAcceptancePaymentLoading
                            }
                            onClick={MakeCCAvenuePayment}
                          >
                            {t('Make Payment (CC Avenue)')}
                            {''} - {''}
                            {curr_code}{' '}
                            {currencyFormatter(
                              reservationDetails?.amount +
                                fnbPrice.total_price -
                                (discount ? discount : 0) +
                                (getInternetHandlingFees(
                                  category_id,
                                  is_internet_handling_enable,
                                  scheduleDetails,
                                  reservationDetails,
                                ) || 0) +
                                (getRateCardAddonsTotal(add_ons_array) || 0) -
                                (totalOfferPrice ? totalOfferPrice : 0),
                            )}
                          </button>
                        )}

                        {!appliedVoucher &&
                          !showCashCardSection &&
                          isUnpaidBookingEnabled &&
                          booking_type_id === 0 && (
                            <button
                              className={`btn-main ${
                                UnpaidBookingLoading && 'loader-text inactive'
                              } ${category_id == 1 && 'golden_btn_pvt'}`}
                              onClick={() => MakeUnpaidBooking()}
                            >
                              {t('Unpaid Booking')}
                            </button>
                          )}
                      </article>
                    </div>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}

      {!isFreeRateCard && (
        <>
          {!is100PercentOff &&
            (showStripe || (!isSaferPayActive && !isUnpaidBookingEnabled)) &&
            isStripePaymentModeEnabled && (
              <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3">
                {bookSeatError && (
                  <p className={`error ${category_id == 1 && ' error-golden '}`}>
                    {bookSeatError}
                  </p>
                )}
                <form onSubmit={stripeHandleSubmit}>
                  {stripeKey && (
                    <CardElement
                      options={{
                        style: {
                          base: {
                            fontSize: '16px',
                            color: 'white',
                            '::placeholder': {
                              color: '#eeeeee',
                            },
                            padding: '10px',
                            border: '1px solid white',
                          },
                          invalid: {
                            color: '#dc3545',
                          },
                        },
                      }}
                    />
                  )}
                  {stripeError && (
                    <p className="error stripe-error">{t(stripeError)}</p>
                  )}
                  <button
                    className={`btn-main mx-auto mt-3 ${
                      loading && 'loader-text inactive'
                    } ${category_id == 1 && 'golden_btn_pvt'}`}
                    disabled={!stripe}
                  >
                    {t('Pay')}
                  </button>
                </form>
              </div>
            )}
        </>
      )}

      {/* {true && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
          <button
            className={`btn-main mx-auto mt-3 ml-3 ${loadingOfferpayment && 'loader-text inactive'
              } ${category_id == 1 && 'golden_btn_pvt'}`}
            onClick={() => MakeMasterCardAsfPayment()}
          >
            {t('Pay MaterCard')}
          </button>
        </div>
      )} */}

      {cardPaymentModes && cardPaymentModes?.length && (
        <div className="col-11 px-0 mx-auto mt-4 pb-3 mx-4">
          <div className="po-outer-div-style">
            <div className="po-method-div-style">
              <p className="po-method-style">Payment Method</p>
            </div>
            <div className="po-card-main-div-style">
              {ccavenueloading ? (
                <div className="po-loadingTab">Redirecting....</div>
              ) : (
                <div className="po-card-div-style">
                  {cardPaymentModes?.map((pm_button, index) => {
                    return (
                      <button
                        onClick={() => MakeMasterCardAsfPayment(pm_button?.pm_id)}
                        className="po-button-style"
                        key={index}
                      >
                        <div className="po-button-div-style">
                          <div className="customradio"></div>
                          <div className="withofferdesign">
                            <div className="offercreditflexcol">
                              <span className="po-paywith-style">Pay With</span>
                              <span className="po-debit-card-style">
                                {pm_button?.name_on_btn}
                              </span>
                              {pm_button?.sub_name_2 && (
                                <span className="fs-12">
                                  {pm_button?.sub_name_2}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="po-div-go-amex-style">
                            <span className="po-go-arrow-style">
                              {!isMobile && (
                                <img
                                  className="payment-multipage-img-mobile-style"
                                  height={pm_button?.img_height}
                                  width={pm_button?.img_width}
                                  src={pm_button?.card_image}
                                />
                              )}
                            </span>
                          </div>
                          <div className="po-div-go-arrow-style">
                            <span className="po-go-arrow-style">
                              <img
                                className="po-goahead-img"
                                src={arrowImage}
                                alt="go ahead"
                              />
                            </span>
                          </div>
                        </div>
                      </button>
                    );
                  })}
                  {/* <button className="po-button-style">
                <div className="po-button-div-style">
                  <div className="customradio"></div>
                  <div className="withofferdesign">
                    <div className="offercreditflexcol"><span className="po-paywith-style">Pay With</span><span
                        className="po-debit-card-style">Debit Card</span></div>
                  </div>
                  <div className="po-div-go-payment-style"><span className="po-go-arrow-style"><img
                        src="https://www.epixcinemas.com/aa349c9499d6456cd58d.png"/></span></div>
                  <div className="po-div-go-arrow-style"><span className="po-go-arrow-style"><img className="po-goahead-img"
                        src="https://www.epixcinemas.com/dcdf0b3909a303d27eab.png" alt="go ahead"/></span></div>
                </div>
              </button> */}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {is100PercentOff && (
        // getInternetHandlingFees(category_id, is_internet_handling_enable, scheduleDetails, reservationDetails) == 0 &&
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
          <button
            className={`btn-main mx-auto mt-3 ml-3 ${
              loadingOfferpayment && 'loader-text inactive'
            } ${category_id == 1 && 'golden_btn_pvt'}`}
            onClick={() => MakeFreeTicketBooking()}
          >
            {t('Confirm')}
          </button>
        </div>
      )}

      {false &&
        isFreeRateCard &&
        getInternetHandlingFees(
          category_id,
          is_internet_handling_enable,
          scheduleDetails,
          reservationDetails,
        ) == 0 && (
          <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
            <button
              className={`btn-main mx-auto mt-3 ml-3 ${
                loadingOfferpayment && 'loader-text inactive'
              } ${category_id == 1 && 'golden_btn_pvt'}`}
              onClick={() => MakeFreeRateCardPayment()}
            >
              {t('Confirm')}
            </button>
          </div>
        )}

      {redemptionAmountEqGrandTotal && !showRedeemTab && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
          <button
            className={`btn-main mx-auto mt-3 ml-3 ${
              fullCashCardaymentLoading && 'loader-text inactive'
            } ${category_id == 1 && 'golden_btn_pvt'}`}
            onClick={() => {
              fullCashCardpayment();
            }}
          >
            {t('Confirm')}
          </button>
        </div>
      )}
      {!ActivePaymentModeAvailable && !is100PercentOff && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-2 pb-3 d-flex">
          <p className="error stripe-error w-100 fs-16">
            {t('No Active Payment Modes Found')}
          </p>
        </div>
      )}
      {/* {isUnpaidBookingEnabled && booking_type_id === 0 && (
        <div className="col-md-10 col-lg-9 col-xl-7 px-0 mx-auto mt-4 pb-3 d-flex">
          <button
            className={`btn-main mx-auto mt-3 ml-3 ${
              UnpaidBookingLoading && "loader-text inactive"
            }`}
            onClick={() => MakeUnpaidBooking()}
          >
            {t("Unpaid Booking")}
          </button>
        </div>
      )} */}
    </>
  );
};
// End  Payment Select Mode

//Start Payment Booking Details
const PaymentBookingDetails = ({ category_id, t, r, s }) => {
  const isMobile = useIsMobile();
  const currency = useSelector((state) => state.movies.currency);
  const { curr_code, curr_id } = currency;
  const totalAmount = useSelector((state) => state.movies.totalAmount);
  const netAmount = useSelector((state) => state.movies.netAmount);
  const scheduleDetails = useSelector((state) => state.movies.scheduleDetails);

  const booking_type = useSelector((state) => state.movies.booking_type);
  const appliedVoucher = useSelector((state) => state.promotions.applied_voucher);
  const appliedPromotion = useSelector((s) => s.promotions.appliedPromotion);
  let totalOfferPrice = appliedPromotion?.BoxOffice?.TotalOfferPrice || 0;
  const appliedLoyalty = useSelector((s) => s.promotions.appliedLoyalty);
  const appliedEwallet = useSelector((s) => s.promotions.appliedEwallet);

  const discount = useSelector((state) => state.promotions.voucher_discount);
  const selected_seats = useSelector((state) => state.seatLayout.seats_selected);

  const selected_language = useSelector((state) => state.movies.selected_language);
  const { lang_name, iso_2, lang_id } = selected_language;
  const selectedFnbItems = useSelector((state) => state.fnb.selected_fnb_items);

  const TotalFnbVatArr = selectedFnbItems.map((x) => {
    return x?.total_tax;
  });
  let TotalFnbVatAmt = TotalFnbVatArr.reduce((acc, inc) => {
    return acc + inc;
  }, 0);

  const fnbPrice = useSelector((state) => state.fnb.fnb_price);
  const seatTypes = useSelector((state) => seatTypesSelector(state));

  const total_seat_count = seatTypes?.map((x) => {
    return x?.seats.map((t) => {
      return t?.no_of_seats;
    });
  });
  const is_internet_handling_enable = useSelector(
    (state) => state?.seatLayout?.is_internet_handling_enable,
  );

  const internet_handling_fee = scheduleDetails?.internet_handling_fee ?? 0;

  const reservationDetails = useSelector(
    (state) => state.movies.reservationDetails,
  );

  const add_ons_array = useSelector(
    (state) => state?.movies?.reservationDetails?.add_ons_array,
  );
  const organization = useSelector((state) => state.movies.organizationDetails);
  const { dummy_poster: dummyPosterDynamic } = organization;

  const dummyPoster = window.localStorage.getItem('placeholder_image')
    ? window.localStorage.getItem('placeholder_image')
    : dummyPosterStatic;

  const screeningTypes = useSelector((state) => state.movies?.screeningTypes) ?? [];
  let private_rate_card_detail = reservationDetails?.private_rate_card_detail ?? [];
  let ticket_count =
    private_rate_card_detail.length > 0
      ? private_rate_card_detail[0].customSeatCount
      : null;

  const movieBottomRef = useRef();
  const movieInfoRef = useRef();
  useEffect(() => {
    const setMovieBottomWidth = () => {
      const movieBottom = movieBottomRef.current;
      const movieInfo = movieInfoRef.current;
      if (movieBottom && movieInfo) {
        movieBottom.style.width = movieInfo.offsetWidth + 'px';
      }
    };
    setMovieBottomWidth();
    window.addEventListener('resize', setMovieBottomWidth);
    return () => window.removeEventListener('resize', setMovieBottomWidth);
  }, []);

  function percentage(percent, total) {
    return (percent / 100) * total;
  }

  const cashCardDetails = useSelector((state) => state.cashCard.cashCardDetails);
  const cashCardDetailsLoading = useSelector(
    (state) => state.cashCard.cashCardDetailsLoading,
  );
  const cashCardRedemptionSelection = useSelector(
    (state) => state.cashCard.cashCardRedemptionSelection,
  );

  useEffect(() => {}, [
    reservationDetails,
    seatTypes,
    discount,
    appliedVoucher,
    cashCardRedemptionSelection,
    cashCardDetails,
    cashCardDetailsLoading,
    scheduleDetails,
    totalOfferPrice,
  ]);

  useEffect(() => {
    // console.log({
    //   appliedVoucher,
    //   appliedPromotion,
    //   totalOfferPrice,
    //   appliedLoyalty,
    //   appliedEwallet,
    // });
  }, [
    appliedVoucher,
    appliedPromotion,
    totalOfferPrice,
    appliedLoyalty,
    appliedEwallet,
  ]);

  return (
    <div
      className={`payment_details_new movie_info_wrapper ${
        !isMobile ? 'sticky_movie_info' : ''
      }`}
    >
      {/* <div className="movie-header">
        <div className="position-relative">
          <img
            src={
              getLangSpecificAttribute(
                scheduleDetails?.languageSpecificImages,
                lang_id,
                "artwork"
              ) ||
              scheduleDetails?.md_thumbnail_url ||
              dummyPoster
            }
            alt="Movie Img"
            className="big-image w-100"
          />
          <div className="movie-overlay"></div>
        </div>
      </div> */}
      {isMobile ? (
        // MOBILE DESIGN
        <>
          <article className="movie_info_mobile" ref={movieInfoRef}>
            <figure className="movie-img">
              <img
                src={
                  getLangSpecificAttribute(
                    scheduleDetails?.languageSpecificImages,
                    lang_id,
                    'artwork',
                  ) ||
                  scheduleDetails?.md_thumbnail_url ||
                  dummyPoster
                }
                alt="Movie Img"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = () => {}; // prevents looping
                  currentTarget.src = dummyPosterStatic;
                }}
              />
            </figure>

            <article className="">
              <section>
                <h6 className="movie_booking_type">
                  {
                    // category_id == 1 ? t('On-demand').toUpperCase() : t("Now Showing").toUpperCase()
                  }
                </h6>
                <h1 className="movie_info_title">
                  {getLangSpecificAttribute(
                    scheduleDetails?.altMovieContent,
                    lang_id,
                    'mc_title',
                  ) || scheduleDetails?.movie_title}
                </h1>
                <div className="movie_info_rating_n_format mb-0">
                  <span className="movie_info_rating">
                    {scheduleDetails?.rating}
                  </span>
                  <span className="movie_info_language safari_align_new">
                    {movieLanguagesWithSubs(
                      !!t(scheduleDetails?.lang_name)
                        ? t(scheduleDetails?.lang_name)
                        : scheduleDetails?.lang_name,
                      scheduleDetails?.sub_1_iso_2,
                      scheduleDetails?.sub_2_iso_2,
                    )}{' '}
                  </span>
                  <span className="movie_info_language safari_align">
                    {scheduleDetails?.mrrdr_runtime} {t('common.mins')}
                  </span>
                </div>
              </section>
              {/* <section className="">
                  <article>
                    <h6 className="">{t("Genre")}</h6>
                <h5 className="">{scheduleDetails?.movie_genres
                  ?.filter((genre) => genre[iso_2?.toLowerCase()])
                  ?.map(
                    (genre, index) =>
                      genre[iso_2?.toLowerCase()] &&
                      index < 13 && (
                        <span key={index}>
                          {genre[iso_2?.toLowerCase()]}
                          {index !== scheduleDetails?.movie_genres?.length - 1 ? ', ' : ''}
                        </span>
                      )
                  )}</h5>
                </article>
                  <article>
                    <h6 className="">{t("Year")}</h6>
                <h5>{moment(
                  scheduleDetails?.original_mrrdr_release_date ===
                    `Invalid Date`
                    ? moment()
                    : scheduleDetails?.original_mrrdr_release_date ||
                    moment()
                ).format("YYYY")}</h5>
                </article>
                  <article>
                    <h6 className="">{t('Duration')}</h6>
                <h5>{scheduleDetails?.mrrdr_runtime} {t("common.mins")}</h5>
                </article>
            </section> */}
              <section>
                <div className=" text-left mb-0">
                  <h6 className="font-weight-bold">
                    {t('Date')} &amp; {t('Time')}
                  </h6>
                  <p className="booking_para" style={{ fontWeight: '500' }}>
                    {moment(scheduleDetails?.ss_actual_start_date).format('ll')} |{' '}
                    {moment(scheduleDetails?.ss_start_show_time, 'HH:mm:ss').format(
                      'HH:mm',
                    )}
                  </p>
                </div>
              </section>
              <section>
                <div className=" text-left mb-0">
                  <h6 className="font-weight-bold">{t('Cinema')}</h6>
                  <p className="booking_para" style={{ fontWeight: '500' }}>
                    {scheduleDetails?.screen_name}
                  </p>
                  <p className="booking_para" style={{ fontWeight: '500' }}>
                    {' '}
                    {scheduleDetails?.cine_name}, {scheduleDetails?.cine_address}
                  </p>
                </div>
              </section>
            </article>
          </article>

          <div className="">
            <h6 className="">{t('Booking Summary').toUpperCase()}</h6>
            <article className="payment_details_rows">
              {seatTypes?.length > 0 &&
                seatTypes?.map((seatType, seatTypeIndex) => (
                  <>
                    {seatType?.seats?.length > 0 &&
                      seatType?.seats?.map((rcg_child, index2) => (
                        <section className="">
                          <p className="left_value">
                            <span className="ml-0">
                              (x{rcg_child.no_of_seats} {t('Seats')})
                            </span>
                            &nbsp;
                            <>{seatType.sst_seat_type}</>
                            {/* <span className="">
                              {rcg_child?.ticket_type_translations?.[
                                iso_2?.toLowerCase()
                              ] || rcg_child.tt_name}
                            </span> */}
                          </p>

                          <p className="right_value">
                            {curr_code} {currencyFormatter(rcg_child?.total_price)}
                          </p>
                        </section>
                      ))}
                  </>
                ))}
              {scheduleDetails?.booking_type_id == 3 ? (
                <>
                  <section className="">
                    <div className="left_value">{scheduleDetails?.screen_name}</div>
                    <div className="right_value">
                      {!!ticket_count
                        ? `(x${ticket_count})`
                        : `(x${scheduleDetails?.ticket_count})`}
                    </div>
                  </section>
                  <section className="">
                    <div className="left_value">
                      {t(scheduleDetails?.booking_type_name)}
                    </div>
                    <div className="right_value reservationDetails?.amount">
                      {curr_code}{' '}
                      {currencyFormatter(
                        reservationDetails?.private_rate_card_detail?.[0]
                          ?.customSeatCount *
                          reservationDetails?.private_rate_card_detail[0]?.slab?.[0]
                            ?.price,
                      )}
                    </div>
                  </section>
                </>
              ) : (
                <>
                  {/* <section className="">
                <div className="left_value">{scheduleDetails?.seat_name}</div>
                <div className="right_value">{curr_code}{" "}{currencyFormatter(reservationDetails?.amount)}</div>
                    </section> */}
                </>
              )}
            </article>
            {/* {selectedFnbItems && selectedFnbItems.length > 0 && (
            <article className="grey-title-lg mb-2">
              {t("Concessions")}
            </article>
          )} */}

            {selectedFnbItems && selectedFnbItems.length > 0 && (
              <div className="payment_details_rows">
                {selectedFnbItems.map((item) => (
                  <section>
                    <div className="left_value">
                      {item.Quantity && <span>(x{item.Quantity})</span>}{' '}
                      {getLangSpecificAttribute(
                        item?.FnbContent,
                        lang_id,
                        'item_lang_name',
                      ) || item.item_name}
                    </div>
                    <div className="right_value">
                      {curr_code} {currencyFormatter(item.total_price)}
                    </div>
                  </section>
                ))}
              </div>
            )}

            {/* Gross addons on rate card */}
            {add_ons_array?.[0]?.gross_amount_addons?.length > 0 && (
              <div className="payment_details_rows" id="grossAddonsDetails">
                <section>
                  <div className="left_value">{t('Addons')}</div>
                  <div className="right_value"></div>
                </section>

                {add_ons_array?.[0]?.gross_amount_addons?.map((item) => (
                  <section>
                    <div className="left_value">
                      {item.quantity && <span>(x{item.quantity}) </span>}
                      {t(item.addons_name) || item.addons_name}
                    </div>
                    <div className="right_value">
                      {curr_code} {currencyFormatter(item?.final_price)}
                    </div>
                  </section>
                ))}

                <section>
                  <div className="left_value">
                    {t('Addons')} {t('Total')}
                  </div>
                  <div className="right_value">
                    {curr_code}{' '}
                    {currencyFormatter(getRateCardAddonsTotal(add_ons_array))}
                  </div>
                </section>
              </div>
            )}

            {reservationDetails?.private_rate_card_detail &&
              reservationDetails?.private_rate_card_detail.length > 0 &&
              reservationDetails?.private_rate_card_detail[0]?.fnb &&
              reservationDetails?.private_rate_card_detail[0]?.fnb.length > 0 && (
                <div className="payment_details_rows">
                  {reservationDetails?.private_rate_card_detail[0]?.fnb.map(
                    (item) => (
                      <>
                        <section>
                          <div className="left_value">
                            {reservationDetails?.private_rate_card_detail[0]
                              ?.customSeatCount && (
                              <span>
                                (x
                                {
                                  reservationDetails?.private_rate_card_detail[0]
                                    ?.customSeatCount
                                }
                                )
                              </span>
                            )}{' '}
                            {item.item_name}
                          </div>
                          <div className="right_value">
                            {curr_code}{' '}
                            {currencyFormatter(
                              item.price *
                                reservationDetails?.private_rate_card_detail[0]
                                  ?.customSeatCount,
                            )}
                          </div>
                        </section>
                      </>
                    ),
                  )}
                </div>
              )}
            {reservationDetails?.private_rate_card_detail &&
              reservationDetails?.private_rate_card_detail.length > 0 &&
              reservationDetails?.private_rate_card_detail[0]?.addon &&
              reservationDetails?.private_rate_card_detail[0]?.addon.length > 0 && (
                <div className="payment_details_rows">
                  {reservationDetails?.private_rate_card_detail[0]?.addon.map(
                    (item) => (
                      <>
                        <section>
                          <div className="left_value">
                            {item?.quantity && <span>(x{item?.quantity})</span>}{' '}
                            {item.item_name}
                          </div>
                          <div className="right_value">
                            {curr_code}{' '}
                            {currencyFormatter(item.price * item?.quantity)}
                          </div>
                        </section>
                      </>
                    ),
                  )}
                </div>
              )}

            {appliedLoyalty?.LoyaltyAmount && (
              <div className="payment_details_rows text-success">
                <section className="">
                  <div className="left_value"> {t('Loyalty')}: </div>
                  <div className="right_value">
                    {' '}
                    {curr_code} {currencyFormatter(appliedLoyalty?.LoyaltyAmount)}
                  </div>
                </section>
              </div>
            )}

            {appliedEwallet && (
              <div className="payment_details_rows text-success">
                <section className="">
                  <div className="left_value"> {t('E-Wallet')}: </div>
                  <div className="right_value">
                    {' '}
                    {curr_code} {currencyFormatter(appliedEwallet)}
                  </div>
                </section>
              </div>
            )}

            {discount && (
              <div className="payment_details_rows text-success">
                <section className="">
                  <div className="left_value"> {t('Discount')}: </div>
                  <div className="right_value">
                    {' '}
                    - {curr_code} {currencyFormatter(discount)}
                  </div>
                </section>
              </div>
            )}

            {totalOfferPrice > 0 && (
              <div className="payment_details_rows text-success">
                <section className="">
                  <div className="left_value"> {t('Offer')}: </div>
                  <div className="right_value">
                    {' '}
                    - {curr_code} {currencyFormatter(totalOfferPrice)}
                  </div>
                </section>
              </div>
            )}

            {getInternetHandlingFees(
              category_id,
              is_internet_handling_enable,
              scheduleDetails,
              reservationDetails,
            ) > 0 &&
              false && (
                <div className="payment_details_rows">
                  <section className="">
                    <div className="left_value"> {t('Internet Handling Fee')}</div>
                    <div className="right_value">
                      {' '}
                      {curr_code}{' '}
                      {currencyFormatter(
                        getInternetHandlingFees(
                          category_id,
                          is_internet_handling_enable,
                          scheduleDetails,
                          reservationDetails,
                        ),
                      )}
                    </div>
                  </section>
                </div>
              )}
            {!discount &&
              !!cashCardRedemptionSelection?.amount &&
              cashCardRedemptionSelection?.amount <=
                (reservationDetails?.amount || 0) +
                  (fnbPrice?.total_price || 0) -
                  (discount ? discount : 0) +
                  (getInternetHandlingFees(
                    category_id,
                    is_internet_handling_enable,
                    scheduleDetails,
                    reservationDetails,
                  ) || 0) +
                  getRateCardAddonsTotal(add_ons_array) -
                  (totalOfferPrice ? totalOfferPrice : 0) && (
                <div className="payment_details_rows">
                  <section className="text-success">
                    <div className="left_value">
                      {' '}
                      {t('Cash Card')} {t('Redeem')}:{' '}
                    </div>
                    <div className="right_value">
                      {' '}
                      - {curr_code}{' '}
                      {currencyFormatter(cashCardRedemptionSelection?.amount)}
                    </div>
                  </section>

                  <section className="">
                    <div className="left_value">
                      {' '}
                      {t('Leftover')} {t('Amount')}:{' '}
                    </div>
                    <div className="right_value">
                      {' '}
                      {curr_code}{' '}
                      {currencyFormatter(
                        reservationDetails?.amount +
                          fnbPrice.total_price -
                          (discount ? discount : 0) -
                          cashCardRedemptionSelection?.amount +
                          (getInternetHandlingFees(
                            category_id,
                            is_internet_handling_enable,
                            scheduleDetails,
                            reservationDetails,
                          ) || 0) +
                          getRateCardAddonsTotal(add_ons_array) -
                          (totalOfferPrice ? totalOfferPrice : 0),
                      )}
                    </div>
                  </section>
                </div>
              )}
            <div className="payment_details_rows payment_details_total">
              <section className="">
                <h5 className={` ${category_id == 1 && ' text-golden '}`}>
                  {t('Grand Total')}
                </h5>
                <h4 className={` ${category_id == 1 && ' text-golden '}`}>
                  {curr_code}{' '}
                  {currencyFormatter(
                    reservationDetails?.amount +
                      fnbPrice.total_price -
                      (discount ? discount : 0) +
                      (getInternetHandlingFees(
                        category_id,
                        is_internet_handling_enable,
                        scheduleDetails,
                        reservationDetails,
                      ) || 0) +
                      (getRateCardAddonsTotal(add_ons_array) || 0) -
                      (totalOfferPrice ? totalOfferPrice : 0),
                  )}
                </h4>
              </section>
              {/* <h6>
                {t('(Incl. of 2.5% VAT - ')} {curr_code}{' '}
                {currencyFormatter(
                  _.round(
                    percentage(
                      2.5,
                      reservationDetails?.amount +
                        fnbPrice.total_price -
                        (discount ? discount : 0) +
                        (getInternetHandlingFees(
                          category_id,
                          is_internet_handling_enable,
                          scheduleDetails,
                          reservationDetails,
                        ) || 0) +
                        (getRateCardAddonsTotal(add_ons_array) || 0) -
                        (totalOfferPrice ? totalOfferPrice : 0),
                    ),
                    4,
                  ),
                )}{' '}
                {')'}
              </h6> */}
            </div>
          </div>
        </>
      ) : (
        // DESKTOP DESIGN
        <>
          <article className="movie_info" ref={movieInfoRef}>
            <div className="movie-img">
              <img
                src={
                  getLangSpecificAttribute(
                    scheduleDetails?.languageSpecificImages,
                    lang_id,
                    'artwork',
                  ) ||
                  scheduleDetails?.md_thumbnail_url ||
                  dummyPoster
                }
                alt="Movie Img"
                onError={({ currentTarget }) => {
                  currentTarget.onerror = () => {}; // prevents looping
                  currentTarget.src = dummyPosterStatic;
                }}
              />
            </div>

            <div className="">
              {/* <h6 className="movie_booking_type">{
                category_id == 1 ? t('On-demand').toUpperCase() : t("Now Showing").toUpperCase()
              }</h6> */}
              <h1 className="movie_info_title">
                {getLangSpecificAttribute(
                  scheduleDetails?.altMovieContent,
                  lang_id,
                  'mc_title',
                ) || scheduleDetails?.movie_title}
              </h1>
              {/* <ul className="list-inline movie_info_props">
              <li  className="list-inline-item">
                  <article>
                    <h6 className="">{t("Genre")}</h6>
                <h5 className="">{scheduleDetails?.movie_genres
                  ?.filter((genre) => genre[iso_2?.toLowerCase()])
                  ?.map(
                    (genre, index) =>
                      genre[iso_2?.toLowerCase()] &&
                      index < 13 && (
                        <span key={index}>
                          {genre[iso_2?.toLowerCase()]}
                          {index !== scheduleDetails?.movie_genres?.length - 1 ? ', ' : ''}
                        </span>
                      )
                  )}</h5>
                </article>
              </li>
              <li className="list-inline-item">
                  <article>
                    <h6 className="">{t("Year")}</h6>
                <h5>{moment(
                  scheduleDetails?.original_mrrdr_release_date ===
                    `Invalid Date`
                    ? moment()
                    : scheduleDetails?.original_mrrdr_release_date ||
                    moment()
                ).format("YYYY")}</h5>
                </article>
              </li>
              <li className="list-inline-item">
                  <article>
                    <h6 className="">{t('Duration')}</h6>
                <h5>{scheduleDetails?.mrrdr_runtime} {t("common.mins")}</h5>
                </article>
              </li>
            </ul> */}
              <div className="movie_info_chekout_new">
                <span className="movie_info_rating">{scheduleDetails?.rating}</span>
                <span className="movie_info_language">
                  {' '}
                  {scheduleDetails?.lang_translation_obj?.[iso_2?.toLowerCase()] ??
                  !!t(scheduleDetails?.lang_name)
                    ? t(scheduleDetails?.lang_name)
                    : scheduleDetails?.lang_name}
                  {`${
                    scheduleDetails?.sub_1_iso_2
                      ? `/${scheduleDetails?.sub_1_iso_2}`
                      : ''
                  }${
                    scheduleDetails?.sub_1_iso_2 && scheduleDetails?.sub_2_iso_2
                      ? `-${scheduleDetails?.sub_2_iso_2}`
                      : ''
                  }`}{' '}
                </span>
                <span className="movie_info_language">
                  {scheduleDetails?.mrrdr_runtime} {t('common.mins')}
                </span>
              </div>
            </div>
            <div className="title_n_value py-3">
              <h5
                style={{ color: '#ea962d' }}
                className={`text=${category_id == 1 && ' text-golden '}`}
              >
                {t('Ticket Details').toUpperCase()}
              </h5>

              <div className="checkout_title_n_value p-2">
                <h6
                  className={`checkout_cinema_title ${
                    category_id == `1` ? 'gold-cinema-name' : ''
                  }`}
                  style={{ color: '#434A9F' }}
                >
                  {/* {t('Cinema')} */}
                </h6>
                <div
                  style={{
                    borderRadius: '20px',
                    backgroundColor: 'gray',
                    textAlign: 'left',
                    height: '30px',
                    padding: '4px',
                    fontWeight: 'normal',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <p style={{ fontWeight: 'normal' }}>
                    <img src={locationnew} alt="location" />{' '}
                    {scheduleDetails?.cine_name}
                    {/* , {scheduleDetails?.cine_address} */}
                  </p>
                </div>
                <div
                  style={{
                    borderRadius: '20px',
                    backgroundColor: 'gray',
                    textAlign: 'left',
                    marginTop: '10px',
                    height: '30px',
                    padding: '4px',
                  }}
                >
                  <p style={{ fontSize: '15px', fontWeight: 'normal' }}>
                    {/* {scheduleDetails?.cine_address} */}
                    <img src={video} alt="location" />{' '}
                    {getLangSpecificAttribute(
                      scheduleDetails?.altMovieContent,
                      lang_id,
                      'mc_title',
                    ) || scheduleDetails?.movie_title}
                  </p>
                </div>
              </div>
              <div className="checkout_title_n_value">
                <h6
                  className={`checkout_cinema_title ${
                    category_id == `1` ? 'gold-cinema-name' : ''
                  }`}
                  style={{ color: '#434A9F' }}
                >
                  {/* {t('Booking Summary').toUpperCase()} */}
                </h6>
                <article className="payment_details_rows">
                  <div className="checkout_title_n_value d-flex justify-content-between align-item-center p-2">
                    <h2
                      className={`checkout_cinema_title ${
                        category_id == `1` ? 'gold-cinema-name' : ''
                      }`}
                      style={{ color: 'white' }}
                    >
                      {t('ShowTime')}
                    </h2>
                    <p className="book_detai_para">
                      {moment(scheduleDetails?.ss_actual_start_date).format('ll')} |{' '}
                      {moment(
                        scheduleDetails?.ss_start_show_time,
                        'HH:mm:ss',
                      ).format('HH:mm')}
                    </p>
                  </div>
                  <div className="checkout_title_n_value d-flex justify-content-between align-item-center p-2">
                    <h2
                      className={`checkout_cinema_title ${
                        category_id == `1` ? 'gold-cinema-name' : ''
                      }`}
                      style={{ color: 'white' }}
                    >
                      {t('Screen')}
                    </h2>
                    <p className="book_detai_para">
                      {scheduleDetails?.screen_name}
                    </p>
                  </div>
                  <div className="checkout_title_n_value d-flex justify-content-between align-item-center p-2">
                    <h2
                      className={`checkout_cinema_title ${
                        category_id == `1` ? 'gold-cinema-name' : ''
                      }`}
                      style={{ color: 'white' }}
                    >
                      {t('Format')}
                    </h2>
                    <p className="book_detai_para">{scheduleDetails?.mf_name}</p>
                  </div>
                  <div className="checkout_title_n_value d-flex justify-content-between align-item-center p-2">
                    <h2
                      className={`checkout_cinema_title ${
                        category_id == `1` ? 'gold-cinema-name' : ''
                      }`}
                      style={{ color: 'white' }}
                    >
                      {t('Class')}
                    </h2>
                    <p>
                      {seatTypes?.length > 0 &&
                        seatTypes?.map((seatType, seatTypeIndex) => (
                          <>
                            {seatType?.seats?.length > 0 &&
                              seatType?.seats?.map((rcg_child, index2) => (
                                <section>
                                  {/* <p className="left_value">
              <span>
                (x{rcg_child.no_of_seats} {t('Seats')})
              </span> */}
                                  {/* &nbsp;
              <>{seatType.sst_seat_type}</>
              &nbsp;
              <br /> */}
                                  {/* <span className="seat-name-color">
                {seatType.newSeatsName.join(',')}
              </span> */}
                                  {/* Add the sst_seat_type here */}
                                  <p className="book_detai_para">
                                    {seatType.sst_seat_type}
                                  </p>
                                  {/* </p> */}
                                </section>
                              ))}
                          </>
                        ))}
                    </p>
                  </div>

                  <div className="checkout_title_n_value d-flex justify-content-between align-item-start p-2">
                    <h2
                      className={`checkout_cinema_title ${
                        category_id == `1` ? 'gold-cinema-name' : ''
                      }`}
                      style={{ color: 'white' }}
                    >
                      {t('Seat')}
                    </h2>
                    <p>
                      {seatTypes?.length > 0 &&
                        seatTypes?.map((seatType, seatTypeIndex) => (
                          <>
                            {seatType?.seats?.length > 0 &&
                              seatType?.seats?.map((rcg_child, index2) => (
                                <section>
                                  <span className="seat-name-color book_detai_para">
                                    {seatType.newSeatsName.join(', ')}
                                  </span>
                                </section>
                              ))}
                          </>
                        ))}
                    </p>
                  </div>
                  {/* Gross addons on rate card */}
                  {add_ons_array?.[0]?.gross_amount_addons?.length > 0 && (
                    <div className="" id="grossAddonsDetails">
                      {/* <section>
                      <div className="left_value">{t('Addons')}</div>
                      <div className="right_value"></div>
                    </section> */}

                      {add_ons_array?.[0]?.gross_amount_addons?.map((item) => (
                        <div className="checkout_title_n_value d-flex justify-content-between align-item-start p-2">
                          <h2
                            className={`checkout_cinema_title`}
                            style={{ color: 'white' }}
                          >
                            {t('Addons')} {t(item.addons_name) || item.addons_name}
                          </h2>
                          <p className="book_detai_para">
                            {' '}
                            <span>(x{item.quantity}) </span>
                            {curr_code}{' '}
                            {currencyFormatter(
                              getRateCardAddonsTotal(add_ons_array),
                            )}
                          </p>
                        </div>

                        // <section>
                        //   <div className="left_value">

                        //   </div>
                        //   <div className="right_value">
                        //     {curr_code} {currencyFormatter(item?.final_price)}
                        //   </div>
                        // </section>
                      ))}
                      {/* 
                      <section>
                        <div className="left_value">
                          {t('Addons')} {t('Total')}
                        </div>
                        <div className="right_value">
                          {curr_code}{' '}
                          {currencyFormatter(getRateCardAddonsTotal(add_ons_array))}
                        </div>
                      </section> */}
                    </div>
                  )}
                  {/* {reservationDetails?.chargeTypeArray?.map((item) => (
                    <div className="checkout_title_n_value d-flex justify-content-between align-item-start p-2">
                      <h2
                        className={`checkout_cinema_title ${
                          category_id == `1` ? 'gold-cinema-name' : ''
                        }`}
                        style={{ color: 'white' }}
                      >
                        {item?.ct_name}
                      </h2>
                      <p className="book_detai_para">
                        {total_seat_count[0]} x {curr_code}{' '}
                        {currencyFormatter(item?.tax_amount / total_seat_count[0])}
                      </p>
                    </div>
                  ))} */}
                  {reservationDetails?.chargeTypeArray?.map((item) => (
                    <div className="checkout_title_n_value d-flex justify-content-between align-item-start p-2">
                      <h2
                        className={`checkout_cinema_title ${
                          category_id == `1` ? 'gold-cinema-name' : ''
                        }`}
                        style={{ color: 'white' }}
                      >
                        {/* {item?.ct_name} */}
                        {item?.ct_name.toLowerCase() === 'vat' && ' VAT 10%'}
                      </h2>
                      <p className="book_detai_para">
                        {total_seat_count.length} x {curr_code}{' '}
                        {currencyFormatter(
                          item?.tax_amount / total_seat_count.length,
                        )}
                      </p>
                    </div>
                  ))}

                  <div className="checkout_title_n_value d-flex justify-content-between align-item-start p-2">
                    <h2
                      className={`checkout_cinema_title ${
                        category_id == `1` ? 'gold-cinema-name' : ''
                      }`}
                      style={{ color: 'white' }}
                    >
                      {t('Conv. Charges')}
                    </h2>
                    <p className="book_detai_para">
                      {total_seat_count.length} x {curr_code}{' '}
                      {currencyFormatter(
                        reservationDetails?.internet_handling_fee /
                          total_seat_count.length,
                      )}
                    </p>
                  </div>

                  {seatTypes?.length > 0 &&
                    seatTypes?.map((seatType, seatTypeIndex) => (
                      <>
                        {seatType?.seats?.length > 0 &&
                          seatType?.seats?.map((rcg_child, index2) => (
                            <section>
                              <p className="left_value p-2">
                                {/* <span>
                                  (x{rcg_child.no_of_seats} {t('Seats')})
                                </span> */}
                                {/* &nbsp;
                                <>{seatType.sst_seat_type}</>
                                &nbsp;
                                <br />
                                <span className="seat-name-color">
                                  {seatType.newSeatsName.join(',')}
                                </span> */}
                                <span className="m-0 font-weight-bold">
                                  {t('Ticket Price')}{' '}
                                  {seatTypes.length > 1 && seatType?.sst_seat_type
                                    ? '- '
                                    : ''}
                                  {seatTypes.length > 1 && seatType?.sst_seat_type
                                    ? seatType?.sst_seat_type
                                    : ''}
                                </span>
                                {/* <span className="">
                                {rcg_child?.ticket_type_translations?.[
                                  iso_2?.toLowerCase()
                                ] || rcg_child.tt_name}
                              </span> */}
                              </p>
                              <p className="right_value p-2 book_detai_para font-weight-bold">
                                {rcg_child?.no_of_seats} x {curr_code}{' '}
                                {currencyFormatter(
                                  rcg_child?.single_seat_net_price,
                                )}
                              </p>
                            </section>
                          ))}
                      </>
                    ))}
                  {scheduleDetails?.booking_type_id == 3 ? (
                    <>
                      <section className="">
                        <div className="left_value">
                          {scheduleDetails?.screen_name}
                        </div>
                        <div className="right_value">
                          {!!ticket_count
                            ? `(x${ticket_count})`
                            : `(x${scheduleDetails?.ticket_count})`}
                        </div>
                      </section>
                      <section className="">
                        <div className="left_value">
                          {t(scheduleDetails?.booking_type_name)}
                        </div>
                        <div className="right_value">
                          {curr_code}{' '}
                          {currencyFormatter(
                            reservationDetails?.private_rate_card_detail?.[0]
                              ?.customSeatCount *
                              reservationDetails?.private_rate_card_detail[0]
                                ?.slab?.[0]?.price,
                          )}
                        </div>
                      </section>
                    </>
                  ) : (
                    <>
                      {/* <section className="">
                <div className="left_value">{scheduleDetails?.seat_name}</div>
                <div className="right_value">{curr_code}{" "}{currencyFormatter(reservationDetails?.amount)}</div>
                      </section> */}
                    </>
                  )}
                </article>
                {/* {selectedFnbItems && selectedFnbItems.length > 0 && (
                  <article className="grey-title-lg mb-2">
                    {t("Concessions")}
                  </article>
                )} */}
                {selectedFnbItems && selectedFnbItems.length > 0 && (
                  <>
                    <div className="payment_details_rows px-2">
                      <h5 className="fnb_detail_title">Food and Beverage</h5>
                      {selectedFnbItems.map((item) => (
                        <section>
                          <div className="left_value">
                            {item.Quantity && <span>(x{item.Quantity}) </span>}
                            {getLangSpecificAttribute(
                              item?.FnbContent,
                              lang_id,
                              'item_lang_name',
                            ) || item.item_name}
                          </div>
                          <div className="right_value book_detai_para font-weight-bold">
                            {curr_code}{' '}
                            {currencyFormatter(item?.fp_amount * item?.quantity)}
                          </div>
                        </section>
                      ))}
                    </div>
                    <div
                      className="payment_details_rows px-2"
                      style={{ borderTop: 'none', paddingTop: '0px' }}
                    >
                      <section>
                        <div className="left_value">VAT 10%</div>
                        <div className="right_value book_detai_para font-weight-bold">
                          {curr_code} {currencyFormatter(TotalFnbVatAmt)}
                        </div>
                      </section>
                    </div>
                  </>
                )}

                <img src={TicketCut} width="100%" height="30"></img>

                <div className="">
                  <section className="d-flex justify-content-between p-2">
                    <h5 className={` ${category_id == 1 && ' text-golden '}`}>
                      {t('To Pay')}
                    </h5>
                    <h5 className={` ${category_id == 1 && ' text-golden '}`}>
                      {curr_code}{' '}
                      {currencyFormatter(
                        reservationDetails?.amount +
                          fnbPrice.total_price -
                          (discount ? discount : 0) +
                          (getInternetHandlingFees(
                            category_id,
                            is_internet_handling_enable,
                            scheduleDetails,
                            reservationDetails,
                          ) || 0) +
                          (getRateCardAddonsTotal(add_ons_array) || 0) -
                          (totalOfferPrice ? totalOfferPrice : 0),
                      )}
                    </h5>
                  </section>
                </div>

                {reservationDetails?.private_rate_card_detail &&
                  reservationDetails?.private_rate_card_detail.length > 0 &&
                  reservationDetails?.private_rate_card_detail[0]?.fnb &&
                  reservationDetails?.private_rate_card_detail[0]?.fnb.length >
                    0 && (
                    <div className="payment_details_rows">
                      {reservationDetails?.private_rate_card_detail[0]?.fnb.map(
                        (item) => (
                          <>
                            <section>
                              <div className="left_value">
                                {reservationDetails?.private_rate_card_detail[0]
                                  ?.customSeatCount && (
                                  <span>
                                    (x
                                    {
                                      reservationDetails
                                        ?.private_rate_card_detail[0]
                                        ?.customSeatCount
                                    }
                                    )
                                  </span>
                                )}{' '}
                                {item.item_name}
                              </div>
                              <div className="right_value">
                                {curr_code}{' '}
                                {currencyFormatter(
                                  item.price *
                                    reservationDetails?.private_rate_card_detail[0]
                                      ?.customSeatCount,
                                )}
                              </div>
                            </section>
                          </>
                        ),
                      )}
                    </div>
                  )}
                {reservationDetails?.private_rate_card_detail &&
                  reservationDetails?.private_rate_card_detail.length > 0 &&
                  reservationDetails?.private_rate_card_detail[0]?.addon &&
                  reservationDetails?.private_rate_card_detail[0]?.addon.length >
                    0 && (
                    <div className="payment_details_rows">
                      {reservationDetails?.private_rate_card_detail[0]?.addon.map(
                        (item) => (
                          <>
                            <section>
                              <div className="left_value">
                                {item?.quantity && <span>(x{item?.quantity})</span>}{' '}
                                {item.item_name}
                              </div>
                              <div className="right_value">
                                {curr_code}{' '}
                                {currencyFormatter(item.price * item?.quantity)}
                              </div>
                            </section>
                          </>
                        ),
                      )}
                    </div>
                  )}
                {discount && (
                  <div className="payment_details_rows text-success">
                    <section className="">
                      <div className="left_value"> {t('Discount')}: </div>
                      <div className="right_value">
                        {' '}
                        - {curr_code} {currencyFormatter(discount)}
                      </div>
                    </section>
                  </div>
                )}

                {appliedLoyalty?.LoyaltyAmount && (
                  <div className="payment_details_rows text-success p-2">
                    <section className="">
                      <div className="left_value"> {t('Loyalty')}: </div>
                      <div className="right_value">
                        {' '}
                        {curr_code}{' '}
                        {currencyFormatter(appliedLoyalty?.LoyaltyAmount)}
                      </div>
                    </section>
                  </div>
                )}

                {appliedEwallet && (
                  <div className="payment_details_rows text-success p-2">
                    <section className="">
                      <div className="left_value"> {t('E-Wallet')}: </div>
                      <div className="right_value">
                        {' '}
                        {curr_code} {currencyFormatter(appliedEwallet)}
                      </div>
                    </section>
                  </div>
                )}

                {totalOfferPrice > 0 && (
                  <div className="payment_details_rows text-success p-2">
                    <section className="">
                      <div className="left_value"> {t('Offer')}: </div>
                      <div className="right_value">
                        {' '}
                        - {curr_code} {currencyFormatter(totalOfferPrice)}
                      </div>
                    </section>
                  </div>
                )}

                {getInternetHandlingFees(
                  category_id,
                  is_internet_handling_enable,
                  scheduleDetails,
                  reservationDetails,
                ) > 0 &&
                  false && (
                    <div className="payment_details_rows">
                      <section className="">
                        <div className="left_value">
                          {' '}
                          {t('Internet Handling Fee')}
                        </div>
                        <div className="right_value">
                          {' '}
                          {curr_code}{' '}
                          {currencyFormatter(
                            getInternetHandlingFees(
                              category_id,
                              is_internet_handling_enable,
                              scheduleDetails,
                              reservationDetails,
                            ),
                          )}
                        </div>
                      </section>
                    </div>
                  )}

                {!discount &&
                  !!cashCardRedemptionSelection?.amount &&
                  cashCardRedemptionSelection?.amount <=
                    (reservationDetails?.amount || 0) +
                      (fnbPrice?.total_price || 0) -
                      (discount ? discount : 0) +
                      (getInternetHandlingFees(
                        category_id,
                        is_internet_handling_enable,
                        scheduleDetails,
                        reservationDetails,
                      ) || 0) +
                      getRateCardAddonsTotal(add_ons_array) -
                      (totalOfferPrice ? totalOfferPrice : 0) && (
                    <div className="payment_details_rows ">
                      <section className="text-success">
                        <div className="left_value">
                          {' '}
                          {t('Cash Card')} {t('Redeem')}:{' '}
                        </div>
                        <div className="right_value">
                          {' '}
                          - {curr_code}{' '}
                          {currencyFormatter(cashCardRedemptionSelection?.amount)}
                        </div>
                      </section>

                      <section className="">
                        <div className="left_value">
                          {' '}
                          {t('Leftover')} {t('Amount')}:{' '}
                        </div>
                        <div className="right_value">
                          {' '}
                          {curr_code}{' '}
                          {currencyFormatter(
                            reservationDetails?.amount +
                              fnbPrice.total_price -
                              (discount ? discount : 0) -
                              cashCardRedemptionSelection?.amount +
                              (getInternetHandlingFees(
                                category_id,
                                is_internet_handling_enable,
                                scheduleDetails,
                                reservationDetails,
                              ) || 0) +
                              getRateCardAddonsTotal(add_ons_array) -
                              (totalOfferPrice ? totalOfferPrice : 0),
                          )}
                        </div>
                      </section>
                    </div>
                  )}
              </div>
            </div>
            <div className="movie_info_scroll_space" />
          </article>
          <article
            className={`d-none movie-bottom-checkout ${
              category_id == '1' ? 'movie-bottom-gold' : ''
            }`}
            ref={movieBottomRef}
          >
            <section className="">
              <div className="checkout_space text_fnb_bold">
                <span
                  className="chek_text"
                  style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
                >
                  {t('Grand Total')}
                </span>
                <span className="chek_text">
                  {curr_code}{' '}
                  {currencyFormatter(
                    reservationDetails?.amount +
                      fnbPrice.total_price -
                      (discount ? discount : 0) +
                      (getInternetHandlingFees(
                        category_id,
                        is_internet_handling_enable,
                        scheduleDetails,
                        reservationDetails,
                      ) || 0) +
                      (getRateCardAddonsTotal(add_ons_array) ?? 0) -
                      (totalOfferPrice ? totalOfferPrice : 0),
                  )}
                </span>
              </div>
              {/* <div className="check_span" style={{ marginTop: '6px' }}>
                {t('(Incl. of 2.5% VAT - ')} {curr_code}{' '}
                {currencyFormatter(
                  _.round(
                    percentage(
                      2.5,
                      reservationDetails?.amount +
                        fnbPrice.total_price -
                        (discount ? discount : 0) +
                        (getInternetHandlingFees(
                          category_id,
                          is_internet_handling_enable,
                          scheduleDetails,
                          reservationDetails,
                        ) || 0) +
                        getRateCardAddonsTotal(add_ons_array) ?? 0,
                    ),
                    4,
                  ),
                )}{' '}
                {')'}
              </div> */}
            </section>

            {/* <div className="img-top">
          <img
            src={circles}
            alt="Arrow Down"
          />
        </div> */}
          </article>
        </>
      )}
    </div>
  );
};
//End Payment Booking Details

const PaymentFlow = ({ t }) => {
  // localStorage.removeItem('hasRenderedBefore');
  const stripePublisherKeyFromStore = useSelector(
    (state) => state.movies.stripePublisherKey,
  );
  const stripePublisherKey =
    localStorage.getItem('stripe_publisher_key') || stripePublisherKeyFromStore;
  const stripePromise = loadStripe(stripePublisherKey);
  return (
    <Elements stripe={stripePromise}>
      <PaymentFlowApp t={t} />
    </Elements>
  );
};
export default withNamespaces()(PaymentFlow);
