import store from '../../store';
import React from 'react';

const numbersAfterDecimal = 3;

export const currencyFormatter = (value = 0) => {
  let currencyFormat;
  const state = store.getState();
  const {
    movies: { globalOptions },
  } = state;
  currencyFormat = globalOptions.find((x) => x.go_key === `CURR_FORMAT`);
  if (currencyFormat?.go_value) {
    currencyFormat = currencyFormat.go_value;
  } else {
    currencyFormat = `en-US`;
  }
  return getNumberFormatter(currencyFormat, value);
};

export const getNumberFormatter = (format, value) => {
  const minimumFractionDigits = numbersAfterDecimal;
  const maximumFractionDigits = numbersAfterDecimal;
  return (
    <>
      {value?.toLocaleString(format, {
        maximumFractionDigits,
        minimumFractionDigits,
      })}
    </>
  );
};
